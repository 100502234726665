import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import Modal from 'react-modal';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Avatar,
  Typography,
  Box
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
// imports for Snackbar UI
import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import CloseIcon from '@mui/icons-material/Close';  // Installed @mui/icons-material package and pointed to it correctly
import UndoIcon from '@mui/icons-material/Undo';
import { SnackbarProvider, useSnackbar } from 'notistack';
//
// import { setUserSession } from '../AuthService';
// import account from '../../../_mocks_/account';
// import account from '../../_mocks_/account';
import { getAccount, getPhotoURL, setPhotoURL, putFile, checkFileExists, stringAvatar, getFileURL } from '../AuthService';
import ElectronicRecipeForm from './ElectronicRecipeForm';
// import RecipeTable from './RecipeTable';

// ----------------------------------------------------------------------

function ProfileForm() {
  const navigate = useNavigate();
  const account = getAccount();
  // const photoURL = getPhotoURL();


  // const photoURL = getFileURL(`${account.firstName}${account.lastName}ProfilePic.jpg`);
  // const { REACT_APP_X_API_KEY } = process.env;

  // States
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [photoURL, setPhotoURL] = useState('');
  const [fileData, setFileData] = useState();

  // console.log("Account Info: ", account)

  React.useEffect(() => { // Check if account is logged in. If not, go to login page
    if (String(account.displayName).includes("Guest")) {  // Not logged in
      // navigate('/login', { replace: true });
      navigate('/login?continue=profile', { replace: true });
    }
  });

  useEffect(async () => {
    setPhotoURL(getPhotoURL());
    setPhotoURL(sessionStorage.getItem('localUserProfilePic'));
  }, [])

  // useEffect(async () => {
  //   console.log(fileData);
  //   // 2. Upload local file to S3 bucket
  //   const uploadFile = await putFile(fileData, 'public');
  //   // 3. Check to see if file exists on S3 bucket
  //   const result = await checkFileExists(fileData, 'public');
  //   // 4. Get the signedURL
  //   const downloadURL = await getFileURL(fileData, 'public');
  //   // 5. Download the file from S3 bucket and extract the image data
  //   fetch(downloadURL)
  //     .then(response => response.blob())
  //     .then(imageBlob => {
  //       const reader = new FileReader();
  //       reader.readAsDataURL(imageBlob);
  //       reader.addEventListener('load', () => {
  //         // 6. Set sessionStorage for localUserProfilePhoto
  //         sessionStorage.setItem('localUserProfilePic', reader.result);
  //       })
  //     })
  //     .catch(err => {
  //       console.log(err);
  //     })
  //   // 7. setPhotoURL in this component with updated sessionStorage localUserProfilePic
  //   setPhotoURL(sessionStorage.getItem('localUserProfilePic'));
  // }, [fileData]);

  const changeProfilePhoto = async () => {
    // TODO: 
    // 1. Change filename to the following const file = `${account.firstName}${account.lastName}ProfilePic.jpg`;
    const updatedFilename = `${account.firstName}${account.lastName}ProfilePic.jpg`;
    // return {foo: {...prevState.foo, a: "updated"}}
    setFileData((prev) => ({ ...prev, name: updatedFilename }));
    console.log(fileData);
    // 2. Upload local file to S3 bucket
    // 3. Check to see if file exists on S3 bucket
    // 4. Get the signedURL
    // 5. Download the file from S3 bucket and extract the image data
    // 6. Set sessionStorage for localUserProfilePhoto
    // 7. setPhotoURL in this component with updated sessionStorage localUserProfilePic
  }

  // TODO:
  // 1. Get email, first and last name, and list of recipes in eRecipe Book from DB
  // 2. Get assets from list of recipes from S3 bucket
  // 3. Populate table with recipes

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: () => {

      // const requestConfig = {
      //   headers: {
      //     'x-api-key': REACT_APP_X_API_KEY,
      //   }
      // }

      const requestBody = {

        username: formik.values.email,
        password: formik.values.password
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    // <style>
    //   img {
    //     border-radius: 15%;
    //   }
    // </style>

    <Stack direction="column" spacing={5}>
      <Modal id="recipeModal" isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)} >
        {/* <ElectronicRecipeForm sx={{ zIndex: 'tooltip' }} /> */}
        {/* <RecipeTable /> */}
      </Modal>
      <Stack direction="row" spacing={2}>


        {photoURL === '' ? <Avatar {...stringAvatar(`${account.firstName} ${account.lastName}`, 150, 150)} /> :
          <img src={photoURL} alt="Profile" width="200" height="200" style={{ borderRadius: 25 }} />}
        <Stack direction="column" spacing={2}>
          <Typography variant="h3" noWrap />
          <Typography variant="h3" noWrap>
            {String(account.displayName).toLocaleLowerCase()}
          </Typography>
          <Typography variant="h5" >
            {account.email}
          </Typography>
          <Link component={RouterLink} variant="subtitle2" to="/resetpw">
            Reset Password
          </Link>
          <input type="file" onChange={e => setFileData(e.target.files[0])} /><br />
          <button onClick={changeProfilePhoto}>Upload File</button>
          <Typography variant="h3" noWrap />
        </Stack>

      </Stack>
      <Stack direction="column" spacing={2}>
        <Box
          sx={{
            p: 1,
            border: 2,
            borderColor: (theme) => theme.palette.black
          }}
          borderRadius={2}
        >
          <Stack direction="column" spacing={1}
            sx={{
              p: 1,
              ml: 1
            }}
          >
            <Typography variant="h5">
              History
            </Typography>
            <Typography variant="subtitle1"
              sx={{
                p: 1,
                ml: 5
              }}
            >
              Experiences History
            </Typography>
          </Stack>
        </Box>
        <Box
          sx={{
            p: 1,
            border: 2,
            borderColor: (theme) => theme.palette.black
          }}
          borderRadius={2}
        >
          <Stack direction="column" spacing={1}
            sx={{
              p: 1,
              ml: 1
            }}>
            <Typography variant="h5" noWrap>
              Membership
            </Typography>
            <Typography variant="subtitle1"
              sx={{
                p: 1,
                ml: 1
              }}
            >
              $15/month subscription
            </Typography>
          </Stack>
        </Box>
        <Box
          sx={{
            p: 1,
            border: 2,
            borderColor: (theme) => theme.palette.black
          }}
          borderRadius={2}
        >
          <Stack direction="column" spacing={1}
            sx={{
              p: 1,
              ml: 1
            }}
          >
            <Typography variant="h5" >
              Payment
            </Typography>
            <Typography variant="subtitle1"
              sx={{
                p: 1,
                ml: 1,
              }}>
              VISA ending in XXXX (Exp. xx/xx)
            </Typography>
          </Stack>
        </Box>
        {/* <RecipeTable
          style={{
            minWidth: 200
          }} /> */}
      </Stack>
      {/* <Button onClick={() => setIsModalOpen(true)}>
        Open Recipe Book
      </Button> */}


    </Stack>


  );
}

// Outward facing function. Main form is wrapped in this function
export default function IntegrationNotistack() {
  return (
    <SnackbarProvider
      maxSnack={1}
      autoHideDuration={3000}
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
      <ProfileForm />
    </SnackbarProvider>
  );
}