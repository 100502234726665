// import { useState, useEffect } from 'react';

// Amplify Authentication
import { Amplify } from 'aws-amplify';

// material
import { Grid } from '@mui/material';
import config from '../aws-exports';
// components
import Page from '../components/Page';
import { Hero, AppExplanation, AppHowItWorks, AppForTheAudience, ContactForm } from '../components/_dashboard/app';
import { getAccount } from '../components/authentication/AuthService';
// import background from "/static/homepage_assets/hero.jpg";
// import background from '../../public/static/homepage_assets/hero.jpg';

Amplify.configure(config);  // Set up Amplify authentication

// ----------------------------------------------------------------------

export default function DashboardApp() {

  // const user = getUser();
  // const name = user !== 'undefined' && user ? user.name : 'Guest';
  // console.log('This is DashBoardApp()');
  // console.log(user);
  const account = getAccount();
  // Provide customized message depending on if it is a guest or not
  // const welcomeBack = ((account.displayName) === 'Guest') ? 'Welcome to Eat-A-Ton' : 'Welcome back!';
  const myStyle = {
    // backgroundImage: `url(/static/homepage_assets/hero.jpg)`,
    // height: 'auto',
    maxWidth:'1920px',
    // alignItems: 'center',
    // maxWidth: '1920px',
    // marginTop: '-70px',
    // fontSize: '50px',
    // backgroundSize: 'contain',
    // backgroundRepeat: 'no-repeat',
  };


  return (
    <Page title="Food Razzle" >
      <div id="dashboard" style={myStyle} >
          <Grid container spacing={3} >
            <Grid item xs={12} md={12} lg={12}>
              <Hero />
              <AppExplanation />
              <AppHowItWorks />
              <AppForTheAudience />
              <ContactForm />
            </Grid>     
          </Grid>
      </div>
    </Page >
  );
}
