import { useFormik } from 'formik';
import { useState } from 'react';


// material
import { Box, Stack, Card, Link, Container, Typography, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TextField from '@mui/material/TextField';
import DateTimePicker from '@mui/lab/DateTimePicker';
// import TimePicker from 'react-time-picker';




// components
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Page from '../components/Page';
import { getProductDetails } from '../components/authentication/AuthService';




import {
  ProductSort,
  ProductList,
  ProductCartWidget,
  ProductFilterSidebar
} from '../components/_dashboard/products';
//
import PRODUCTS from '../_mocks_/products';
import Label from '../components/Label';


// ----------------------------------------------------------------------

export default function ProductVideoSchedule() {
  // Check to see if a user is logged in.  If not, send them to the login page

  // const authed = !getToken();
  // return authed ? <Navigate to="/login"/> : <Outlet /> // may want to make this a specific page
  
  const [openFilter, setOpenFilter] = useState(false);
  const [value, setValue] = useState(new Date());
  const [dateEx7, setDateEx7]= useState("10:12");

  // console.log("Are we in product details?");
  const productdetails = getProductDetails();
  // console.log(productdetails);
  const { name, cover, price, colors, status, priceSale, description } = productdetails;
  
  const newprice = status === 'included in membership' ? 0 : price; // If the class is included in memberhsip, set price to $0



  const navigate = useNavigate();
  function onSignup() {

    navigate("/dashboard/productvideodetails", { replace: false}); // Navigate to the Products Video Details Page
  
}

function scheduleExperience() {

  navigate("/dashboard/productvideoschedule", { replace: false}); // Navigate to the Products Video Details Page


}

  const ProductImgStyle = styled('img')({
    top: 0,
    width: '20%',
    height: '20%',
    
    
  });

  const formik = useFormik({
    initialValues: {
      gender: '',
      category: '',
      colors: '',
      priceRange: '',
      rating: ''
    },
    onSubmit: () => {
      setOpenFilter(false);
    }
  });

  const { resetForm, handleSubmit } = formik;

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleResetFilter = () => {
    handleSubmit();
    resetForm();
  };


  return (
    <Page title="Schedule Experience">
      <Container>
        <Typography variant="h4" align="center" sx={{ mb: 5 }}>
          {name}: Schedule Experience
        </Typography>
        <Card>
      <Stack direction="row" justifyContent="space-around" borderColor="#fff" >
        
        <ProductImgStyle alt={name} src={cover} />

        
        <Box >
        <Typography variant="h4" flexWrap> 
           Step 1: Pick a Date & Time
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
  <DateTimePicker
    label="Date & Time"
    value={value}
    onChange={(newValue) => {
      setValue(newValue);
      console.log(newValue);
    }}
    renderInput={(params) => <TextField {...params} />}
  />
</LocalizationProvider>
        <Typography variant="h4" flexWrap> 
           Step 2: Add Users to Share Experience
        </Typography>
       
        </Box>
      </Stack>

      <Stack spacing={2} sx={{ p: 3 }}>
        <Link to="#" color="inherit" underline="hover" component={RouterLink}>
          <Typography variant="subtitle2" noWrap>
            ${newprice} {status}
          </Typography>
        </Link>

        <Stack direction="row" alignItems="center" justifyContent="flex-end">
          <Typography variant="subtitle1">
            <Typography
              component="span"
              variant="button"
              sx={{
                color: 'inherit',
               
              }}
            >
              &nbsp;
            </Typography>
            <Button 
              to="#"
              size="large"
              color="inherit"
              onClick={() => onSignup()}
              >
                Launch Experience Now!
 
             </Button> 
             &nbsp;
             <Button 
              to="#"
              size="large"
              color="inherit"
              onClick={() => scheduleExperience()}
              >
                schedule Experience
 
             </Button> 
          </Typography>
        </Stack>
      </Stack>
    </Card>
        </Container>
        </Page>
  );

}






