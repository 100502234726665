import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider, FieldArray, Field } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
// import Modal from 'react-modal';
import axios from 'axios';
// material
import { Stack, TextField, IconButton, InputAdornment, Paper } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// imports for Snackbar UI
import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import CloseIcon from '@mui/icons-material/Close';  // Installed @mui/icons-material package and pointed to it correctly
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import { SnackbarProvider, useSnackbar } from 'notistack';
import RecipesParser from "recipes-parser";
import units from "recipes-parser/lib/nlp/en/units.json";
import globalUnit from "recipes-parser/lib/nlp/en/global_unit.json";
import * as fs from "fs-web";
import raw from "./rules.pegjs";


// Include process module
const process = require('process');

function ElectronicRecipeForm() {

  const navigate = useNavigate();
  const registerUrl = 'https://3cqvp6zerj.execute-api.us-east-1.amazonaws.com/prod/register';
  const [showPassword, setShowPassword] = useState(false);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const { REACT_APP_X_API_KEY } = process.env;

  let rules;
  let parser;


  const RegisterSchema = Yup.object().shape({
    recipeName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Recipe name required'),
    recipeShortDescription: Yup.string().min(2, 'Too Short!').required('Short Description Required'),
    recipeLongDescription: Yup.string().min(2, 'Too short!').required('Long Description Required'),
    password: Yup.string().required('Password is required')
  });

  const formik = useFormik({
    initialValues: {
      recipeName: '',
      recipeShortDescription: '',
      recipeLongDescription: '',
      jsonRecipe: '',
      password: '',
      ingredient: []

    },

    validationSchema: RegisterSchema,
    onSubmit: () => {
      const space = ' ';

      const requestConfig = {
        headers: {
          'x-api-key': REACT_APP_X_API_KEY,
        }
      }

      const requestBody = {
        recipeName: formik.values.recipeName,
        recipeShortDescription: formik.values.recipeShortDescription,
        recipeLongDescription: formik.values.recipeLongDescription,
        password: formik.values.password
      }



      // axios.post(registerUrl, requestBody, requestConfig).then(response => {
      //   console.log('Registration Succesful - Welcome to Eat-a-Ton');
      //   handleClickVariant("Registration Successful! Sending you to Login Page.", "success", actionAPISuccess, handleCloseAPISuccess)();  // Show Snackbar, go to Login page on close
      // }).catch(error => {
      //   if (error.response.status === 401 || error.response.status === 403) {
      //     console.log(error.response.data.message);
      //     handleClickVariant("Username already exists, please try again.", "error", actionAPIError, handleCloseAPIError)();  // Show Snackbar, reload page on close
      //   } else {
      //     console.log('The backend server is down, please try again later.');
      //     handleClickVariant("The backend server is down, please try again later.", "error", actionAPIError, handleCloseAPIError)();  // Show Snackbar, reload page on close
      //   }
      // });
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  // Code to enable SnackBars 
  const { enqueueSnackbar } = useSnackbar();

  // Define the Snackbar parameters
  const handleClickVariant = (message, variant, action, onClose) => () => {
    enqueueSnackbar(message, { variant, action, onClose });
  };

  const handleCloseAPISuccess = (event, reason) => {
    // Navigate to the Login page
    navigate('/login', { replace: true });
  };

  const handleCloseAPIError = (event, reason) => {
    window.location.reload()  // Reload the Registration page
  };

  // Additional HTML actions to perform on SnackBar
  const actionAPISuccess = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseAPISuccess}
      >
        <RedoIcon fontSize="medium" />
      </IconButton>
    </>
  );

  const actionAPIError = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseAPIError}
      >
        <UndoIcon fontSize="medium" />
      </IconButton>
    </>
  );

  const parseRecipe = async () => {
    // const rules = '';
    // readString('rules.pegjs', 'utf8', (err, data) => {
    //   if (err) throw err;
    //   console.log(data);
    // });
    // const parser = new RecipesParser(readFile('./rules.pegjs'),units,globalUnit);
    await fetch(raw)
      .then(r => r.text())
      .then(text => {
        // console.log('text decoded:', text);
        rules = text;
        parser = new RecipesParser(rules, units, globalUnit);
        console.log("Parser: ", parser);
      });

    console.log("Original recipe: ", formik.values.recipeLongDescription);
    // const temp = formik.values.recipeLongDescription;


    let recipeString = formik.values.recipeLongDescription;
    // Remove special characters other than letters, numbers, spaces, forward slash, parentheses, periods, and fraction unicodes
    recipeString = recipeString.replace(/[^a-zA-Z0-9' ''/'\u00BC-\u00BE\u2150-\u215E\u2189'('')''\n''.']/g, '');


    // const recipeStringArray = formik.values.recipeLongDescription.split("\n");
    const recipeStringArray = recipeString.split("\n");
    console.log("Recipe Ingredients: ", recipeStringArray);
    const results = [];
    // results.push(() => {
    //   parser.getIngredientsFromText([recipeStringArray[0]], true);
    // });

    recipeStringArray.forEach((element) => {
      // console.log("Element: ", element);
      console.log("Parser: ", parser);
      results.push(parser.getIngredientsFromText([element.trim()], true));  // Trim leading and trailing whitespaces
    });

    // Format and parse JSON
    // const text = JSON.stringify(results);
    // const myObj = JSON.parse(text);


    let testField = [];

    results.forEach((element) => {
      // Creating the text display entry
      if (element[0].result) {
        testField += "Ingredient: "
        testField += element[0].result.ingredient;
        testField += '\n';
        testField += "Quantity: "
        testField += element[0].result.amount
        testField += ' ';
        if (!element[0].result.unit) {
          testField += '';
        }
        else {
          testField += element[0].result.unit;
        }
        testField += '\n\n';
      }
      else {
        testField += element[0].unknown.instruction;
        testField += '\n\n';
      }
    })

    formik.setFieldValue("jsonRecipe", testField);

  };

  const [avatarPreview, setAvatarPreview] = useState('/avatars/default.png');

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        {/* <Button onClick={() => setIsModalOpen(true)}>Open Modal</Button> */}

        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Recipe Name"
              {...getFieldProps('recipeName')}
              error={Boolean(touched.recipeName && errors.recipeName)}
              helperText={touched.recipeName && errors.recipeName}
            />

            <TextField
              fullWidth
              label="Recipe Short Description"
              {...getFieldProps('recipeShortDescription')}
              error={Boolean(touched.recipeShortDescription && errors.recipeShortDescription)}
              helperText={touched.recipeShortDescription && errors.recipeShortDescription}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>

            <TextField
              fullWidth
              multiline
              // fullHeight
              autoComplete="off"
              label="Recipe Long Description"
              {...getFieldProps('recipeLongDescription')}
              error={Boolean(touched.recipeLongDescription && errors.recipeLongDescription)}
              helperText={touched.recipeLongDescription && errors.recipeLongDescription}
              onSelect={parseRecipe}
            />

            <TextField
              fullWidth
              multiline
              disabled
              // fullHeight
              autoComplete="off"
              label="JSON Recipe"
              {...getFieldProps('jsonRecipe')}
              error={Boolean(touched.jsonRecipe && errors.jsonRecipe)}
              helperText={touched.jsonRecipe && errors.jsonRecipe}
            />

          </Stack>

          <input
            type="file"
            accept="image/*"
            multiple
            name="avatar"
            label="Pick a File"
            onChange={(event) => {
              formik.setFieldValue('avatar', event.currentTarget.files[0]);
            }}
          />
          <FieldArray
            name="ingredient"
            render={arrayHelpers => (
              <div>
                {formik.values.ingredient && formik.values.ingredient.length > 0 ? (
                  formik.values.ingredient.map((ingredient, index) => (
                    <div key={index}>
                      <TextField label="name of ingredient" name={`ingredient.[${index}].name`}
                      //  {...getFieldProps('ingredient')}

                      />
                      <TextField label="unit" name={`ingredient.[${index}].unit`} />
                      <TextField label="amount" name={`ingredient.[${index}].amount`} />
                      <Button
                        type="button"
                        onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                      >
                        Remove ingredient
                      </Button>
                      <Button
                        type="button"
                        onClick={() => arrayHelpers.insert(index, '')} // insert an empty string at a position
                      >
                        Add ingredient
                      </Button>
                    </div>
                  ))
                ) : (
                  <Button type="button" onClick={() => arrayHelpers.push('')}>
                    {/* show this when user has removed all ingredients from the list */}
                    Add an ingredient
                  </Button>
                )}
                <div>
                  <Button type="button" onClick={() => {
                    console.log("This was clicked");
                    const displayitem = formik.values.ingredient;
                    console.log(displayitem);
                  }
                  }>Finalize List</Button>
                </div>
              </div>
            )}

          />
          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />


          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Save and Upload Experience
          </LoadingButton>
        </Stack>

      </Form>
    </FormikProvider >


  );
}

// Outward facing function. Main form is wrapped in this function
export default function IntegrationNotistack() {
  return (
    <SnackbarProvider
      maxSnack={1}
      autoHideDuration={3000}
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
      <ElectronicRecipeForm />
    </SnackbarProvider>
  );
}