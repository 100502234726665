import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Divider, Stack } from '@mui/material';
import { Auth } from 'aws-amplify';
// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { MHidden } from '../../components/@material-extend';
import { getAccount, getPhotoURL, resetUserSession } from '../../components/authentication/AuthService';

//
import sidebarConfig from './SidebarConfig';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: '#eeeeee',
  // backgroundColor: theme.palette.grey[200]
}));



// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  // console.log('we are in DashboardSidebar');

  // Update the account field
  const navigate = useNavigate();
  const account = getAccount();
  const photoURL = getPhotoURL();
/* 
const user = getUser();
const name = user !== 'undefined' && user ? user.name : 'Guest';
const account = {
  displayName: name,
  email: 'demo@minimals.cc',
  photoURL: '/static/mock-images/avatars/avatar_default.jpg'
};
*/ 
  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const loginHandler = async () => {
    // try {
    //   await Auth.signOut();
    //   console.log('Logging out');
    // } catch (error) {
    //   console.log('error signing out: ', error);
    // }
    resetUserSession();
    // console.log('would normally push to login here'); 
    navigate("/login", { replace: true }); // On clicking logout, move to Login page
  }

  const logoutHandler = async () => {
    try {
      await Auth.signOut();
      console.log('Logging out');
    } catch (error) {
      console.log('error signing out: ', error);
    }
    resetUserSession();
    // console.log('would normally push to login here'); 
    navigate("/login", { replace: true }); // On clicking logout, move to Login page
  }

  const renderContent = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' }
      }}
    >
      <Box sx={{ px: 2.5, py: 3 }}>
        <Box component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
          <Logo />
        </Box>
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none" component={RouterLink} to="#">
          <AccountStyle>
            {/* <AccountPopover /> */}
            <Avatar src={photoURL} alt="photoURL" />
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {account.displayName}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {account.role}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box>

      <NavSection navConfig={sidebarConfig} />

      <Box sx={{ flexGrow: 1 }} />

      <Divider sx={{ my: 1 }} />

      <Box sx={{ p: 2, pt: 1.5 }}>
          {account.displayName === 'Guest' ?
            <Button fullWidth color="inherit" variant="outlined" onClick={loginHandler}>
              Login
            </Button> :
            <Button fullWidth color="inherit" variant="outlined" onClick={logoutHandler}>
              Logout
            </Button>}
        </Box>

      {/* <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack
          alignItems="center"
          spacing={3}
          sx={{
            p: 2.5,
            pt: 5,
            borderRadius: 2,
            position: 'relative',
            bgcolor: 'grey.200'
          }}
        >
          <Box
            component="img"
            src="/static/illustrations/illustration_waitlist.jfif"
            sx={{ width: 100, position: 'absolute', top: -50 }}
          />

          <Box sx={{ textAlign: 'center' }}>
            <Typography gutterBottom variant="h6">
              Be a part of our growing Community
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              &nbsp;
            </Typography>
          </Box>

          <Button
            fullWidth
            href="/register"
            
            variant="contained"
          >
            Join the Waitlist!
          </Button>
        </Stack>
      </Box> */}
    </Scrollbar>
  );

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      {/* <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default'
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden> */}
    </RootStyle>
  );
}
