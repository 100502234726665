import { Auth, Storage } from 'aws-amplify';
// import axios from 'axios';

export default async function getAuthCredentials() {

    let account = '';
    let token = '';
    let photoURL = '';

    await Auth.currentSession()
        .then(data => {
            // console.log(data.getIdToken().decodePayload().email)
            // console.log(data.getIdToken().decodePayload().given_name)
            // console.log("ID Token: ", data.getIdToken().decodePayload())
            // TODO: Check if ID token is valid
            account = {
                displayName: data.getIdToken().decodePayload().given_name,
                firstName: data.getIdToken().decodePayload().given_name,
                lastName: data.getIdToken().decodePayload().family_name,
                email: data.getIdToken().decodePayload().email,
                photoURL: '', // ?getFileURL("ProfileTest.jpg"),
                cHash: ''
            }
            // localStorage.setItem('user', JSON.stringify(account));
            sessionStorage.setItem('user', JSON.stringify(account));
            token = data.getAccessToken().getJwtToken();
            // localStorage.setItem('token', token);
            sessionStorage.setItem('token', token);

            // All this code gets the user's profile pic from the S3 bucket and stores it in sessionStorage!
            const dummyFunc = async () => {
                const file = `${account.firstName}${account.lastName}ProfilePic.jpg`;
                const levelValue = 'public';
                const checkFile = await checkFileExists(file, levelValue);
                if (!checkFile) {
                    sessionStorage.setItem('localUserProfilePic', '');  // If no profile pic exists, make empty
                } else {
                    photoURL = await getFileURL(file, levelValue);
                    fetch(photoURL)
                        .then(response => response.blob())
                        .then(imageBlob => {
                            const reader = new FileReader();
                            reader.readAsDataURL(imageBlob);
                            reader.addEventListener('load', () => {
                                sessionStorage.setItem('localUserProfilePic', reader.result);
                            })
                        })
                        .catch(err => {
                            console.log(err);
                        })
                }
            }
            dummyFunc();

            // account.photoURL = getFileURL(`${account.firstName}${account.lastName}ProfilePic.jpg`);
            // return { account, token };
        })
        .catch(err => {
            console.log(err);
        });

    if (account === '') {
        account = {
            displayName: 'Guest',
            firstName: 'Guest',
            lastName: 'User',
            email: '',
            photoURL: '',
            cHash: ''
        }
        token = '';
        sessionStorage.setItem('user', JSON.stringify(account));
        sessionStorage.setItem('token', token);
    }

    return { account, token };
}

export async function putFile(file, levelValue) {
    // const toastId = useRef();
    //  toastId.current = toast.info("Starting upload", { autoClose: 10000 });
    try {
        const result = await Storage.put(file.name, file, {
            level: levelValue,
            // contentType: "text/plain",
            resumable: true,
            completeCallback: (event) => {
                // console.log(`Successfully uploaded ${event.key}`);
                //    toast.update(toastId.current, { render: `Successfully uploaded ${event.key.split('/')[1]}`, type: toast.TYPE.SUCCESS, autoClose: 3000 });
                // toast.success("Upload successful");
            },
            progressCallback: (progress) => {
                // console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
                //    toast.update(toastId.current, { render: `Uploaded: ${(progress.loaded / progress.total * 100).toPrecision(3)}%` });
            },
            errorCallback: (err) => {
                console.error('Unexpected error while uploading', err);
                //    toast.update(toastId.current, { render: err.message, type: toast.TYPE.ERROR, autoClose: 3000 });
                // toast.error(err.message);
            }
        });
        return result;
        // toast.dismiss(toastId.current);
    } catch (err) {
        console.error('Catastrophic error while uploading', err);
        //    toast.update(toastId.current, { render: err.message, type: toast.TYPE.ERROR, autoClose: 3000, onClose: () => window.location.reload() });
        return '';
    }
}

export async function checkFileExists(file, levelValue) {
    const fileExists = await Storage.list(file, { level: levelValue })
        .then(result => {
            console.log("Result: ", result)
            return result.length;
        })
        .catch(err => console.log("Error: ", err));
    return fileExists
}



export async function getFileURL(file, levelValue) {
    // const toastId = useRef();
    // toastId.current = toast.info("Starting download", { autoClose: 10000 });
    try {
        const result = await Storage.get(file, {
            level: levelValue,
            // contentType: "text/plain",
            download: false,
            // resumable: true,
            expires: 86400, // Valid for 24 hours
            completeCallback: (event) => {
                console.log(`Successfully downloaded ${event.key}`);
                //   toast.update(toastId.current, { render: `Successfully download ${event.key.split('/')[1]}`, type: toast.TYPE.SUCCESS, autoClose: 3000 });
                // toast.success("Upload successful");
            },
            progressCallback: (progress) => {
                console.log(`Downloaded: ${progress.loaded}/${progress.total}`);
                //   toast.update(toastId.current, { render: `Downloaded: ${(progress.loaded / progress.total * 100).toPrecision(3)}%` });
            },
            errorCallback: (err) => {
                console.error('Unexpected error while downloading', err);
                //   toast.update(toastId.current, { render: err.message, type: toast.TYPE.ERROR, autoClose: 3000 });
                // toast.error(err.message);
            }
        })
        // console.log("SignedURL: ", result);
        // sessionStorage.setItem(file, result);
        setFileURL(file, result);
        return result;
        //   setShowPic(true);
        // toast.dismiss(toastId.current);
    } catch (err) {
        console.error('Catastrophic error while downloading', err);
        //   toast.update(toastId.current, { render: err.message, type: toast.TYPE.ERROR, autoClose: 3000, onClose: () => window.location.reload() });
        return '';
    };
}

export function getUser() {
    // console.log('this is the get User function');
    // await getAuthCredentials();
    // const user = localStorage.getItem('user');
    const user = sessionStorage.getItem('user');
    // return JSON.parse(user);
    if (user === 'undefined' || !user) {
        return null;
    }

    if (user) {
        return JSON.parse(user);
    }
}

export function getToken() {
    // return localStorage.getItem('token');
    return sessionStorage.getItem('token');
}

export function getPhotoURL() {
    // return localStorage.getItem('localUserProfilePic');
    return sessionStorage.getItem('localUserProfilePic');
}

export function setPhotoURL(photoURL) {
    fetch(photoURL)
        .then(response => response.blob())
        .then(imageBlob => {
            const reader = new FileReader();
            reader.readAsDataURL(imageBlob);
            reader.addEventListener('load', () => sessionStorage.setItem('localUserProfilePic', reader.result))
        })
        .catch(err => {
            console.log(err);
        })
}

export function setFileURL(fileName, fileURL) {
    fetch(fileURL)
        .then(response => response.blob())
        .then(imageBlob => {
            const reader = new FileReader();
            reader.readAsDataURL(imageBlob);
            reader.addEventListener('load', () => sessionStorage.setItem(fileName, reader.result))
        })
        .catch(err => {
            console.log(err);
        })
}

export function setUserSession(user, token) {
    // console.log('Did we get into setUserSession');
    sessionStorage.setItem('user', JSON.stringify(user));
    sessionStorage.setItem('token', token);
}

export function setProductDetails(product) {
    sessionStorage.setItem('productdetails', JSON.stringify(product));
    console.log(product);
}

export function getProductDetails() {
    const productdetails = sessionStorage.getItem('productdetails');
    if (productdetails === 'undefined' || !productdetails) {
        return null;
    }

    if (productdetails) {
        console.log(productdetails);
        return JSON.parse(productdetails);
    }
}

export function resetUserSession() {
    // localStorage.removeItem('user');
    // localStorage.removeItem('token');
    // localStorage.removeItem('localUserProfilePic');
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('localUserProfilePic');
}

export function getAccount() {

    // const user = localStorage.getItem('user');
    const user = sessionStorage.getItem('user');

    if (user === 'undefined' || !user) {
        const account = {
            displayName: 'Guest',
            firstName: 'Guest',
            lastName: 'User',
            email: 'No email on file',
            photoURL: '',
            cHash: ''
        };
        return account;
    }

    if (user) {
        const tempuser = JSON.parse(user);
        const account = {
            displayName: tempuser.displayName,
            firstName: tempuser.firstName,
            lastName: tempuser.lastName,
            email: tempuser.email,
            photoURL: tempuser.photoURL,
            cHash: tempuser.cHash
        }
        return account;
    }
}

// Avatar Functions
function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

export function stringAvatar(name, width, height) {
    return {
        sx: {
            bgcolor: stringToColor(name),
            fontSize: width / 2,
            width,
            height
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

