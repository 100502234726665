import * as Yup from 'yup';
import { useState, useEffect } from 'react';
// import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider, FieldArray, Field } from 'formik';
// import eyeFill from '@iconify/icons-eva/eye-fill';
// import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
// import axios from 'axios';
// material
import { Stack, TextField, IconButton, InputAdornment, Paper } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// imports for Snackbar UI
import * as React from 'react';
import { API, graphqlOperation, Auth, Storage } from 'aws-amplify';

import Button from '@mui/material/Button';
// import Snackbar from '@mui/material/Snackbar';
// import SnackbarContent from '@mui/material/SnackbarContent';
// // import CloseIcon from '@mui/material/Icon';
// import CloseIcon from '@mui/icons-material/Close';  // Installed @mui/icons-material package and pointed to it correctly
// import UndoIcon from '@mui/icons-material/Undo';
// import RedoIcon from '@mui/icons-material/Redo';
// import { SnackbarProvider, useSnackbar } from 'notistack';
import RecipesParser from "recipes-parser";
import units from "recipes-parser/lib/nlp/en/units.json";
import globalUnit from "recipes-parser/lib/nlp/en/global_unit.json";
// import * as fs from "fs-web";
// import raw from "recipes-parser/node_modules/recipes-parser/lib/nlp/en/en/rules.pegjs";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

import raw from "./rules.pegjs";
// import * as path from "path";

import * as queries from '../../../graphql/queries'
// import * as customQueries from '../../../graphql/custom_queries';
import * as mutations from '../../../graphql/mutations';
import * as subscriptions from '../../../graphql/subscriptions';


function AddExperienceForm() {

  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cognitoID, setCognitoID] = useState();
  const [picUrl, setPicUrl] = useState();
  const [recipePicture, setRecipePicture] = useState();


  let rules;
  let parser;

  useEffect(() => { // Check to see if user is already logged in
    Auth.currentSession()
      .then(data => {
        // console.log(data)
        setCognitoID(data.getAccessToken().decodePayload().username)

      })
      .catch(err => {
        console.log(err)
        // const userInfoHTML = document.getElementById("userInfo");
        // userInfoHTML.innerHTML = "No User Logged In";
      });
  }, []);

  useEffect(() => {
    console.log("picUrl changed");
  }, [picUrl]);


  const RegisterSchema = Yup.object().shape({
    recipeName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Recipe name required'),
    recipeShortDescription: Yup.string().min(2, 'Too Short!').required('Short Description Required'),
    recipeLongDescription: Yup.string().min(2, 'Too short!').required('Long Description Required'),
    // password: Yup.string().required('Password is required')
  });

  const formik = useFormik({
    initialValues: {
      recipeName: 'Enter Recipe Name',
      recipeShortDescription: 'Enter a short description',
      recipeLongDescription: 'Enter cooking steps',
      recipeIngredients: 'Enter ingredients here',
      directions: 'Enter directions here',
      equipment: 'Enter any equipment here',
      jsonRecipe: '',
      jsonDirections: '',
      jsonEquipment: '',
      // ingredient: []

    },

    validationSchema: RegisterSchema,
    onSubmit: async () => {
      const space = ' ';

    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  const parseText = async (type, incomingText) => {

    await fetch(raw)
      .then(r => r.text())
      .then(text => {
        // console.log('text decoded:', text);
        rules = text;
        parser = new RecipesParser(rules, units, globalUnit);
        console.log("Parser: ", parser);
      });

    console.log("Original recipe: ", incomingText);
    // console.log("Original recipe: ", formik.values.recipeLongDescription);
    // const temp = formik.values.recipeLongDescription;


    let recipeString = incomingText;
    // let recipeString = formik.values.recipeLongDescription;
    // Remove special characters other than letters, numbers, spaces, forward slash, parentheses, periods, and fraction unicodes
    recipeString = recipeString.replace(/[^a-zA-Z0-9' ''/'\u00BC-\u00BE\u2150-\u215E\u2189'('')''\n''.']/g, '');


    // const recipeStringArray = formik.values.recipeLongDescription.split("\n");
    const recipeStringArray = recipeString.split("\n");
    console.log("Recipe Ingredients: ", recipeStringArray);
    const results = [];
    // results.push(() => {
    //   parser.getIngredientsFromText([recipeStringArray[0]], true);
    // });

    recipeStringArray.forEach((element) => {
      // console.log("Element: ", element);
      console.log("Parser: ", parser);
      results.push(parser.getIngredientsFromText([element.trim()], true));  // Trim leading and trailing whitespaces
    });

    let testField = [];

    results.forEach((element) => {
      // Creating the text display entry
      if (element[0].result) {
        testField += "Ingredient: "
        testField += element[0].result.ingredient;
        testField += '\n';
        testField += "Quantity: "
        testField += element[0].result.amount
        testField += ' ';
        if (!element[0].result.unit) {
          testField += '';
        }
        else {
          testField += element[0].result.unit;
        }
        testField += '\n\n';
      }
      else {
        testField += element[0].unknown.instruction;
        testField += '\n\n';
      }


      // testField.push(element[0].result.amount + ' ' + results[index][0].result.unit + ' ' + results[index][0].result.ingredient)

    })

    formik.setFieldValue(type, testField);
    return results;

  };

  const [avatarPreview, setAvatarPreview] = useState('/avatars/default.png');

  function allowDrop(ev) {
    ev.preventDefault();
  }

  // function drag(ev) {
  //   ev.dataTransfer.setData("text", ev.target.id);
  // }

  function drop(ev) {
    const data = ev.dataTransfer.getData("text");
    ev.preventDefault();

    ev.target.appendChild(document.getElementById(data));
  }

  const submitRecipe = async () => {
    const ingredients = await parseText('jsonRecipe', formik.values.recipeIngredients);
    const directions = await parseText('jsonDirections', formik.values.directions);
    const equipment = await parseText('jsonEquipment', formik.values.equipment);

    try {
      const recipe = await API.graphql(graphqlOperation(mutations.createRecipe, {
        input: {
          recipePostedById: cognitoID,
          title: formik.values.recipeName,
          shortDescription: formik.values.recipeShortDescription,
          directions: JSON.stringify(directions),
          ingredientsList: JSON.stringify(ingredients),
          equipment: JSON.stringify(equipment),
          pictureReference: "Nothing yet",
          accessLevel: false
        }
      }));

      console.log("Mutation result ", recipe);
      toast.success("Uploaded Recipe", {
        onClose: () => {
          formik.resetForm();
          setIsModalOpen(false);
        }
      });
    } catch (err) {
      console.log("Error: ", err);
      toast.error(err.message);
    }
  }

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Button onClick={() => setIsModalOpen(true)}>Open Modal</Button>
        <Modal isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)} ariaHideApp={false}	>
          <input
            id="file"
            type="file"
            // accept="image/*"
            // multiple
            name="file"
            label="Pick a File"
            onChange={(e) => {
              // formik.setFieldValue('recipePic', e.currentTarget.files[0]);
              setPicUrl("file", e.currentTarget.files[0]);
              console.log("What are the file parameters?")
              const reader = new FileReader();
              reader.onload = function (e) {
                setPicUrl(e.target.result);
              };
              reader.readAsDataURL(e.target.files[0]);
              console.log(reader);
            }}
          />

          <div id="recipePic" src={picUrl} onDrop={(e) => { drop(e) }}
            onDragOver={allowDrop}
            style=
            {{
              width: "350px",
              height: "70px",
              padding: "5px",
              border: "1px solid"
            }}
             /><br /><br />
          <Stack spacing={3}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                fullWidth
                label="Recipe Name"
                {...getFieldProps('recipeName')}
                error={Boolean(touched.recipeName && errors.recipeName)}
                helperText={touched.recipeName && errors.recipeName}
              />

              <TextField
                fullWidth
                label="Recipe Short Description"
                {...getFieldProps('recipeShortDescription')}
                error={Boolean(touched.recipeShortDescription && errors.recipeShortDescription)}
                helperText={touched.recipeShortDescription && errors.recipeShortDescription}
              />
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>

              <TextField
                fullWidth
                multiline
                // fullHeight
                autoComplete="off"
                label="Cooking Ingredients"
                {...getFieldProps('recipeIngredients')}
                error={Boolean(touched.recipeIngredients && errors.recipeIngredients)}
                helperText={touched.recipeIngredients && errors.recipeIngredients}
                onSelect={(e) => { parseText('jsonRecipe', e.target.value) }}
              />

              <TextField
                fullWidth
                multiline
                disabled
                // fullHeight
                autoComplete="off"
                label="Stored Ingredients and Quantities"
                {...getFieldProps('jsonRecipe')}
                error={Boolean(touched.jsonRecipe && errors.jsonRecipe)}
                helperText={touched.jsonRecipe && errors.jsonRecipe}
              />

            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>

              <TextField
                fullWidth
                multiline
                // fullHeight
                autoComplete="off"
                label="Cooking Steps"
                {...getFieldProps('directions')}
                error={Boolean(touched.directions && errors.directions)}
                helperText={touched.directions && errors.directions}
                onSelect={(e) => { parseText('jsonDirections', e.target.value) }}
              />

              <TextField
                fullWidth
                multiline
                disabled
                // fullHeight
                autoComplete="off"
                label="Stored Cooking Steps"
                {...getFieldProps('jsonDirections')}
                error={Boolean(touched.jsonDirections && errors.jsonDirections)}
                helperText={touched.jsonDirections && errors.jsonDirections}
              />

            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>

              <TextField
                fullWidth
                multiline
                // fullHeight
                autoComplete="off"
                label="Cooking Equipment"
                {...getFieldProps('equipment')}
                error={Boolean(touched.equipment && errors.equipment)}
                helperText={touched.equipment && errors.equipment}
                onSelect={(e) => { parseText('jsonEquipment', e.target.value) }}
              />

              <TextField
                fullWidth
                multiline
                disabled
                // fullHeight
                autoComplete="off"
                label="Stored Cooking Equipments"
                {...getFieldProps('jsonEquipment')}
                error={Boolean(touched.jsonEquipment && errors.jsonEquipment)}
                helperText={touched.jsonEquipment && errors.jsonEquipment}
              />

            </Stack>


            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              onClick={submitRecipe}
            >
              Save and Upload Recipe
            </LoadingButton>
            <ToastContainer
              position="bottom-center"
              autoClose={1000}
            />
          </Stack>
        </Modal>
      </Form>
    </FormikProvider >


  );
}

// function parseRecipe() {
//   // const rules = '';
//   // readFile('rules.pegjs', 'utf8', (err, data) => {
//   //   if (err) throw err;
//   //   console.log(data);
//   // });
//   // const parser = new RecipesParser(readFile('./rules.pegjs'), units, globalUnit);

//   const recipeStringArray = formik.values.recipeLongDescription.split("\n");
//   console.log("Recipe Ingredients: ", recipeStringArray);
//   const results = [];
//   results.push(() => {
//     parser.getIngredientsFromText([recipeStringArray[0]], true);
//   });

//   // recipeStringArray.forEach((element) => {
//   //   // console.log("Element: ", element);
//   //   console.log("Parser: ", parser);
//   //   results.push(parser.getIngredientsFromText([element], true));
//   // });
//   formik.setFieldValue("jsonRecipe", results);

// };

export default AddExperienceForm;
// // Outward facing function. Main form is wrapped in this function
// export default function IntegrationNotistack() {
//   return (
//     <SnackbarProvider
//       maxSnack={1}
//       autoHideDuration={3000}
//       anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
//       <AddExperienceForm />
//     </SnackbarProvider>
//   );
// }