import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import shoppingBagFill from '@iconify/icons-eva/shopping-bag-fill';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import lockFill from '@iconify/icons-eva/lock-fill';
import personAddFill from '@iconify/icons-eva/person-add-fill';
import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';
import questionCircleFilled from '@iconify/icons-ant-design/question-circle-filled'
import homeOutlined from '@iconify/icons-ant-design/home-outlined'
import profileCircled from '@iconify/icons-iconoir/profile-circled'
import videoIcon from '@iconify/icons-akar-icons/video';
import foodMenu from '@iconify/icons-bx/food-menu';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width="42px" height="42px" />;

const sidebarConfig = [
  {
    title: 'Home',
    path: '/dashboard/app',
    icon: getIcon(homeOutlined)
  },
  {
    title: 'profile',
    path: '/profile', 
    icon: getIcon(profileCircled)
  },
  {
    title: 'experiences',
    path: '/experiencesubmit',
    icon: getIcon(videoIcon)
  },
  {
    title: 'recipes',
    path: '/recipesubmit',
    icon: getIcon(foodMenu)
  },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: getIcon(lockFill)
  // },
  // {
  //   title: 'register',
  //   path: '/register',
  //   icon: getIcon(personAddFill)
  // },
  {
    title: 'FAQ',
    path: '/dashboard/tos',
    icon: getIcon(questionCircleFilled)
  }

];

export default sidebarConfig;
