import React, { Component } from 'react'

class Timer extends Component {

  constructor(props) {
    super(props)
    this.state = {
      count: 1
    }
  }

  componentDidMount() {
    const { startCount } = this.props
    this.setState({
      count: startCount
    })
    this.doIntervalChange()
  }

  componentWillUnmount() {
    clearInterval(this.myInterval)
  }

  doIntervalChange = () => {
    this.myInterval = setInterval(() => {
      if (this.props.timerEnabled) {
        this.setState(prevState => ({
          count: prevState.count + 1
        }))
      }
      // console.log("WSS_AWS: This count = ", this.state.count);
      if (this.state.count % this.props.timerInterval) {

        this.props.parentCallback(false);
        // preventDefault();
      }
      else {
        // console.log("WSS_AWS: MATCHES!!!!!!!!!");
        this.props.parentCallback(true);
      }
    }, 1000)


  }

  render() {
    const { count } = this.state
    return (
      <div>
        <h1>Timer: {Math.floor(count / 60)}:{String(count % 60).padStart(2, '0')} </h1>
      </div>
    )
  }
}

export default Timer