import React, { useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import UploadIcon from '@mui/icons-material/Upload';
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';

export default function VideoSelect(props) {
    const { parentCallback } = props;
    const [file, setFile] = useState();

    useEffect(() => {
        // console.log("In the fileUrl Effect");
        parentCallback(file);
    }, [file]);

    const onDrop = async (acceptedFiles) => {
        const file = new Blob(
            acceptedFiles,
            { "type": acceptedFiles[0].type});

        setFile(file);
    }
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    return (
        <div {...getRootProps()}>
            <input {...getInputProps()} />
            {
                isDragActive ?
                    <>
                        <p>Drop the files here ...</p>
                        <UploadOutlinedIcon sx={{fontSize:'100px'}}/>
                    </>
                    :
                    <>
                        <p>Drag 'n' drop a file here, or click to select file</p>
                        <UploadIcon sx={{fontSize:'100px'}}/>
                    </>
            }

        </div>
    )
}