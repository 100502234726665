import React from 'react'
// import { useDropzone } from 'react-dropzone'
// import Pie from './PiePercent'
// import VideoThumbnail from 'react-video-thumbnail'; // use npm published version
// import ReactPlayer from 'react-player/lazy';

// import {
//     generateVideoThumbnails,
//     importFileandPreview
//   }  from '@rajesh896/video-thumbnails-generator';

// import ThumbnailGenerator from 'video-thumbnail-generator';
// import * as VideoThumbnail from 'video-thumbnail';
// var thumb = require('video-thumbnail');

export default function captureThumbnail(videoDOM) {
    const scaleFactor = 0.5;

    const dataURLtoFile = (dataurl, filename) => {
        const arr = dataurl.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = arr[1].toString('base64');
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n) {
            u8arr[n] = bstr.charCodeAt(n);
            n -= 1; // Decrement n
        }
        return new File([u8arr], filename, { type: mime });
    }

    const capture = (video) => {
        const w = video.childNodes[0].videoWidth * scaleFactor;
        const h = video.childNodes[0].videoHeight * scaleFactor;
        const canvas = document.createElement('canvas');
        canvas.width = w;
        canvas.height = h;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(video.childNodes[0], 0, 0, w, h); // Use childNodes with ReactPlayer
        const dataURI = canvas.toDataURL('image/jpeg');
        const boj = {
            canvas,
            file: dataURLtoFile(dataURI, `${+new Date()}.jpg`),
            base64: dataURI
        }
        return boj;
    }
   
    const pic = capture(videoDOM);
    // console.log("Pic: ", pic);
    return pic;

    // return (
    //     <div>

    //         <img alt='' src={fileURL} />

    //     </div>
    // );
}