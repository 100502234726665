
// material
import { Stack, Paper, Divider } from '@mui/material';
// imports for Snackbar UI
import * as React from 'react';
import '@fontsource/lato';


function RecipeTemplate(props) {
  const { 
    croppedURL,
    recipeName,
    recipeShortDescription,
    recipePrepTime,
    recipeCookTime,
    recipeReadyTime
  } = props;

  return (

    <Paper elevation={10} sx={{ maxHeight: '100%', marginRight: '2%', minHeight: '100%' }} >
      <img id="recipePic" src={croppedURL} alt="" style={{ width: '100%', aspectRatio: '5/1' }} />
      <h1 style={{fontFamily:'lato'}} align='center'>{recipeName}</h1>
      <h3 align='center'>{recipeShortDescription}</h3>
      <h5 align='center'>Prep: {recipePrepTime} min | Cook: {recipeCookTime} min | Ready In: {recipeReadyTime} min</h5>
      <br />
      <hr style={{ width: '90%', margin: 'auto' }} />
      <Stack direction='row' divider={<Divider orientation="vertical" flexItem />}>
        <Stack direction='column' minWidth='35%'>
          <h4 style={{ marginLeft: '15%' }}>Ingredients</h4>
          <div style={{ overflow: 'overlay', fontFamily: 'lato'}}>
            <ul id="ingredientsList" style={{ marginLeft: '20%', maxHeight: '500px' }} />
          </div>
          <br />
          <h4 style={{ marginLeft: '15%' }}>Equipment</h4>
          <div style={{ overflow: 'overlay' }}>
            <ul id="equipmentList" style={{ marginLeft: '20%', maxHeight: '250px' }} />
          </div>
        </Stack>
        <Stack direction='column' minWidth='65%'>
          <h4 style={{ marginLeft: '5%' }}>Directions</h4>
          <div style={{ overflow: 'overlay' }}>
            <ol id='directionsList' style={{ marginLeft: '10%', marginRight: '10%', maxHeight: '500px' }} />
          </div>
          <br />
          <h4 style={{ marginLeft: '5%' }}>Notes</h4>
          <div style={{ overflow: 'overlay' }}>
            <ul id='notesList' style={{ marginLeft: '10%', marginRight: '10%', maxHeight: '250px' }} />
          </div>
        </Stack>
      </Stack>
    </Paper>
  );
}

export default RecipeTemplate;