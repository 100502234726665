import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import Cropper from 'react-easy-crop'
import './styles.css'
import Button from '@mui/material/Button';
import { getCroppedImg } from './cropImage';

const ImageCropper = ({ getBlob, inputImg }) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixelsState, setCroppedAreaPixelsState] = useState();
    //   const [imageURL, setImageURL] = useState();
    //   const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    //     console.log(croppedArea, croppedAreaPixels)
    //     setCroppedAreaPixelsState(croppedAreaPixels);
    //   }, [])

    const onCropComplete = async (_, croppedAreaPixels) => {
        const croppedImage = await getCroppedImg(
            inputImg,
            croppedAreaPixels
        )
        getBlob(croppedImage)
    }

    return (

        // <div className="App">
            <div className="crop-container">
                <Cropper
                    image={inputImg}
                    crop={crop}
                    zoom={zoom}
                    aspect={5 / 1}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                />
            </div>
            /* <div className="controls">
                <input
                    type="range"
                    value={zoom}
                    min={1}
                    max={3}
                    step={0.1}
                    aria-labelledby="Zoom"
                    onChange={(e) => {
                        setZoom(e.target.value)
                    }}
                    className="zoom-range"
                />
                <Button variant='contained' style={{ minWidth: '15%' }} onClick={() => props.croppedPicPixels(croppedAreaPixelsState)}>Submit Pic</Button>
            </div> */


        // </div>


    )
}

export default ImageCropper;
// const rootElement = document.getElementById('root')
// ReactDOM.render(<App />, rootElement)
