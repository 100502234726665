// ImageUpload.js

// import { BluetoothDisabled } from '@mui/icons-material'
import React, { useState } from 'react'
import ImageCropper from './ImageCropper'

const ImageUpload = ({ inputImg, getCroppedImg }) => {
    const [blob, setBlob] = useState(null)
    const [croppedImg, setCroppedImg] = useState(null)

    const getBlob = (blob) => {
        // pass blob up from the ImageCropper component
        setBlob(blob)
    }

    const handleSubmitImage = (e) => {
        // upload blob to firebase 'images' folder with filename 'image'
        e.preventDefault()
        blob.lastModifiedDate = new Date();
        blob.name = "croppedImage.jpg";
        console.log("Submitting image", { blob });
        getCroppedImg(blob);
    }


    return (
        <form onSubmit={handleSubmitImage}>
            {
                inputImg && (
                    <ImageCropper
                        getBlob={getBlob}
                        inputImg={inputImg}
                    // position='flex'

                    />
                )
            }
            <button type='submit'>Submit</button>
        </form>
    )
}

export default ImageUpload