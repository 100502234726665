import * as Yup from 'yup';
import { useState } from 'react';
import { Auth } from 'aws-amplify';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// material
import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import Modal from 'react-modal';
// import { styled } from '@mui/material/styles';
// imports for Snackbar UI
import * as React from 'react';

// ----------------------------------------------------------------------


function RegisterForm() {
  const navigate = useNavigate();
  // const registerUrl = 'https://3cqvp6zerj.execute-api.us-east-1.amazonaws.com/prod/register';
  const [showPassword, setShowPassword] = useState(false);
  const [code, setCode] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const { REACT_APP_X_API_KEY } = process.env;

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('First name required'),
    lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Last name required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
    passwordMatch: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Required')
  });

  const signUp = async () => {
    // e.preventDefault()
    try {
      const { user } = await Auth.signUp({
        username: formik.values.email,
        password: formik.values.password,
        attributes: {
          given_name: formik.values.firstName,
          family_name: formik.values.lastName,
          // other custom attributes 
        }
      });
      console.log(user);
      toast.success("Registered!", {
        onClose: () => {
          formik.setSubmitting(false);
          // Hide all the Register fields
          document.getElementById("registerSubmitStack").style.display = "none";
          // Show the code submit fields
          document.getElementById("codeSubmitStack").style.display = "block";
        }
      });
      // window.confirm("Check email for verification code!")

    } catch (error) {
      console.log('error signing up:', error);
      toast.error(error.message, {
        onClose: () => window.location.reload()  // Reload the Login page
      });
    }
  }

  const confirmSignUp = async (e) => {
    e.preventDefault();
    try {
      await Auth.confirmSignUp(formik.values.email, formik.values.code);
      console.log('Verified!');
      toast.success("Verified!", {
        onClose: () => {
          setIsModalOpen(false);
          navigate('/login', { replace: true });
        }
      });
      // setIsModalOpen(false);
    } catch (error) {
      console.log('error confirming sign up', error);
      toast.error("Unverified, resending code.", {
        onClose: () => {
          resendConfirmationCode();
        }
      })
    }
  }

  const resendConfirmationCode = async (e) => {
    // e.preventDefault();
    try {
      await Auth.resendSignUp(formik.values.email);
      console.log('code resent successfully');
      toast.info("Resending Code");
      // toast.info("Resending Code", {
      //   onClose: setIsModalOpen(true)
      // });
    } catch (err) {
      console.log('error resending code: ', err);
    }
  }

  // const ContentStyle = styled('div')(({ theme }) => ({
  //   backgroundColor: '#282c34',
  //   // maxHeight: '50vh',
  //   // maxHeight: '480vh',
  //   display: 'flex',
  //   flexDirection: 'column',
  //   alignItems: 'center',
  //   // fontSize: '108px',
  //   color: 'white',
  //   // maxWidth: 480,
  //   margin: 'auto',
  //   justifyContent: 'center',
  //   padding: theme.spacing(12, 0)
  // }));

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      passwordMatch: '',
      code: ''
    },

    validationSchema: RegisterSchema,
    onSubmit: () => {
      signUp();
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      {/* <Modal isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)} ariaHideApp={false}>
        <ContentStyle>
          <form>
            <text>Email verification code:</text><br />
            <input type="text" id="verificationCode" name="verificationCode" onChange={e => setCode(e.target.value)} /><br /><br />
            <input type="submit" value="Submit" onClick={confirmSignUp} />
            <input type="submit" value="Resend-Code" onClick={resendConfirmationCode} />
          </form>
        </ContentStyle>
      </Modal> */}
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
      />
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} id="registerSubmitStack">
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="First Name"
              {...getFieldProps('firstName')}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />

            <TextField
              fullWidth
              label="Last Name"
              {...getFieldProps('lastName')}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Stack>

          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address/Username"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />

          <TextField
            fullWidth
            autoComplete="matched-password"
            type={showPassword ? 'text' : 'password'}
            label="Matched Password"
            {...getFieldProps('passwordMatch')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.passwordMatch && errors.passwordMatch)}
            helperText={touched.passwordMatch && errors.passwordMatch}
          />



          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Register
          </LoadingButton>


        </Stack>
        <Stack spacing={3} id="codeSubmitStack" style={{display: "none"}}>
          <TextField
            id="codeField"
            fullWidth
            autoComplete="code"
            type="text"
            label="Check Your Email for Verification Code"
            {...getFieldProps('code')}
            error={Boolean(touched.code && errors.code)}
            helperText={touched.code && errors.code}
          />
          <LoadingButton
            id="codeSubmit"
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            onClick={confirmSignUp}
            loading={isSubmitting}
          >
            Submit Code
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider >
  );
}

export default RegisterForm;


// COMMENTED SECTION

// import * as Yup from 'yup';
// import { useState } from 'react';
// import { Icon } from '@iconify/react';
// import { useFormik, Form, FormikProvider } from 'formik';
// import eyeFill from '@iconify/icons-eva/eye-fill';
// import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// // material
// import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';
// import { LoadingButton } from '@mui/lab';
// // imports for Snackbar UI
// import * as React from 'react';
// import Button from '@mui/material/Button';
// import Snackbar from '@mui/material/Snackbar';
// import SnackbarContent from '@mui/material/SnackbarContent';
// // import CloseIcon from '@mui/material/Icon';
// import CloseIcon from '@mui/icons-material/Close';  // Installed @mui/icons-material package and pointed to it correctly
// import UndoIcon from '@mui/icons-material/Undo'; 
// import RedoIcon from '@mui/icons-material/Redo'; 
// import { SnackbarProvider, useSnackbar } from 'notistack';


// // ----------------------------------------------------------------------


// function RegisterForm() {
//   const navigate = useNavigate();
//   const registerUrl = 'https://3cqvp6zerj.execute-api.us-east-1.amazonaws.com/prod/register';
//   const [showPassword, setShowPassword] = useState(false);
//   const { REACT_APP_X_API_KEY } = process.env;

//   const RegisterSchema = Yup.object().shape({
//     firstName: Yup.string()
//       .min(2, 'Too Short!')
//       .max(50, 'Too Long!')
//       .required('First name required'),
//     lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Last name required'),
//     email: Yup.string().email('Email must be a valid email address').required('Email is required'),
//     password: Yup.string().required('Password is required')
//   });

//   const formik = useFormik({
//     initialValues: {
//       firstName: '',
//       lastName: '',
//       email: '',
//       password: ''
//     },

//     validationSchema: RegisterSchema,
//     onSubmit: () => {
//       const space = ' ';
//       const name = formik.values.firstName + space + formik.values.lastName;

//       const requestConfig = {
//         headers: {
//           'x-api-key': REACT_APP_X_API_KEY,
//         }
//       }

//       const requestBody = {
//         name,
//         email: formik.values.email,
//         username: formik.values.email,
//         password: formik.values.password
//       }

      

//       axios.post(registerUrl, requestBody, requestConfig).then(response => {
//         console.log('Registration Succesful - Welcome to Eat-a-Ton');
//         handleClickVariant("Registration Successful! Sending you to Login Page.", "success", actionAPISuccess, handleCloseAPISuccess)();  // Show Snackbar, go to Login page on close
//       }).catch(error => {
//         if (error.response.status === 401 || error.response.status === 403) {
//           console.log(error.response.data.message);
//           handleClickVariant("Username already exists, please try again.", "error", actionAPIError, handleCloseAPIError)();  // Show Snackbar, reload page on close
//         } else {
//           console.log('The backend server is down, please try again later.');
//           handleClickVariant("The backend server is down, please try again later.", "error", actionAPIError, handleCloseAPIError)();  // Show Snackbar, reload page on close
//         }
//       });
//     }
//   });

//   const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

//   // Code to enable SnackBars 
//   const { enqueueSnackbar } = useSnackbar();

//   // Define the Snackbar parameters
//   const handleClickVariant = (message, variant, action, onClose) => () => {
//     enqueueSnackbar(message, { variant, action, onClose });
//   };

//   const handleCloseAPISuccess = (event, reason) => {
//       // Navigate to the Login page
//       navigate('/login', { replace: true });
//   };

//   const handleCloseAPIError = (event, reason) => {
//     window.location.reload()  // Reload the Registration page
//   };

//    // Additional HTML actions to perform on SnackBar
//   const actionAPISuccess = (
//     <>
//       <IconButton
//         size="small"
//         aria-label="close"
//         color="inherit"
//         onClick={handleCloseAPISuccess}
//       >
//         <RedoIcon fontSize="medium" />
//       </IconButton>
//     </>
//   );

//   const actionAPIError = (
//     <>
//       <IconButton
//         size="small"
//         aria-label="close"
//         color="inherit"
//         onClick={handleCloseAPIError}
//       >
//         <UndoIcon fontSize="medium" />
//       </IconButton>
//     </>
//   );



//   return (
//     <FormikProvider value={formik}>
//       <Form autoComplete="off" noValidate onSubmit={handleSubmit}>

//         <Stack spacing={3}>
//           <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
//             <TextField
//               fullWidth
//               label="First Name"
//               {...getFieldProps('firstName')}
//               error={Boolean(touched.firstName && errors.firstName)}
//               helperText={touched.firstName && errors.firstName}
//             />

//             <TextField
//               fullWidth
//               label="Last Name"
//               {...getFieldProps('lastName')}
//               error={Boolean(touched.lastName && errors.lastName)}
//               helperText={touched.lastName && errors.lastName}
//             />
//           </Stack>

//           <TextField
//             fullWidth
//             autoComplete="username"
//             type="email"
//             label="Email address/Username"
//             {...getFieldProps('email')}
//             error={Boolean(touched.email && errors.email)}
//             helperText={touched.email && errors.email}
//           />

//           <TextField
//             fullWidth
//             autoComplete="current-password"
//             type={showPassword ? 'text' : 'password'}
//             label="Password"
//             {...getFieldProps('password')}
//             InputProps={{
//               endAdornment: (
//                 <InputAdornment position="end">
//                   <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
//                     <Icon icon={showPassword ? eyeFill : eyeOffFill} />
//                   </IconButton>
//                 </InputAdornment>
//               )
//             }}
//             error={Boolean(touched.password && errors.password)}
//             helperText={touched.password && errors.password}
//           />

//           <LoadingButton
//             fullWidth
//             size="large"
//             type="submit"
//             variant="contained"
//             loading={isSubmitting}
//           >
//             Register
//           </LoadingButton>
//         </Stack>
//       </Form>
//     </FormikProvider>


//   );
// }

// // Outward facing function. Main form is wrapped in this function
// export default function IntegrationNotistack() {
//   return (
//     <SnackbarProvider
//       maxSnack={1}
//       autoHideDuration={3000}
//       anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
//       <RegisterForm />
//     </SnackbarProvider>
//   );
// }