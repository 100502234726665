import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
// imports for Snackbar UI
import * as React from 'react';
// import Button from '@mui/material/Button';
// import Snackbar from '@mui/material/Snackbar';
// import SnackbarContent from '@mui/material/SnackbarContent';
// import CloseIcon from '@mui/icons-material/Close';  // Installed @mui/icons-material package and pointed to it correctly
// import UndoIcon from '@mui/icons-material/Undo';
// import { SnackbarProvider, useSnackbar } from 'notistack';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import { Auth } from 'aws-amplify';
// import { withAuthenticator } from '@aws-amplify/ui-react';

// import Login from 'src/pages/Login';
// import account from '../../../_mocks_/account';
import getAuthCredentials, { setUserSession } from '../AuthService';




// ----------------------------------------------------------------------

function LoginForm() {

  const navigate = useNavigate();
 
  /*
    Code to determine if refering url page exists.
    Format of URL: protocol//hostname/login?continue=nextUrl
    e.g. URL: https://foodrazzle.com/login?continue=experiencesubmit 
    would create a refering url of /experiencesubmit
  */
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const nextUrl = urlParams.get('continue') ?? '';

  // const location = useLocation();
  // const referer = location.state && location.state.referer;

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: async () => {

      // e.preventDefault();
      try {
        const user = await Auth.signIn(formik.values.email, formik.values.password);
        const userInfo = {
          username: user.attributes.email,
          name: user.attributes.given_name
        }
        toast.success("Successful login!", {
          autoClose: 1000,
          onOpen: async () => {
            await getAuthCredentials();
            // navigate("/dashboard/app", { replace: true }) // On clicking login, move to Premium Content page
          },
          onClose: () => {
            navigate(nextUrl ? `/${nextUrl}` : "/dashboard/app", { replace: true });
            // navigate("/dashboard/app", { replace: true }) // On clicking login, move to Premium Content page
          }
        });
        // navigate("/dashboard/app", { replace: true }); // On clicking login, move to Premium Content page
      } catch (error) {
        console.log('error signing in', error);
        toast.error(error.message, {
          onClose: () => window.location.reload()  // Reload the Login page
        });
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address/Username"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} style={{ color: "#1976D2" }} />}
            label="Remember me"
          />

          <Link component={RouterLink} variant="subtitle2" to="/resetpw" style={{ color: "#1976D2" }}>
            Forgot password?
          </Link>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          style={{
            backgroundColor: "#1976D2"
          }}
        >
          Login
        </LoadingButton>
      </Form>
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
      />
    </FormikProvider>
  );
}

export default LoginForm;
