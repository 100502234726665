import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Auth } from 'aws-amplify'
// import { Icon } from '@iconify/react';
// import eyeFill from '@iconify/icons-eva/eye-fill';
// import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  // Link,
  Stack,
  // Checkbox,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// ----------------------------------------------------------------------

function ResetPWForm() {
  const navigate = useNavigate();
  // const [disabled, setDisabled] = useState(true);

  const ResetPWSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    newPassword: Yup.string().required('New password is required'),
    code: Yup.string().required('Code is required'),

  });

  const sendConfirmationCode = () => {
    // Send confirmation code to user's email
    Auth.forgotPassword(formik.values.email)
      .then(data => {
        console.log(data);
        toast.success("Emailed reset code!", {
          onClose: () => {
            // setDisabled(false);  // Enable New Password and Code Field
            // Hide email field
            document.getElementById("resetSubmitStack").style.display = "none";
            // Show code fields
            document.getElementById("submitEmail").innerHTML = "Resend Code";
            document.getElementById("codeSubmitStack").style.display = "block";
          }
        });
      })
      .catch(error => {
        console.log(error);
        toast.error(error.message, {
          onClose: () => window.location.reload()  // Reload the Login page
        });
      });
  }

  const forgotPasswordSubmit = () => { // Collect confirmation code and new password, then
    Auth.forgotPasswordSubmit(formik.values.email, formik.values.code, formik.values.newPassword)
      .then(data => {
        console.log(data);
        toast.success("Password successfully reset!", {
          onClose: () => {
            navigate('/login', { replace: true });
          }
        })
      })
      .catch(error => {
        console.log(error);
        toast.error(error.message, {
          onClose: () => {
            formik.setSubmitting(false);
          }
        })
      });
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      newPassword: '',
      code: '',
    },
    validationSchema: ResetPWSchema,
    onSubmit: () => {
      forgotPasswordSubmit();
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>

      <ToastContainer
        position="bottom-center"
        autoClose={1000}
      />

      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack spacing={3} id="codeSubmitStack" style={{ display: "none" }}>

            <TextField
              id="newPasswordField"
              fullWidth
              autoComplete="newPassword"
              // disabled={disabled}
              type="password"
              // type="email"
              label="New Password"
              {...getFieldProps('newPassword')}
              error={Boolean(touched.newPassword && errors.newPassword)}
              helperText={touched.newPassword && errors.newPassword}
            />
            <TextField
              id="codeField"
              fullWidth
              autoComplete="code"
              // disabled={disabled}
              // type="email"
              label="Reset Code"
              {...getFieldProps('code')}
              error={Boolean(touched.code && errors.code)}
              helperText={touched.code && errors.code}
            />



            <LoadingButton
              fullWidth
              id="submitCode"
              type="submit"
              size="large"
              variant="contained"
              loading={isSubmitting}
            >
              Submit Code and New Password
            </LoadingButton>
          </Stack>
          <Stack spacing={3} id="resetSubmitStack">
            <TextField
              id="emailField"
              fullWidth
              autoComplete="email"
              type="email"
              label="Enter Email"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
          </Stack>

          <LoadingButton
            fullWidth
            id="submitEmail"
            size="large"
            onClick={sendConfirmationCode}
            variant="contained"
          >
            Submit
          </LoadingButton>
        </Stack>



      </Form>
    </FormikProvider>
  );
}

export default ResetPWForm;

// COMMENTED SECTION

// import * as Yup from 'yup';
// import { useState } from 'react';
// import { Link as RouterLink, useNavigate } from 'react-router-dom';
// import { useFormik, Form, FormikProvider } from 'formik';
// import { Icon } from '@iconify/react';
// import eyeFill from '@iconify/icons-eva/eye-fill';
// import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// // material
// import {
//   Link,
//   Stack,
//   Checkbox,
//   TextField,
//   IconButton,
//   InputAdornment,
//   FormControlLabel
// } from '@mui/material';
// import { LoadingButton } from '@mui/lab';
// import axios from 'axios';
// // imports for Snackbar UI
// import * as React from 'react';
// import Button from '@mui/material/Button';
// import Snackbar from '@mui/material/Snackbar';
// import SnackbarContent from '@mui/material/SnackbarContent';
// import CloseIcon from '@mui/icons-material/Close';  // Installed @mui/icons-material package and pointed to it correctly
// import UndoIcon from '@mui/icons-material/Undo';
// import RedoIcon from '@mui/icons-material/Redo';
// import { SnackbarProvider, useSnackbar } from 'notistack';

// // ----------------------------------------------------------------------

// function ResetPWForm() {
//   const navigate = useNavigate();
//   const resetPWUrl = 'https://3cqvp6zerj.execute-api.us-east-1.amazonaws.com/prod/reset-pw';
//   const { REACT_APP_X_API_KEY } = process.env;
//   // const [showPassword, setShowPassword] = useState(false);

//   const ResetPWSchema = Yup.object().shape({
//     lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Username required')
//     // email: Yup.string().email('Email must be a valid email address').required('Email is required')
//     // password: Yup.string().required('Password is required')
//   });

//   const formik = useFormik({
//     initialValues: {
//       lastName: ''
//       // password: '',
//       // remember: true
//     },
//     validationSchema: ResetPWSchema,
//     onSubmit: () => {
//       console.log('We have submitted the password reset and are now navigating to the dashboard');
//       // const name = formik.values.firstName;
//       // const username = formik.values.lastName;
//       // const email = formik.values.email;
//       // const password = formik.values.password;
//       console.log(formik.values.email);

//       const requestConfig = {
//         headers: {
//           'x-api-key': REACT_APP_X_API_KEY,
//         }
//       }

//       const domainProtocol = window.location.protocol;
//       const domainHostname = window.location.hostname;
//       const domainPort = window.location.port ? `:${window.location.port}` : "";
//       const domainURL = `${domainProtocol}//${domainHostname}${domainPort}`;  // Create a string of the current domain URL

//       const requestBody = {
//         username: formik.values.lastName,
//         domain: domainURL
//       }

//       console.log(requestBody);

//       axios.post(resetPWUrl, requestBody, requestConfig).then(response => {
//         console.log('Password Reset Succesful - Check your email');
//         // setOpenSuccess(true);  // Show the Snackbar
//         handleClickVariant("Password Reset Succesful - Check your email", "success", actionAPISuccess, handleCloseAPISuccess)();  // Show Snackbar, go to Login page on close
//       }).catch(error => {
//         if (error.response.status === 401 || error.response.status === 403) {
//           console.log(error.response.data.message);
//           if (error.response.data.message.includes("hostname")) {  // Domain URL error
//             handleClickVariant("Invalid hostname, please try again.", "error", actionAPIError, handleCloseAPIError)();  // Show Snackbar, reload page on close
//           }
//           else {  // Username error
//             handleClickVariant("Username does not exist, please try again.", "error", actionAPIError, handleCloseAPIError)();  // Show Snackbar, reload page on close
//           }
//         } else {
//           console.log('The backend server is down, please try again later.');
//           handleClickVariant("The backend server is down, please try again later.", "error", actionAPIError, handleCloseAPIError)();  // Show Snackbar, reload page on close
//         }
//         // setOpenError(true);  // Show the Snackbar
//       });

//       // console.log(lastName);
//       // console.log(email);
//       // console.log(password);
//       // navigate('/dashboard/app', { replace: true });
//     }
//   });

//   const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;


//   // Code to enable SnackBars 
//   const { enqueueSnackbar } = useSnackbar();

//   // Define the Snackbar parameters
//   const handleClickVariant = (message, variant, action, onClose) => () => {
//     enqueueSnackbar(message, { variant, action, onClose });
//   };

//   const handleCloseAPISuccess = (event, reason) => {
//     // Navigate to the Dashboard page
//     navigate('/dashboard/app', { replace: true });
//   };

//   const handleCloseAPIError = (event, reason) => {
//     window.location.reload()  // Reload the Reset page
//   };


//   // Additional HTML actions to perform on SnackBar
//   const actionAPISuccess = (
//     <>
//       <IconButton
//         size="small"
//         aria-label="close"
//         color="inherit"
//         onClick={handleCloseAPISuccess}
//       >
//         <RedoIcon fontSize="medium" />
//       </IconButton>
//     </>
//   );

//   const actionAPIError = (
//     <>
//       <IconButton
//         size="small"
//         aria-label="close"
//         color="inherit"
//         onClick={handleCloseAPIError}
//       >
//         <UndoIcon fontSize="medium" />
//       </IconButton>
//     </>
//   );


//   // // const handleShowPassword = () => {
//   // //   setShowPassword((show) => !show);
//   // // };

//   // // Code to enable SnackBar Success
//   // const [openSuccess, setOpenSuccess] = useState(false);
//   // // Code to enable SnackBar Error
//   // const [openError, setOpenError] = useState(false);


//   // const handleClickSuccess = () => {
//   //   setOpenSuccess(true);
//   // };

//   // const handleClickError = () => {
//   //   setOpenError(true);
//   // };

//   // const handleCloseSuccess = (event, reason) => {
//   //   if (reason === 'clickaway') {
//   //     return;
//   //   }
//   //   setOpenSuccess(false);
//   //   navigate('/dashboard/app', { replace: true });
//   // };

//   // const handleCloseError = (event, reason) => {
//   //   if (reason === 'clickaway') {
//   //     return;
//   //   }
//   //   setOpenError(false);
//   //   window.location.reload()  // Reload the Registration page
//   // };

//   // const actionSuccess = (
//   //   <>
//   //     <IconButton
//   //       size="small"
//   //       aria-label="close"
//   //       color="primary"
//   //       onClick={handleCloseSuccess}
//   //     >
//   //       <CloseIcon fontSize="small" />
//   //     </IconButton>
//   //   </>
//   // );

//   // const actionError = (
//   //   <>
//   //     <IconButton
//   //       size="small"
//   //       aria-label="close"
//   //       color="primary"
//   //       onClick={handleCloseError}
//   //     >
//   //       <CloseIcon fontSize="small" />
//   //     </IconButton>
//   //   </>
//   // );

//   return (
//     <FormikProvider value={formik}>
//       <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
//         {/* <Snackbar
//           open={openSuccess}
//           autoHideDuration={3000}
//           onClose={handleCloseSuccess}
//           message="Reset Successful. Check your email!"
//           action={actionSuccess}
//           anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
//         />
//         <Snackbar
//           open={openError}
//           autoHideDuration={3000}
//           onClose={handleCloseError}
//           message="Invalid username. Please try again."
//           action={actionError}
//           anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
//         /> */}
//         <Stack spacing={3}>
//           <TextField
//             fullWidth
//             autoComplete="username"
//             // type="email"
//             label="Username"
//             {...getFieldProps('lastName')}
//             error={Boolean(touched.lastName && errors.lastName)}
//             helperText={touched.lastName && errors.lastName}
//           />

//           {/* <TextField
//             fullWidth
//             autoComplete="current-password"
//             type={showPassword ? 'text' : 'password'}
//             label="Password"
//             {...getFieldProps('password')}
//             InputProps={{
//               endAdornment: (
//                 <InputAdornment position="end">
//                   <IconButton onClick={handleShowPassword} edge="end">
//                     <Icon icon={showPassword ? eyeFill : eyeOffFill} />
//                   </IconButton>
//                 </InputAdornment>
//               )
//             }}
//             error={Boolean(touched.password && errors.password)}
//             helperText={touched.password && errors.password}
//           /> */}
//         </Stack>

//         <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
//           {/* <FormControlLabel
//             control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
//             label="Remember me"
//           /> */}

//           {/* <Link component={RouterLink} variant="subtitle2" to="#">
//             Forgot password?
//           </Link> */}
//         </Stack>

//         <LoadingButton
//           fullWidth
//           size="large"
//           type="submit"
//           variant="contained"
//           loading={isSubmitting}
//         >
//           Submit
//         </LoadingButton>
//       </Form>
//     </FormikProvider>
//   );
// }

// // Outward facing function. Main form is wrapped in this function
// export default function IntegrationNotistack() {
//   return (
//     <SnackbarProvider
//       maxSnack={1}
//       autoHideDuration={3000}
//       anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
//       <ResetPWForm />
//     </SnackbarProvider>
//   );
// }