// import { Link as RouterLink } from 'react-router-dom';
// import { Auth } from 'aws-amplify';
import { useState, useEffect, forwardRef } from 'react';
// material
import { styled } from '@mui/material/styles';
import { Card, Stack, Link, Container, Typography } from '@mui/material';

// layouts
// import AuthLayout from '../layouts/AuthLayout';
// components
import Iframe from 'react-iframe';
import Popper from '@mui/material/Popper';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Page from '../components/Page';

// import { MHidden } from '../components/@material-extend';
import { StartVideoSession } from '../components/authentication/video';
import getAuthCredentials from '../components/authentication/AuthService';
import Checkout from '../components/authentication/Experiences/Tipping/Checkout';



// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  // maxWidth: 480,
  width: '100%',
  margin: 'auto',
  display: 'flex',
  minHeight: '70vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function VideoStart() {

  const currentURL = window.location;
  console.log("Current URL: ", currentURL.href);

  const { REACT_APP_SIGNED_URL } = process.env;

  const [token, setToken] = useState('testToken');
  const [room, setRoom] = useState('');
  const [name, setName] = useState('');
  const [uniqueName, setUniqueName] = useState('');
  const [videoFileURL, setVideoFileURL] = useState('');
  const [vodPath, setVodPath] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [popperOpen, setPopperOpen] = useState(false);
  const [checkoutUrl, setCheckoutUrl] = useState(null);

  // Get User credentials and Video Chat information
  useEffect(async () => {
    // const account = getAccount();
    // const token = await getToken();
    const { account, token } = await getAuthCredentials();
    setToken(token);
    setName(account.firstName);
    setUniqueName(account.email);
    // Get room and video parameters from the URL
    // Send message to iFrame
    const frame = document.getElementById('frame-chat');
    frame.contentWindow.postMessage('hello!', 'https://chimeapi.foodrazzle.com');

  }, []);

  useEffect(() => {
    if (!token) return;
    const roomAndVodInfo = window.location.search;
    // Is there VOD info?
    if (roomAndVodInfo.search("vod")) {
      const myArray = roomAndVodInfo.split("?");
      setRoom(myArray[1].replace('room=', ''));
      setVodPath(myArray[2].replace('vod=', ''));
    }
    else {
      setRoom(roomAndVodInfo.replace('room=', ''));
    }
  }, [token]);

  useEffect(() => {
    if (!room || !videoFileURL) return;
    console.log("Room: ", room);
    console.log("Video URL: ", videoFileURL);
  }, [videoFileURL]);

  const hanldeTipClick = (e) => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
    setPopperOpen(!anchorEl);
  }

  const handleCheckoutUrlUpdate = (url) => {
    // setCheckoutUrl(url);
    console.log("checkout Url: ", url);
    window.open(url, '_blank'); // Open in new tab
  }

  const handleClose = () => {
    setPopperOpen(false);
    setAnchorEl(null);
  }

  const handleIframeLoad = () => {
    // window.addEventListener('message', event => {
    //   // IMPORTANT: check the origin of the data!
    //   if (event.origin === 'https://chimeapi.foodrazzle.com') {
    //     // The data was sent from your site.
    //     // Data sent with postMessage is stored in event.data:
    //     console.log(event.data);
    //   } else {
    //     console.log("alert!!!");
    //     // The data was NOT sent from your site!
    //     // Be careful! Do not use it. This else branch is
    //     // here just for clarity, you usually shouldn't need it.
    //     // return;
    //   }
    // });
  }

  return (
    <RootStyle title="Video | Food Razzle" >
      {/* <Container id="videochat-container"> */}
      <ContentStyle style={{ display: 'contents' }}>
        {/* <Stack sx={{ mb: 5 }}> */}
        {/* <Typography variant="h4" gutterBottom>
              Your Cooking Experience
            </Typography> */}
        <Button
          onClick={hanldeTipClick}
          style={{
            position: 'relative',
            width: '10%',
            height: '10%',
            zIndex: '100'
          }}>
          $$$ Tip Creator! $$$
        </Button>

        <Popper open={popperOpen} anchorEl={anchorEl} transition>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps}>
              <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}>
                {/* Add Error Handling to the component below */}
                <Checkout token={token} parentFnc={handleCheckoutUrlUpdate} closeFnc={handleClose} />
              </Box>
            </Fade>
          )}
        </Popper>

        <Iframe url={`https://chimeapi.foodrazzle.com/?m=${room}&name=${name}`}
          width="100%"
          height="100%"
          zIndex='-100'
          // margin='0'
          // padding='0'
          // border='none'
          id="frame-chat"
          className=""
          display="block"
          position="absolute"
          allow="camera;microphone"
          onLoad={handleIframeLoad}

        />
        {/* {name && room && <StartVideoSession displayName={name} uniqueName={uniqueName} token={token} videoChatRoom={room} videoFile={vodPath}/> } */}
        {/* {name && room && <StartVideoSession name={name} uniqueName={uniqueName} token={token} videoChatRoom={room} videoFile={vodPath}/> } */}
        {/* <Checkout token={token} parentFnc={handleCheckoutUrlUpdate} /> */}
        {/* </Stack> */}
      </ContentStyle>
      {/* </Container> */}
    </RootStyle>
  );
}