import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import Products from './pages/Products';
import Blog from './pages/Blog';
import User from './pages/User';
import NotFound from './pages/Page404';
import Tos from './pages/Tos';
import MembTerms from './pages/MembTerms';
import ResetPW from './pages/ResetPW';
import NewPassword from './pages/NewPassword';
import ProductDetails from './pages/ProductDetails';
import ProductVideoDetails from './pages/ProductVideoDetails';
import Profile from './pages/Profile';
import VideoStart from './pages/VideoStart';
import AddExperience from './pages/AddExperience';
import ProductVideoSchedule from './pages/productVideoSchedule';
import RecipeSubmit from './pages/RecipeSubmit';
import ExperienceSubmit from './pages/ExperienceSubmit';
import Success from './pages/Success';
import Canceled from './pages/Canceled';
// import StripeSuccess from './pages/StripeSuccess';
// import StripeCancel from './pages/StripeCancel';



//       { path: 'products', element: <PublicRoute /> },
// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" replace /> },
        { path: 'app', element: <DashboardApp /> },
        { path: 'user', element: <User /> },
        { path: 'products', element: <Products /> },
        { path: 'productdetails', element: <ProductDetails /> },
        { path: 'productvideodetails', element: <ProductVideoDetails /> },
        { path: 'productvideoschedule', element: <ProductVideoSchedule /> },
        { path: 'tos', element: <Tos /> },
        { path: 'blog', element: <Blog /> },
        { path: 'membterms', element: <MembTerms /> },
      
        


      ]
    },
    {
      path: '/',
      element: <DashboardLayout />,
      // element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        
        // { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/dashboard/app" /> },
        { path: '*', element: <Navigate to="/404" /> },
        { path: 'resetpw', element: <ResetPW /> },
        { path: 'resetpw/:token', element: <NewPassword /> },
        { path: 'videostart', element: <VideoStart /> },
        { path: 'addexperience', element: <AddExperience /> }



      ]
    },
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { path: 'profile', element: <Profile /> },
        { path: 'recipesubmit', element: <RecipeSubmit /> },
        { path: 'experiencesubmit', element: <ExperienceSubmit /> },
        { path: '404', element: <NotFound /> },
      ]
    },
    {
      path: '/',
      element: <DashboardLayout />,
      // element: null,
      children: [
        { path: 'videostart', element: <VideoStart /> },
        { path: 'success.html', element: <Success /> },
        { path: 'canceled.html', element: <Canceled /> },
      ]
    },
    
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
