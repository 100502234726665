/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      firstName
      lastName
      emailVerified
      profilePic
      Friends {
        items {
          id
          createdAt
          updatedAt
          userFriendsId
          friendshipFriendId
        }
        nextToken
      }
      accessLevel
      subscriptionStartDate
      createdExperiences {
        items {
          id
          title
          pictureReference
          shortDescription
          videoFileName
          experienceDuration
          membershipLevelRequired
          price
          avgRating
          accessLevel
          notes
          createdAt
          updatedAt
          userCreatedExperiencesId
          userExperiencesWatchedId
          experienceCreatedById
          experienceHostedById
          experienceDefaultRecipeId
        }
        nextToken
      }
      upcomingVideoRooms {
        items {
          id
          roomTitle
          scheduledTime
          createdAt
          updatedAt
          userUpcomingVideoRoomsId
          videoRoomOrganizerId
          videoRoomVideoId
        }
        nextToken
      }
      experiencesWatched {
        items {
          id
          title
          pictureReference
          shortDescription
          videoFileName
          experienceDuration
          membershipLevelRequired
          price
          avgRating
          accessLevel
          notes
          createdAt
          updatedAt
          userCreatedExperiencesId
          userExperiencesWatchedId
          experienceCreatedById
          experienceHostedById
          experienceDefaultRecipeId
        }
        nextToken
      }
      dateTimeExperienceWatched
      createdAt
      updatedAt
      experienceUsersWhoHaveSignedUpId
      videoRoomAttendeesId
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        firstName
        lastName
        emailVerified
        profilePic
        Friends {
          nextToken
        }
        accessLevel
        subscriptionStartDate
        createdExperiences {
          nextToken
        }
        upcomingVideoRooms {
          nextToken
        }
        experiencesWatched {
          nextToken
        }
        dateTimeExperienceWatched
        createdAt
        updatedAt
        experienceUsersWhoHaveSignedUpId
        videoRoomAttendeesId
      }
      nextToken
    }
  }
`;
export const getFriendship = /* GraphQL */ `
  query GetFriendship($id: ID!) {
    getFriendship(id: $id) {
      id
      user {
        id
        username
        firstName
        lastName
        emailVerified
        profilePic
        Friends {
          nextToken
        }
        accessLevel
        subscriptionStartDate
        createdExperiences {
          nextToken
        }
        upcomingVideoRooms {
          nextToken
        }
        experiencesWatched {
          nextToken
        }
        dateTimeExperienceWatched
        createdAt
        updatedAt
        experienceUsersWhoHaveSignedUpId
        videoRoomAttendeesId
      }
      friend {
        id
        username
        firstName
        lastName
        emailVerified
        profilePic
        Friends {
          nextToken
        }
        accessLevel
        subscriptionStartDate
        createdExperiences {
          nextToken
        }
        upcomingVideoRooms {
          nextToken
        }
        experiencesWatched {
          nextToken
        }
        dateTimeExperienceWatched
        createdAt
        updatedAt
        experienceUsersWhoHaveSignedUpId
        videoRoomAttendeesId
      }
      createdAt
      updatedAt
      userFriendsId
      friendshipFriendId
    }
  }
`;
export const listFriendships = /* GraphQL */ `
  query ListFriendships(
    $filter: ModelFriendshipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFriendships(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user {
          id
          username
          firstName
          lastName
          emailVerified
          profilePic
          accessLevel
          subscriptionStartDate
          dateTimeExperienceWatched
          createdAt
          updatedAt
          experienceUsersWhoHaveSignedUpId
          videoRoomAttendeesId
        }
        friend {
          id
          username
          firstName
          lastName
          emailVerified
          profilePic
          accessLevel
          subscriptionStartDate
          dateTimeExperienceWatched
          createdAt
          updatedAt
          experienceUsersWhoHaveSignedUpId
          videoRoomAttendeesId
        }
        createdAt
        updatedAt
        userFriendsId
        friendshipFriendId
      }
      nextToken
    }
  }
`;
export const getExperience = /* GraphQL */ `
  query GetExperience($id: ID!) {
    getExperience(id: $id) {
      id
      title
      createdBy {
        id
        username
        firstName
        lastName
        emailVerified
        profilePic
        Friends {
          nextToken
        }
        accessLevel
        subscriptionStartDate
        createdExperiences {
          nextToken
        }
        upcomingVideoRooms {
          nextToken
        }
        experiencesWatched {
          nextToken
        }
        dateTimeExperienceWatched
        createdAt
        updatedAt
        experienceUsersWhoHaveSignedUpId
        videoRoomAttendeesId
      }
      hostedBy {
        id
        username
        firstName
        lastName
        emailVerified
        profilePic
        Friends {
          nextToken
        }
        accessLevel
        subscriptionStartDate
        createdExperiences {
          nextToken
        }
        upcomingVideoRooms {
          nextToken
        }
        experiencesWatched {
          nextToken
        }
        dateTimeExperienceWatched
        createdAt
        updatedAt
        experienceUsersWhoHaveSignedUpId
        videoRoomAttendeesId
      }
      pictureReference
      shortDescription
      videoFileName
      experienceDuration
      usersWhoHaveSignedUp {
        items {
          id
          username
          firstName
          lastName
          emailVerified
          profilePic
          accessLevel
          subscriptionStartDate
          dateTimeExperienceWatched
          createdAt
          updatedAt
          experienceUsersWhoHaveSignedUpId
          videoRoomAttendeesId
        }
        nextToken
      }
      defaultRecipe {
        id
        title
        shortDescription
        prepTime
        cookTime
        readyTime
        ingredientsList
        directions
        equipment
        notes
        postedBy {
          id
          username
          firstName
          lastName
          emailVerified
          profilePic
          accessLevel
          subscriptionStartDate
          dateTimeExperienceWatched
          createdAt
          updatedAt
          experienceUsersWhoHaveSignedUpId
          videoRoomAttendeesId
        }
        recipeInspiredBy {
          id
          title
          pictureReference
          shortDescription
          videoFileName
          experienceDuration
          membershipLevelRequired
          price
          avgRating
          accessLevel
          notes
          createdAt
          updatedAt
          userCreatedExperiencesId
          userExperiencesWatchedId
          experienceCreatedById
          experienceHostedById
          experienceDefaultRecipeId
        }
        accessLevel
        createdAt
        updatedAt
        recipePostedById
        recipeRecipeInspiredById
      }
      membershipLevelRequired
      price
      avgRating
      accessLevel
      notes
      createdAt
      updatedAt
      userCreatedExperiencesId
      userExperiencesWatchedId
      experienceCreatedById
      experienceHostedById
      experienceDefaultRecipeId
    }
  }
`;
export const listExperiences = /* GraphQL */ `
  query ListExperiences(
    $filter: ModelExperienceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExperiences(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        createdBy {
          id
          username
          firstName
          lastName
          emailVerified
          profilePic
          accessLevel
          subscriptionStartDate
          dateTimeExperienceWatched
          createdAt
          updatedAt
          experienceUsersWhoHaveSignedUpId
          videoRoomAttendeesId
        }
        hostedBy {
          id
          username
          firstName
          lastName
          emailVerified
          profilePic
          accessLevel
          subscriptionStartDate
          dateTimeExperienceWatched
          createdAt
          updatedAt
          experienceUsersWhoHaveSignedUpId
          videoRoomAttendeesId
        }
        pictureReference
        shortDescription
        videoFileName
        experienceDuration
        usersWhoHaveSignedUp {
          nextToken
        }
        defaultRecipe {
          id
          title
          shortDescription
          prepTime
          cookTime
          readyTime
          ingredientsList
          directions
          equipment
          notes
          accessLevel
          createdAt
          updatedAt
          recipePostedById
          recipeRecipeInspiredById
        }
        membershipLevelRequired
        price
        avgRating
        accessLevel
        notes
        createdAt
        updatedAt
        userCreatedExperiencesId
        userExperiencesWatchedId
        experienceCreatedById
        experienceHostedById
        experienceDefaultRecipeId
      }
      nextToken
    }
  }
`;
export const getRecipe = /* GraphQL */ `
  query GetRecipe($id: ID!) {
    getRecipe(id: $id) {
      id
      title
      shortDescription
      prepTime
      cookTime
      readyTime
      ingredientsList
      directions
      equipment
      notes
      postedBy {
        id
        username
        firstName
        lastName
        emailVerified
        profilePic
        Friends {
          nextToken
        }
        accessLevel
        subscriptionStartDate
        createdExperiences {
          nextToken
        }
        upcomingVideoRooms {
          nextToken
        }
        experiencesWatched {
          nextToken
        }
        dateTimeExperienceWatched
        createdAt
        updatedAt
        experienceUsersWhoHaveSignedUpId
        videoRoomAttendeesId
      }
      recipeInspiredBy {
        id
        title
        createdBy {
          id
          username
          firstName
          lastName
          emailVerified
          profilePic
          accessLevel
          subscriptionStartDate
          dateTimeExperienceWatched
          createdAt
          updatedAt
          experienceUsersWhoHaveSignedUpId
          videoRoomAttendeesId
        }
        hostedBy {
          id
          username
          firstName
          lastName
          emailVerified
          profilePic
          accessLevel
          subscriptionStartDate
          dateTimeExperienceWatched
          createdAt
          updatedAt
          experienceUsersWhoHaveSignedUpId
          videoRoomAttendeesId
        }
        pictureReference
        shortDescription
        videoFileName
        experienceDuration
        usersWhoHaveSignedUp {
          nextToken
        }
        defaultRecipe {
          id
          title
          shortDescription
          prepTime
          cookTime
          readyTime
          ingredientsList
          directions
          equipment
          notes
          accessLevel
          createdAt
          updatedAt
          recipePostedById
          recipeRecipeInspiredById
        }
        membershipLevelRequired
        price
        avgRating
        accessLevel
        notes
        createdAt
        updatedAt
        userCreatedExperiencesId
        userExperiencesWatchedId
        experienceCreatedById
        experienceHostedById
        experienceDefaultRecipeId
      }
      accessLevel
      createdAt
      updatedAt
      recipePostedById
      recipeRecipeInspiredById
    }
  }
`;
export const listRecipes = /* GraphQL */ `
  query ListRecipes(
    $filter: ModelRecipeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRecipes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        shortDescription
        prepTime
        cookTime
        readyTime
        ingredientsList
        directions
        equipment
        notes
        postedBy {
          id
          username
          firstName
          lastName
          emailVerified
          profilePic
          accessLevel
          subscriptionStartDate
          dateTimeExperienceWatched
          createdAt
          updatedAt
          experienceUsersWhoHaveSignedUpId
          videoRoomAttendeesId
        }
        recipeInspiredBy {
          id
          title
          pictureReference
          shortDescription
          videoFileName
          experienceDuration
          membershipLevelRequired
          price
          avgRating
          accessLevel
          notes
          createdAt
          updatedAt
          userCreatedExperiencesId
          userExperiencesWatchedId
          experienceCreatedById
          experienceHostedById
          experienceDefaultRecipeId
        }
        accessLevel
        createdAt
        updatedAt
        recipePostedById
        recipeRecipeInspiredById
      }
      nextToken
    }
  }
`;
export const getVodInfo = /* GraphQL */ `
  query GetVodInfo($id: ID!) {
    getVodInfo(id: $id) {
      id
      guid
      srcBucket
      startTime
      srcVideo
      hlsUrl
      mp4Urls
    }
  }
`;
export const listVodInfos = /* GraphQL */ `
  query ListVodInfos(
    $filter: ModelVodInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVodInfos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        guid
        srcBucket
        startTime
        srcVideo
        hlsUrl
        mp4Urls
      }
      nextToken
    }
  }
`;
export const getVideoRoom = /* GraphQL */ `
  query GetVideoRoom($id: ID!) {
    getVideoRoom(id: $id) {
      id
      roomTitle
      organizer {
        id
        username
        firstName
        lastName
        emailVerified
        profilePic
        Friends {
          nextToken
        }
        accessLevel
        subscriptionStartDate
        createdExperiences {
          nextToken
        }
        upcomingVideoRooms {
          nextToken
        }
        experiencesWatched {
          nextToken
        }
        dateTimeExperienceWatched
        createdAt
        updatedAt
        experienceUsersWhoHaveSignedUpId
        videoRoomAttendeesId
      }
      attendees {
        items {
          id
          username
          firstName
          lastName
          emailVerified
          profilePic
          accessLevel
          subscriptionStartDate
          dateTimeExperienceWatched
          createdAt
          updatedAt
          experienceUsersWhoHaveSignedUpId
          videoRoomAttendeesId
        }
        nextToken
      }
      video {
        id
        title
        createdBy {
          id
          username
          firstName
          lastName
          emailVerified
          profilePic
          accessLevel
          subscriptionStartDate
          dateTimeExperienceWatched
          createdAt
          updatedAt
          experienceUsersWhoHaveSignedUpId
          videoRoomAttendeesId
        }
        hostedBy {
          id
          username
          firstName
          lastName
          emailVerified
          profilePic
          accessLevel
          subscriptionStartDate
          dateTimeExperienceWatched
          createdAt
          updatedAt
          experienceUsersWhoHaveSignedUpId
          videoRoomAttendeesId
        }
        pictureReference
        shortDescription
        videoFileName
        experienceDuration
        usersWhoHaveSignedUp {
          nextToken
        }
        defaultRecipe {
          id
          title
          shortDescription
          prepTime
          cookTime
          readyTime
          ingredientsList
          directions
          equipment
          notes
          accessLevel
          createdAt
          updatedAt
          recipePostedById
          recipeRecipeInspiredById
        }
        membershipLevelRequired
        price
        avgRating
        accessLevel
        notes
        createdAt
        updatedAt
        userCreatedExperiencesId
        userExperiencesWatchedId
        experienceCreatedById
        experienceHostedById
        experienceDefaultRecipeId
      }
      scheduledTime
      createdAt
      updatedAt
      userUpcomingVideoRoomsId
      videoRoomOrganizerId
      videoRoomVideoId
    }
  }
`;
export const listVideoRooms = /* GraphQL */ `
  query ListVideoRooms(
    $filter: ModelVideoRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideoRooms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        roomTitle
        organizer {
          id
          username
          firstName
          lastName
          emailVerified
          profilePic
          accessLevel
          subscriptionStartDate
          dateTimeExperienceWatched
          createdAt
          updatedAt
          experienceUsersWhoHaveSignedUpId
          videoRoomAttendeesId
        }
        attendees {
          nextToken
        }
        video {
          id
          title
          pictureReference
          shortDescription
          videoFileName
          experienceDuration
          membershipLevelRequired
          price
          avgRating
          accessLevel
          notes
          createdAt
          updatedAt
          userCreatedExperiencesId
          userExperiencesWatchedId
          experienceCreatedById
          experienceHostedById
          experienceDefaultRecipeId
        }
        scheduledTime
        createdAt
        updatedAt
        userUpcomingVideoRoomsId
        videoRoomOrganizerId
        videoRoomVideoId
      }
      nextToken
    }
  }
`;
