// material
import { useState } from 'react';

// import { useNavigate } from 'react-router-dom';
// import ReCAPTCHA from "react-google-recaptcha";


import { Grid, Typography, Button, TextField, Stack } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

// utils
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { styled, useTheme } from '@mui/material/styles';
import { getAccount } from '../../authentication/AuthService';




// ----------------------------------------------------------------------

export default function ContactForm() {

  const theme = useTheme();

  // const videoURL = '/static/homepage_assets/how-it-works-1080p-vimeo.mp4';
  // const thumbnailURL = '/static/homepage_assets/how-it-works-thumbnail-cropped.png';

  const { REACT_APP_X_API_KEY, REACT_APP_CONTACT } = process.env;

  const account = getAccount();
  const [isLoading, setIsLoading] = useState(false);
  const [completed, setCompleted] = useState(false);

  const RootStyle = styled('div')(({ theme }) => ({
    width: '100vw',
    height: 'auto',
    position: 'flex',
    // top: '-5em',
    left: 0,
    zindex: 2,
    color: theme.palette.primary.main,
    backgroundColor: '#000000',
    align: 'center'
  }));

  const PaperStyle = {
    width: '80vw',
    height: 'auto',
    position: 'relative',
    marginTop: '2em',
    marginBottom: '2em',
    paddingBottom: '2em',
    left: '10vw',
    zindex: 2,
    color: theme.palette.primary.main,
    backgroundColor: '#ffffff'
  }

  const titleStyles = {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    position: 'relative',
    // left: '5%',
    zindex: 2,
    color: theme.palette.primary.contrastText,
    backgroundColor: 'transparent',
    width: {xs: '50%', sm: '100%' },
  }

  const textList = {
    pt: 0,
    // paddingLeft: 5,
    // paddingRight: 5,
    textAlign: 'left',
    width: '20vw',
    height: 'auto',
    paddingBottom: '1em',
    color: '#757575',
    fontWeight: '400'
  }

  const ulStyle = {
    padding: '5em',
    width: '100ch'
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const name =  document.getElementById("contact-name").value;
    const email =  document.getElementById("contact-email").value;
    setIsLoading(true);

    // setDisableButton(true);
    console.log("Submit!")

    const requestBody = {
      name,
      email
    };

    const requestConfig = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
    };

    const response = await axios.post(REACT_APP_CONTACT, requestBody, requestConfig).then(response => {
      console.log('Submitted contact info');
      console.log(response);
      toast.success(`Thanks for your interest, ${name}`, {
        onClose: () => {
          // window.location.reload(); // Reload the page
          setCompleted(true);
        }
      });
    }).catch(error => {
      console.log(error);
      toast.error(error.response.data.message, {
        onClose: () => {
          window.location.reload(); // Reload the page
        }
      });
    });
  }

  return (
    <RootStyle>
      <Grid container >
        <Grid item xs={12} md={12} lg={12} flexDirection="column" align="center" marginTop={10} marginBottom={10}>
          <Typography variant="h2" align="center" style={titleStyles} component="h2" fontWeight={400} >
            It's almost here. Be among the first.
          </Typography>
          <Typography variant="h4" align="center" style={titleStyles} component="h2" fontWeight={400} maxWidth="50ch" marginBottom={3}>
            By entering your name and email address, we’ll reach out to you the  moment the platform goes live. We’ll never sell your information or share it with third-party marketing services.
          </Typography>
          <Stack
            sx={{
              maxWidth: '50ch',
            }}
            spacing={2}
            noValidate
            autoComplete="off"
            align="center"
            marginTop={5}
            marginBottom={5}
          >
            <form
              onSubmit={handleSubmit}
              hidden={completed}
            >
              <TextField id="contact-name" label="Name" variant="filled" style={{ backgroundColor: "#FFFFFF", width: "90%", margin: 10 }} />
              <TextField id="contact-email" label="Email" variant="filled" style={{ backgroundColor: "#FFFFFF", width: "90%", margin: 10 }} />
              <LoadingButton id="submit-contact" loading={isLoading} type="submit" variant="contained" style={{ backgroundColor: "#1976D2", fontSize: '18pt', margin: 10 }}>Submit</LoadingButton>
              {/* <div>
                <ReCAPTCHA
                  sitekey={TEST_SITE_KEY}
                  ref={reCaptchaRef}
                  // onChange={onChangeReCaptcha}
                  size="invisible"
                />
              </div> */}
            </form>
            <Typography variant="h4" align="center" style={titleStyles} component="h2" fontWeight={400} maxWidth="50ch" marginBottom={3} hidden={!completed}>
            SUBMITTED!
          </Typography>
          </Stack>


        </Grid>
      </Grid>
      <ToastContainer
        position="bottom-center"
        autoClose={1500}
      />
    </RootStyle>
  );
} 