import { useFormik } from 'formik';
import { useState } from 'react';

// material
import { Box, Stack, Card, Link, Container, Typography, Button } from '@mui/material';
import { styled } from '@mui/material/styles';


// components
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Page from '../components/Page';
import { getProductDetails } from '../components/authentication/AuthService';


import {
  ProductSort,
  ProductList,
  ProductCartWidget,
  ProductFilterSidebar
} from '../components/_dashboard/products';
//
import PRODUCTS from '../_mocks_/products';
import Label from '../components/Label';


// ----------------------------------------------------------------------

export default function ProductDetails() {
  // Check to see if a user is logged in.  If not, send them to the login page

  // const authed = !getToken();
  // return authed ? <Navigate to="/login"/> : <Outlet /> // may want to make this a specific page
  
  const [openFilter, setOpenFilter] = useState(false);
  // console.log("Are we in product details?");
  const productdetails = getProductDetails();
  // console.log(productdetails);
  const { name, cover, price, colors, status, priceSale, description } = productdetails;
  
  const newprice = status === 'included in membership' ? 0 : price; // If the class is included in memberhsip, set price to $0



  const navigate = useNavigate();
  function onSignup() {

    navigate("/dashboard/productvideodetails", { replace: false}); // Navigate to the Products Video Details Page
  
}

function scheduleExperience() {

  navigate("/dashboard/productvideoschedule", { replace: false}); // Navigate to the Products Video Details Page


}

  const ProductImgStyle = styled('img')({
    top: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute'
  });

  const formik = useFormik({
    initialValues: {
      gender: '',
      category: '',
      colors: '',
      priceRange: '',
      rating: ''
    },
    onSubmit: () => {
      setOpenFilter(false);
    }
  });

  const { resetForm, handleSubmit } = formik;

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleResetFilter = () => {
    handleSubmit();
    resetForm();
  };


  return (
    <Page title="Classes Detail">
      <Container>
        <Typography variant="h4" align="center" sx={{ mb: 5 }}>
          {name} Details
        </Typography>
        <Card>
      <Box sx={{ pt: '50%', position: 'relative' }}>
        {status && (
          <Label
            variant="filled"
            color={(status === 'sale' && 'error') || 'info'}
            sx={{
              zIndex: 9,
              top: 16,
              right: 16,
              position: 'absolute',
              textTransform: 'uppercase'
            }}
          >
            {status}
          </Label>
        )}
        <ProductImgStyle alt={name} src={cover} />
      </Box>

      <Stack spacing={2} sx={{ p: 3 }}>
        <Link to="#" color="inherit" underline="hover" component={RouterLink}>
          <Typography variant="subtitle2" noWrap>
            ${newprice} {status}
          </Typography>
        </Link>

        <Stack direction="row" alignItems="center" justifyContent="flex-end">
          <Typography variant="subtitle1">
            <Typography
              component="span"
              variant="button"
              sx={{
                color: 'inherit',
               
              }}
            >
              &nbsp;
            </Typography>
            <Button 
              to="#"
              size="large"
              color="inherit"
              onClick={() => onSignup()}
              >
                Launch Experience Now!
 
             </Button> 
             &nbsp;
             <Button 
              to="#"
              size="large"
              color="inherit"
              onClick={() => scheduleExperience()}
              >
                schedule Experience
 
             </Button> 
          </Typography>
        </Stack>
      </Stack>
    </Card>
        </Container>
        </Page>
  );

}



