import faker from 'faker';
import { sample } from 'lodash';
// utils
import { mockImgProduct, experienceImg } from '../utils/mockImages';


// ----------------------------------------------------------------------

const PRODUCT_NAME = [
  'Tofu with a Twist',
  'Sushi Salmon and Miso',
  'Delectable Burger & Fries',
  'Healthy Pasta',
  'Seared Pork & Veggies',
  'Soft egg over Creamy Rice',
  'Acai Bowl with Fruits',
  'Kebab for the Summer',
 
];
const PRODUCT_COLOR = [
  '#00AB55',
  '#000000',
  '#FFFFFF',
  '#FFC0CB',
  '#FF4842',
  '#1890FF',
  '#94D82D',
  '#FFC107'
];

const PRODUCT_TIME = [
    '30',
    '15',
    '45',
    '30',
    '45',
    '60',
    '90',
    '20'
]

const maxIndex = 8; // get this from db eventually

// ----------------------------------------------------------------------

const products = [...Array(maxIndex)].map((_, index) => {
  const setIndex = index + 1;

  return {
    id: faker.datatype.uuid(),
    cover: experienceImg(setIndex),
    name: PRODUCT_NAME[index],
    price: faker.datatype.number({ min: 4, max: 99, precision: 0.01 }),
    priceSale: setIndex % 3 ? null : faker.datatype.number({ min: 19, max: 29, precision: 0.01 }),
    avgRating: faker.datatype.number({min: 2, max: 5, precision: 0.1}),
    runLength: PRODUCT_TIME[index],
    experienceDate: faker.date.between('2019-01-01', '2022-12-31'),
    author: faker.name.findName(),
    colors:
      (setIndex === 1 && PRODUCT_COLOR.slice(0, 2)) ||
      (setIndex === 2 && PRODUCT_COLOR.slice(1, 3)) ||
      (setIndex === 3 && PRODUCT_COLOR.slice(2, 4)) ||
      (setIndex === 4 && PRODUCT_COLOR.slice(3, 6)) ||
      (setIndex === 23 && PRODUCT_COLOR.slice(4, 6)) ||
      (setIndex === 24 && PRODUCT_COLOR.slice(5, 6)) ||
      PRODUCT_COLOR,
      description: faker.lorem.paragraph(4),
      status: sample(['included in membership', 'additional fee', 'Limted Time Offer', ''])
    
  };
});

export default products;
