

// material
import { Box, Stack, Card, Link, Container, Typography, Button } from '@mui/material';

import ReactPlayer from 'react-player';
import { styled } from '@mui/material/styles';


// components
import { Link as RouterLink } from "react-router-dom";
import Page from '../components/Page';
import { getProductDetails } from '../components/authentication/AuthService';





// ----------------------------------------------------------------------

export default function ProductVideoDetails() {
  // Check to see if a user is logged in.  If not, send them to the login page

  // const authed = !getToken();
  // return authed ? <Navigate to="/login"/> : <Outlet /> // may want to make this a specific page
  
   // console.log("Are we in product details?");
  const productdetails = getProductDetails();
  // console.log(productdetails);
  const { name, cover, price, colors, status, priceSale, description } = productdetails;
  console.log(productdetails.description);
 

  const newprice = status === 'included in membership' ? 0 : price; // If the class is included in memberhsip, set price to $0


  return (
    <Page title="Video Experience Detail">

      <Container>
        <Typography variant="h4" align="center" sx={{ mb: 5 }}>
          {name} VIDEO Details
        </Typography>
        
      <Box sx={{ pt: '0%', position: 'relative' }}>
      
        <ReactPlayer url='/static/illustrations/smoothie_green.webm' 
            controls
            width='100%'
            height='100%'
            position='absolute'
             /> 

       </Box>
      <Stack spacing={2} sx={{ p: 3 }}>
        <Link to="#" color="inherit" underline="hover" component={RouterLink}>
          <Typography variant="subtitle2" noWrap>
            ${newprice}: {status}
          </Typography>
        </Link>

        <Stack direction="row" alignItems="center" justifyContent="flex-end">
          <Typography variant="h6">
            <Typography
              component="span"
              fontSize='1.5rem'
              sx={{
                color: 'inherit',
               
              }}
            >
              Experience Description: <br /> 
              <Typography variant="body1" fontSize='1rem'>    
              
            {description}
              
              </Typography>        
            </Typography>
            
 
          </Typography>
        </Stack>
      </Stack>
  
        </Container>
        </Page>
  );

}



