import React from 'react';
import { Box, Card, Typography, Grid, Container, Stack, Avatar } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import { styled, useTheme } from '@mui/material/styles';




const styles = (theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: theme.palette.secondary.light,
    height: '50em',
  },
  container: {
    // marginTop: theme.spacing(15),
    // marginBottom: theme.spacing(30),
    // display: 'flex',
    // position: 'relative',

  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  image: {
    height: 55,
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),

  },
  curvyLines: {
    backgroundimage: '/static/productCurvyLines.png',
    pointerEvents: 'none',
    position: 'absolute',
    top: -180,
  },
});




const RootStyle = styled('div')(({ theme }) => ({
  width: '100%',
  height: '50vh',
  // top: '50em',
  left: 0,
  zindex: 2,
  color: theme.palette.primary.contrastText,
  backgroundColor: 'transparent'
  // backgroundColor: "#eeeeee",  // this is the background color for the appvalues 
  // backgroundColor: "#d5deeb",  // this is the background color for the appvalues 
  // backgroundImage: "url('/static/productCurvyLines.png')",


}));

export default function AppHowItWorks() {

  const theme = useTheme();

  const titleStyles = {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    position: 'relative',
    // left: '5%',
    zindex: 2,
    color: theme.palette.primary.contrastText,
    backgroundColor: 'transparent',
    width: '100%',
  }

  const cardStyles = {
    backgroundColor: "transparent",
    border: "none",
    boxShadow: "none",
    width: "30em",
    height: "30em",
    margin: 6,
    align: "left",
    // marginLeft: "10em",
    // marginRight: "10em",
  }

  const convenientTitle = "Charles E."
  const convenientLede = "I always wanted to be able to take a class with a real chef on my terms and with my friends.  Now, I can schedule a class for when I want it!"
  const funTitle = "Stefani S."
  const funLede = "While I use cooking apps, this site really brought an experience alive for me. There is something great about being able to interact with folks in a live environment."
  const costTitle = "Toa H."
  const costLede = "As a creator, this platform allows me to connect with folks all over the world -- I get to share what I am most passionate about."

  const myStyle = {
    backgroundImage: `url(/static/homepage_assets/Dough.png)`,
    height: 'auto',
    width: '105vw',
    // minWidth: '2000px',
    // marginTop: '-70px',
    // fontSize: '50px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  };

  return (
    <div style={myStyle} >
      <Grid container styles={styles.container}>
        <RootStyle sx={{ paddingLeft: '20px', paddingRight: '20px', paddingTop: '2em', height: 'auto' }}>
          <Typography variant="h2" textAlign="center" style={titleStyles} component="h2" fontWeight={400} >
            What Excites Our Users
          </Typography>
          <Stack flexDirection="row" flexWrap="wrap" justifyContent="center">

            <Card style={cardStyles}>
              <CardMedia align="center">
                <Avatar alt='charles' src='/static/mock-images/avatars/charles-etoroma-unsplash.jpg' variant='circle' sx={{ height: '12em', width: '12em' }} />
              </CardMedia>

              <CardContent>
                <Typography gutterBottom variant="h4" component="div" align='center' color={theme.palette.primary.contrastText}>
                  {convenientTitle}
                </Typography>
                <Typography variant="h5" color={theme.palette.primary.contrastText} fontWeight={400}>
                  {convenientLede}
                </Typography>
              </CardContent>
            </Card>

            <Card style={cardStyles}>
              <CardMedia align="center">
                <Avatar alt='stefan' src='/static/mock-images/avatars/stefan-stefancik-unsplash.jpg' variant='circle' sx={{ height: '12em', width: '12em' }} />
              </CardMedia>
              <CardContent>
                <Typography gutterBottom variant="h4" component="div" align='center' color={theme.palette.primary.contrastText}>
                  {funTitle}
                </Typography>
                <Typography variant="h5" color={theme.palette.primary.contrastText} fontWeight={400}>
                  {funLede}
                </Typography>
              </CardContent>
            </Card>

            <Card style={cardStyles}>
              <CardMedia align="center">
                <Avatar alt='toa' src='/static/mock-images/avatars/toa-heftiba-unsplash.jpg' variant='circle' sx={{ height: '12em', width: '12em' }} />
              </CardMedia>

              <CardContent>
                <Typography gutterBottom variant="h4" component="div" align='center' color={theme.palette.primary.contrastText}>
                  {costTitle}
                </Typography>
                <Typography variant="h5" color={theme.palette.primary.contrastText} fontWeight={400}>
                  {costLede}
                </Typography>
              </CardContent>
            </Card>
        
          </Stack>
        </RootStyle>
      </Grid>
    </div>

  );
}

