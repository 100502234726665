import React from 'react';
import PropTypes from 'prop-types';
import { Box, Card, Typography, Grid, Container, Stack } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import { styled } from '@mui/material/styles';

import { height, margin } from '@mui/system';



const styles = (theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: theme.palette.secondary.light,
  },
  container: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(30),
    display: 'flex',
    position: 'relative',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  image: {
    height: 55,
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  curvyLines: {
    backgroundimage: '/static/productCurvyLines.png',  
    pointerEvents: 'none',
    position: 'absolute',
    top: -180,
  },
});

const RootStyle = styled(Card)(({ theme }) => ({
    width: '100%',
    height: '50vh',
    top: '10%',
    left: 0,
    zindex: 2,      
    color: theme.palette.primary.contrastText,
    backgroundColor: "#d5deeb",  // this is the background color for the appvalues 
    backgroundImage: "url('/static/productCurvyLines.png')",
   
    
  }));

export default function AppValues() {
  // const { classes } = props;
  
  const convenientTitle = "Relax"
  const convenientLede = "With our Video on Demand feature, cook with your friends, family, or colleagues at a time convenient for you.  No longer do you have to change your schedule for a chef or instructor."
  const funTitle ="Have Fun"
  const funLede = "Our unique experiences allow you to make a wonderful dish while enjoying the company of those you want to spend time with."
  const costTitle = "Affordable"
  const costLede = "For a low, monthly fee you get access to live and on demand experiences that you can enjoy with your friends, family, and colleagues."
      return (
        
        <Grid container styles={styles.container} >
{/*            <img 
            src="/static/productCurvyLines.png"
            position="absolute"
            top="-180"
            alt="curvy lines"
      /> */}
    <RootStyle>
        <Stack direction="row" justifyContent="space-between"> 
        <Card style={{backgroundColor: "transparent", border: "none", boxShadow: "none"}} sx={{ width: 345, height: 400, margin: 6}}>
        <CardMedia
            component="img"
            sx={{objectFit: 'scale-down'}}
            height="50%"        
            image="/static/productValues1.svg"
            alt="suitcase"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" align='center'>
          {convenientTitle}
        </Typography>
        <Typography variant="body2" color="text.secondary">
            {convenientLede}
        </Typography>
      </CardContent>
      </Card>

      <Card style={{backgroundColor: "transparent", border: "none", boxShadow: "none"}} sx={{ width: 345, height: 400,  margin: 6}}>
      
      <CardMedia
        component="img"
        sx={{objectFit: 'scale-down'}}
        height="50%"
        image="/static/productValues2.svg"
        alt="something else"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" align='center'>
          {funTitle}
        </Typography>
        <Typography variant="body2" color="text.secondary">
            {funLede}
        </Typography>
      </CardContent>
      
    </Card>
    <Card style={{backgroundColor: "transparent", border: "none", boxShadow: "none"}} sx={{ width: 345, height: 400,  margin: 6}}>
      
      <CardMedia
        component="img"
        sx={{objectFit: 'scale-down'}}
        height="50%"
        image="/static/productValues3.svg"
        alt="something else"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" align='center'>
          {costTitle}
        </Typography>
        <Typography variant="body2" color="text.secondary">
            {costLede}
        </Typography>
      </CardContent>
      
    </Card>
    </Stack>
    </RootStyle>
          </Grid>
        
      );
    }

 

/* 
AppValues.propTypes = {
  classes: PropTypes.object.isRequired,
};
*/ 

         /* This is the old code 
        <img
          src="/productCurvyLines.png"
          className={classes.curvyLines}
          alt="curvy lines"
        />
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src="/productValues1.svg"
                alt="suitcase"
              />
              <Typography variant="h6" className={classes.title}>
                The best luxury hotels
              </Typography>
              <Typography variant="h5">
                'From the latest trendy boutique hotel to the iconic palace with XXL pool'
                ', go for a mini-vacation just a few subway stops away from your home.'
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src="productValues2.svg"
                alt="graph"
              />
              <Typography variant="h6" className={classes.title}>
                New experiences
              </Typography>
              <Typography variant="h5">
                'Privatize a pool, take a Japanese bath or wake up in 900m2 of garden… '
                'your Sundays will not be alike.'
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src="/productValues3.svg"
                alt="clock"
              />
              <Typography variant="h6" className={classes.title}>
                Exclusive rates
              </Typography>
              <Typography variant="h5">
                'By registering, you will access specially negotiated rates '
                'that you will not find anywhere else.'
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Stack>



    ----

    This is even older

    <Paper style={styles.curvyLines}>
          Here is some text just in case
    <RootStyle>    
    <Card sx={{ maxWidth: 345 }}>
      <CardMedia
        component="img"
        height="100%"
        image="/static/productValues1.svg"
        alt="suitcase"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Lizard
        </Typography>
        <Typography variant="body2" color="text.secondary">
            From the latest trendy boutique hotel to the iconic palace with XXL pool, go for a mini-vacation just a few subway stops away from your home.
        </Typography>
      </CardContent>
      
    </Card>
    </RootStyle>
    </Paper>
    */