import { useFormik } from 'formik';
import { useEffect, useState } from 'react';

// material
import { Stack, Container, Typography} from '@mui/material';



// components
import { Navigate } from "react-router-dom";
import Page from '../components/Page';

import {
  ProductSort,
  ProductList,
  ProductCartWidget,
  ProductFilterSidebar
} from '../components/_dashboard/products';
//
import PRODUCTS from '../feed/experiences';
import { getToken } from "../components/authentication/AuthService";


// ----------------------------------------------------------------------

export default function Products() {
  // Check to see if a user is logged in.  If not, send them to the login page

  // const [authed, setAuthed] = useState(true);

  // useEffect(() => {
  //   setAuthed(!getToken());
  // },[]);
  
  const authed = !getToken();
  const thisisFalse = false;
  return authed ? <Navigate to="/login" replace={!thisisFalse}/> : EcommerceShop(); // may want to make this a specific page
}

function EcommerceShop() {
  const [openFilter, setOpenFilter] = useState(false);
  // console.log("Are we in products?");

  const formik = useFormik({
    initialValues: {
      gender: '',
      category: '',
      colors: '',
      priceRange: '',
      rating: ''
    },
    onSubmit: () => {
      setOpenFilter(false);
    }
  });

  const { resetForm, handleSubmit } = formik;

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleResetFilter = () => {
    handleSubmit();
    resetForm();
  };

  return (
    <Page title="Experiences | Food Razzle">
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Experiences
        </Typography>

        <Stack
          direction="row"
          flexWrap="wrap-reverse"
          alignItems="center"
          justifyContent="flex-end"
          sx={{ mb: 5 }}
        >
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
            <ProductFilterSidebar
              formik={formik}
              isOpenFilter={openFilter}
              onResetFilter={handleResetFilter}
              onOpenFilter={handleOpenFilter}
              onCloseFilter={handleCloseFilter}
            />
            <ProductSort />
          </Stack>
        </Stack>

        <ProductList products={PRODUCTS} />
        <ProductCartWidget />
      </Container>
    </Page>
  );
}
