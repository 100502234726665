// material
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Card, Grid, Typography, Button, Paper } from '@mui/material';

// utils
import ReactPlayer from 'react-player';
import { styled, useTheme } from '@mui/material/styles';
import { getAccount } from '../../authentication/AuthService';


// ----------------------------------------------------------------------

export default function AppForTheAudience() {

  const theme = useTheme();
  const [playing, setOpen] = useState(false);  // Do not start the video unless the user asks to do so
  const [controlsvisible, setOpen1] = useState(false);
  const [buttonText, setButtonText] = useState("Play!");


  const navigate = useNavigate();
  const videoURL = '/static/homepage_assets/how-it-works-1080p-vimeo.mp4';
  const thumbnailURL = '/static/homepage_assets/how-it-works-thumbnail-cropped.png';
  // const videoURL = '/static/illustrations/videoexp.mp4';

  const account = getAccount();
  const videoEntryText = "Watch the video to learn more about Food Razzle"
  const buttonURL = ((account.displayName) === 'Guest') ? '/register' : '/dashboard/products';  // update URL


  const RootStyle = styled('div')(({ theme }) => ({
    width: '100vw',
    height: 'auto',
    position: 'flex',
    // top: '-5em',
    left: 0,
    zindex: 2,
    color: theme.palette.primary.main,
    backgroundColor: '#eeeeee',
    align: 'center'
  }));

  const PaperStyle = {
    width: '80%',
    height: 'auto',
    position: 'relative',
    marginTop: '2em',
    marginBottom: '2em',
    padding: '1em',
    left: '10vw',
    zindex: 2,
    color: theme.palette.primary.main,
    backgroundColor: '#ffffff'
  }

  const textList = {
    pt: 0,
    // paddingLeft: 5,
    // paddingRight: 5,
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'left',
    maxWidth: '100%',
    height: 'auto',
    paddingBottom: '1em',
    color: '#757575',
    fontWeight: '400'
  }

  const textList2 = {
    pt: 0,
    // paddingLeft: 5,
    // paddingRight: 5,
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'left',
    maxWidth: '100%',
    height: 'auto',
    paddingBottom: '1em',
    color: '#757575',
    fontWeight: '400'
  }

  const ulStyle = {
    marginTop: '5%',
    width: '50ch'
  }

  const subSectionStyle = {
    display:'flex',
    // marginLeft: '10em',
    marginTop: '2%',
    marginLeft: '5%',
    marginRight: '5%',
    justifyContent: 'space-evenly',
    height: 'auto',
    flexDirection: "row",
    flexWrap: "wrap",
    // spacing: '20%',
  }



  return (
    <RootStyle>
      <Grid container >
        <Grid item xs={12} md={12} lg={12} >
          <Paper container style={PaperStyle} elevation={10}>
            <Typography variant="h2" sx={{ pt: 0, textAlign: 'center', marginLeft: '0em', width: '100%', paddingTop: '1em', color: '#757575', fontWeight: '400' }}   >
              Why Subscribe?
            </Typography>
            <Grid style={subSectionStyle}>
              <img alt='chef' src='/static/homepage_assets/virtual_experience.jpg' style={{aspectRatio:'16/7', width:'700px'}}   />
              <ul style={ulStyle}>
                <li>
                  <Typography variant="h4" style={textList2} >
                    Access to a large catalog of cooking content
                  </Typography>
                </li>
                <li>
                  <Typography variant="h4" style={textList2} >
                    Host virtual events with your friends and family
                  </Typography>

                </li>
                <li>
                  <Typography variant="h4" style={textList2} >
                    Interact with live chefs and restauranteurs
                  </Typography>
                </li>
              </ul>
            </Grid>
            <br />
            <Grid item style={{...subSectionStyle, flexWrap: 'wrap-reverse'}}>
              <ul style={ulStyle}>
                <li>
                  <Typography variant="h4" style={textList} >
                    Import or create your own recipes and store them in your personal digital recipe book
                  </Typography>
                </li>
                <li>
                  <Typography variant="h4" style={textList} >
                    Schedule live events or watch recorded content with your friends
                  </Typography>
                </li>
              </ul>
              <img alt='features' src='/static/homepage_assets/features-scheduler.jpg' style={{aspectRatio:'16/10.5', width:'700px'}} />
            </Grid>
          </Paper>
          <Paper container style={PaperStyle} elevation={10}>
            <Typography variant="h2" sx={{ pt: 0, paddingLeft: 5, paddingRight: 5, marginLeft: '0em', textAlign: 'center', width: '100%', paddingTop: '1em', color: '#757575', fontWeight: '400' }}   >
              For the Creator
            </Typography>
            <Grid item style={subSectionStyle} >
              <img alt='globe' src='/static/homepage_assets/globe-unsplash.jpg' style={{aspectRatio:'16/10.6', width:'600px'}} />
              <ul style={ulStyle}>
                <li>
                  <Typography variant="h4" style={textList} >
                    Exposure to local and global audiences on your terms
                  </Typography>
                </li>
                <li>
                  <Typography variant="h4" style={textList} >
                    Monetize your expertise directly from your viewers
                  </Typography>

                </li>
                <li>
                  <Typography variant="h4" style={textList} >
                    No viewer minimum necessary to start earning
                  </Typography>
                </li>
              </ul>
            </Grid>
            <br />
            <Grid item style={{...subSectionStyle, flexWrap: 'wrap-reverse'}} >
              <ul style={ulStyle}>
                <li>
                  <Typography variant="h4" style={textList} >
                    Show your skills! Become a recognized expert to a hungry audience
                  </Typography>
                </li>
                <li>
                  <Typography variant="h4" style={textList} >
                    For the restauranteur: build your brand and convert local subscribers to in-person patrons
                  </Typography>
                </li>
              </ul>
              <img alt='creator-setup' src='/static/homepage_assets/creator_setup.jpeg' style={{aspectRatio:'16/9.9', width:'600px'}} />
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </RootStyle>


  );
}