import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Card, Link, Container, Typography } from '@mui/material';
// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
// import { AddExperienceForm } from '../components/authentication/Experiences';
import { AddExperienceForm } from '../components/authentication/Experiences';
import AuthSocial from '../components/authentication/AuthSocial';
import { Hero } from '../components/_dashboard/app';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  // maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  // justifyContent: 'center',
  // padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------
/* took out  <AuthSocial /> right before <RegisterForm> */

export default function AddExperience() {
  return (
    <RootStyle title="Register | Food Razzle">
      


        <SectionStyle>
        <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              These are our latest TERMS OF SERVICE
            </Typography>
            <img alt="tos" src="/static/illustrations/illustration_register.png" />

        </SectionStyle>


      <Container>
        <ContentStyle>
          <Box sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              eRecipe Book
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              Browse, add, and edit your favorite recipes!
            </Typography>
          </Box>

          <AddExperienceForm />

          


        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
