import React, { useEffect, useState } from 'react';
import axios from 'axios';

const formatPrice = ({ amount, currency, quantity }) => {
  const numberFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    currencyDisplay: 'symbol',
  });
  const parts = numberFormat.formatToParts(amount);
  let zeroDecimalCurrency = true;
  for (let i = 0; i < parts.length; i += 1) {
    if (parts[i].type === 'decimal') {
      zeroDecimalCurrency = false;
    }
  }
  amount = zeroDecimalCurrency ? amount : amount / 100;
  const total = (quantity * amount).toFixed(2);
  return numberFormat.format(total);
};

function Checkout(props) {

  const { REACT_APP_STRIPE } = process.env;
  const { token, parentFnc, closeFnc } = props;

  const [quantity, setQuantity] = useState(1);
  const [amount, setAmount] = useState(0);
  const [currency, setCurrency] = useState('USD');
  const [picURL, setImage] = useState('');


  useEffect(() => {
    async function fetchConfig() {
      // Fetch config from our backend.
      const requestBody = {
        endpoint: "config",
      };
  
      const requestConfig = {
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json',
        },
      };
  
      await axios.post(REACT_APP_STRIPE, requestBody, requestConfig).then(res => {
        console.log("Reponse: ", res);
        setAmount(res.data.unitAmount);
        setCurrency(res.data.currency);
        setImage(res.data.image); // use the image from the product 
        // parentFnc(res.data.checkoutUrl);
      }).catch(error => {
        console.log("ERROR: ", error);
      });

      // const {
      //   unitAmount,
      //   currency,
      //   image
      // } = await fetch('/config').then(r => r.json());
      // setAmount(unitAmount);
      // setCurrency(currency);
      // setImage(image); // use the image from the product 
    }
    fetchConfig();
  }, []);

  const handleCheckout = async (e) => {
    e.preventDefault();
    console.log("In handleCheckout");
    const requestBody = {
      endpoint: "createCheckoutSessionEndpoint",
      quantity,
    };

    const requestConfig = {
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
      },
    };

    await axios.post(REACT_APP_STRIPE, requestBody, requestConfig).then(res => {
      console.log("Reponse: ", res);
      parentFnc(res.data.checkoutUrl);
    }).catch(error => {
      console.log("ERROR: ", error);
    });
  }

  return (
    <div className="sr-root">
      <div className="sr-main">
        <section className="container">
          <div>
            <h1>Thank you for the Tip</h1>
            <h4>Creators are supported by your generosity</h4>
            <div className="pasha-image">
              <img
                alt="Random asset from Picsum"
                src={picURL}
                width="140"
                height="160"
              />
            </div>
          </div>
          <form onSubmit={handleCheckout} >
            <div className="quantity-setter">
              <button
                className="increment-btn"
                disabled={quantity === 0}
                onClick={() => setQuantity(quantity - 1)}
                type="button"
              >
                -
              </button>
              <input
                type="number"
                id="quantity-input"
                min="1"
                max="10"
                value={quantity}
                name="quantity"
                readOnly
              />
              <button
                className="increment-btn"
                disabled={quantity === 10}
                onClick={() => setQuantity(quantity + 1)}
                type="button"
              >
                +
              </button>
            </div>
            <p className="sr-legal-text">Number of copies (max 10)</p>

            <button role="link" id="submit" type="submit" >
              Tip {formatPrice({ amount, currency, quantity })}
            </button>
          </form>
          <button onClick={closeFnc}>Close</button>
        </section>
      </div>
    </div>
  );
};

export default Checkout;
