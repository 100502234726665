import { useState } from 'react';
import { Outlet } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import DashboardFooter from '../../components/_dashboard/app/DashboardFooter';
import { MHidden } from '../../components/@material-extend';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  // paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    // paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  // console.log('We are in DashboardLayout');
  // console.log(open);

  return (
    <RootStyle>

      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <MHidden width="lgUp">
        <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      </MHidden>

      <MainStyle>
        <Outlet />
        <DashboardFooter />
      </MainStyle>

    </RootStyle>

  );
}
