
// material
import { Stack, Paper, Divider, Button, Box } from '@mui/material';

// imports for Snackbar UI
import * as React from 'react';
import { useState, useEffect } from 'react';
import '@fontsource/lato';
import ReactPlayer from 'react-player'
import captureThumbnail from '../../../utils/captureThumbnail';


function ExperienceTemplate(props) {
  const {
    videoURL,
    experienceName,
    experienceShortDescription,
    experienceNotes,
    create,
    selectThumbnail,
    parentCallback
  } = props;

  const [fileURL, setFileURL] = useState('');
  const [thumbnail, setThumbnail] = useState('');

  // const scaleFactor = 0.25;

  const handleThumbnail = () => {
    const el = document.getElementById('videoPlayer');
    // console.log(el);
    const pic = captureThumbnail(el);
    // console.log("Pic: ", pic);
    setThumbnail(pic);
    setFileURL(pic.base64);
  }

  useEffect(() => {
    // console.log("In the fileUrl Effect");
    parentCallback(thumbnail);
  }, [thumbnail]);

  // console.log("videoURL = ", videoURL);

  return (
    <div >
      {/* <h1 style={{ fontFamily: 'lato' }} align='center'>{experienceName}</h1>
      <h4 align='center' style={{maxWidth:'35ch'}}>{experienceShortDescription}</h4> */}
      {videoURL !== '' &&
        <ReactPlayer
          id='videoPlayer'
          url={videoURL}
          controls
          // style={{ margin: '10px' }}
          config={{
            file: {
              attributes: {
                controlsList: 'nodownload'
              }
            }
          }}
        />}
      <br />
      <hr style={{ width: '90%', margin: 'auto' }} />

      {/* <Stack direction='row' divider={<Divider orientation="vertical" flexItem />}> */}
      <Stack direction='column' minWidth='65%'>
        {/* <h4 style={{ marginLeft: '5%' }}>Notes</h4> */}
        {/* <h3 align='left'>{experienceNotes}</h3> */}
        {/* <div style={{ marginLeft: '10%' }}>
            <ul id='notesList' style={{ marginLeft: '10%', marginRight: '10%', maxHeight: '250px' }} >

            {experienceNotes}

            </ul>
          </div> */}
        {create && selectThumbnail &&
          <>
            <Button onClick={handleThumbnail}>Click to take Thumbnail</Button>
            {/* <img
              alt=''
              src={fileURL}
            /> */}
          </>}
      </Stack>

    </div>
  );
}

export default ExperienceTemplate;