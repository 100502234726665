// import * as React from 'react';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import CardActionArea from '@mui/material/CardActionArea';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { green, red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ExperienceTemplate from './ExperienceTemplate';

// interface ExpandMoreProps extends IconButtonProps {
//   expand: boolean;
// }

const ExpandMore = styled((props /*: ExpandMoreProps */) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function ExperienceCard(props) {
  const [expanded, setExpanded] = useState(false);

  const { experience, parentCallback } = props;

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    // <Card sx={{ maxWidth: '20%', minHeight: '10%', maxHeight: '20%' }} >
    <Card sx={{ maxWidth: 345, minHeight: 400, maxHeight: 500 }} > 
      <CardActionArea onClick={() => {
        parentCallback(true);
      }}>
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: green[500] }} aria-label="experience">
              E
            </Avatar>
          }
          action={
            <IconButton aria-label="settings">
              <MoreVertIcon />
            </IconButton>
          }
          title={experience.title}
          subheader={experience.updatedAt}
        />
        <CardMedia
          component="img"
          height="194"
          image={experience.s3FileURL}
          alt="Experience Pic"
          aspectratio="16/9"
          // display="block"
          objectFit="cover"
        />
        <CardContent>
          <Typography className='card-shortdescription' variant="body2" color="text.secondary">
            {experience.shortDescription}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions disableSpacing>
        <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      {/* <Collapse in={expanded} timeout='auto' unmountOnExit>
        <CardContent>
          <ExperienceTemplate
            croppedURL={experience.s3FileURL}
            experienceName={experience.title}
            experienceShortDescription={experience.shortDescription}
            experiencePrepTime='10 min'
            experienceCookTime='10 min'
            experienceReadyTime='20 min'
          />
        </CardContent>
      </Collapse> */}
    </Card>
  );
}
