import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton } from '@mui/material';
// components
import { MHidden } from '../../components/@material-extend';
//
import Searchbar from './Searchbar';
import DashboardNavSection from '../../components/DashboardNavSection';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import NotificationsPopover from './NotificationsPopover';
import DashboardSidebar from './DashboardSidebar';
import sidebarConfig from './SidebarConfig';


// ----------------------------------------------------------------------

const DRAWER_WIDTH = 0; // need to set back to 280 if use sidebar
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  // backgroundColor: alpha(theme.palette.background.appbar, 0.8),
  backgroundColor: "#212121",
  // backgroundColor: theme.palette.background.appbar,
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar({ onOpenSidebar }) {
  return (
    <RootStyle>
      <ToolbarStyle>
        <MHidden width="smUp">
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: '#FFF' }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>

        {/* <Searchbar />  taking out the searchbar for now
        */}

        <Box sx={{ flexGrow: 1 }} />
        <MHidden width="smDown">
          <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
            <DashboardNavSection navConfig={sidebarConfig} />

            {/* <LanguagePopover /> */}
            {/* <NotificationsPopover /> */}
            <AccountPopover />
          </Stack>
        </MHidden>
      </ToolbarStyle>
    </RootStyle>
  );
}
