// material
import { styled } from '@mui/material/styles';
import { Box, Card, Container, Typography } from '@mui/material';

// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';



// const htmlDoc = {__html: HTMLPage };

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    margin: 5,
    padding: 5,
    backgroundColor: theme.palette.background.neutral,
    
  }));


  
  const SectionStyle = styled(Card)(({ theme }) => ({
    width: '100%',
    maxWidth: 464,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(2, 0, 2, 2)
  }));
  
  const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 800,
    width: '100%',
    margin: 'auto',
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(12, 0)
  }));

  export default function MembTerms() {


    // height of 7000 below was based on guess and check -- need to update anytime a change is made to TOS document
    function createMarkup() { 
      return {
        __html: '<iframe  frameBorder="0" overflow="auto" scrolling="yes" width="100%" height="2500" type="text/html" src="/static/frmembterms.html" title="Membership Terms"></iframe>'}; 
       
      
      };

    return (
      <RootStyle title="Membership Terms">
        

 

              <div dangerouslySetInnerHTML={createMarkup()} />

              </RootStyle>

    );
  }