// material
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Card, Grid, Typography, Button } from '@mui/material';

// utils
import ReactPlayer from 'react-player';
import { styled, useTheme } from '@mui/material/styles';
import { getAccount } from '../../authentication/AuthService';


// ----------------------------------------------------------------------

export default function AppExplanation() {

  const theme = useTheme();
  const [playing, setOpen] = useState(false);  // Do not start the video unless the user asks to do so
  const [controlsvisible, setOpen1] = useState(false);
  const [buttonText, setButtonText] = useState("Play!");
  const { REACT_APP_FR_VIDEO } = process.env;


  const navigate = useNavigate();
  const videoURL = REACT_APP_FR_VIDEO;
  const thumbnailURL = '/static/homepage_assets/how-it-works-thumbnail-cropped.png';
  // const videoURL = '/static/illustrations/videoexp.mp4';

  const account = getAccount();
  const videoEntryText = "Watch the video to learn more about Food Razzle"
  const buttonURL = ((account.displayName) === 'Guest') ? '/register' : '/dashboard/products';  // update URL


  const RootStyle = styled('div')(({ theme }) => ({
    width: '100vw',
    height: 'auto',
    position: 'relative',
    // top: '-2em',
    left: 0,
    // zindex: 2,
    padding: 3,
    color: theme.palette.primary.main,
    backgroundColor: '#eeeeee'
  }));

  const reactPlayerstyle = {
    // width: '100vw',
    // height: 'auto',
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: '#eeeeee',
    // boxShadow: theme.customShadows.z12,
    // marginTop: 2,
    marginTop: '2%',
    marginRight: 1,
    marginLeft: 1,
    // paddingBottom: 20
  };

  return (
    <RootStyle>
      <Grid container >
        <Typography variant="h2" sx={{ pt: 0, paddingLeft: 5, paddingRight: 5, textAlign: 'center', width: '100%', marginTop: '3%', color: '#757575', fontWeight: '400' }}   >
          How It Works
        </Typography>
        <Grid item xs={12} md={12} lg={12} style={reactPlayerstyle} flexDirection="column" >
          <ReactPlayer url={videoURL}
            alt="No video here"
            playing
            width="80%"
            height="auto"
            controls
            light={thumbnailURL}
            style={{
              aspectRatio: '16/9',
              marginBottom: '5%'
              // width: '50%'
            }}
          />
        </Grid>
      </Grid>
    </RootStyle>


  );
}