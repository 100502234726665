import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
// material
import {
  Stack,
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Grid,
  Paper,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// imports for Snackbar UI
import * as React from 'react';
// import ReactPlayer from 'react-player'
import axios from 'axios';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import Button from '@mui/material/Button';
import RecipesParser from "recipes-parser";
import units from "recipes-parser/lib/nlp/en/units.json";
import globalUnit from "recipes-parser/lib/nlp/en/global_unit.json";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
// import VideoThumbnail from 'react-video-thumbnail'; // use npm published version

import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import AddLinkIcon from '@mui/icons-material/AddLink';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import CloseIcon from '@mui/icons-material/Close';
import Scrollbar from '../../Scrollbar';



import { putFile, getFileURL, getToken, getUser, checkFileExists } from '../AuthService';
import ExperienceTable from './ExperienceTable';
import ExperienceCard from './ExperienceCard';

import raw from "./rules.pegjs";
// import * as path from "path";

import * as queries from '../../../graphql/queries'
// import * as customQueries from '../../../graphql/custom_queries';
import * as mutations from '../../../graphql/mutations';
// import * as subscriptions from '../../../graphql/subscriptions';
import ExperienceTemplate from './ExperienceTemplate';
// import ExperienceCard from './ExperienceCard';
// import VideoUpload from '../VideoUpload'
import VideoSelect from '../VideoSelect'
import TimeLineGraph from '../../charts/TimeLineGraph'
import { RecipeSubmitForm } from '../account';
import './experience_styles.css';


function ExperienceSubmitForm() {

  const navigate = useNavigate();
  const { REACT_APP_SIGNED_URL_VIDEO, REACT_APP_SIGNED_URL, REACT_APP_VOD_URL, REACT_APP_VIDEO_ROOM_URL } = process.env;

  // Experience Creation Wizard Steps
  const stepArray = {
    DETAILS: 'Enter Experience Details',
    VIDEO: 'Add Video',
    THUMBNAIL: 'Choose Video Thumbnail',
    RECIPE: 'Connect to Recipe',
    ACCESS: 'Make Experience Public or Private',
    SUBMIT: 'Submit',
    LINK: 'Invite Friends!',
  };


  // Experience View Read Only
  const stepArrayReadOnly = {
    VIDEO: 'View Video',
    LINK: 'Invite Friends!',
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cognitoID, setCognitoID] = useState();
  const [token, setToken] = useState();
  const [presignedUploadUrl, setPreSignedUploadUrl] = useState('');
  const [picUrl, setPicUrl] = useState();
  const [experienceToEditId, setExperienceToEditId] = useState();
  const [create, setCreate] = useState(true);
  const [isEdit, setIsEdit] = useState(true);
  const [croppedURL, setCroppedURL] = useState();
  const [accessLevel, setAccessLevel] = useState(false);
  const [experiencePicData, setExperiencePicData] = useState();
  const [experienceType, setExperienceType] = useState(false);
  const [experienceDuration, setExperienceDuration] = useState(30);
  const [downloadedExperiences, setDownloadedExperiences] = useState([]);
  const [localVideoURL, setLocalVideoURL] = useState('');
  const [videoFile, setVideoFile] = useState();
  const [isUploading, setIsUploading] = useState(false);
  const [percent, setPercent] = useState(0);
  const [thumbnail, setThumbnail] = useState();
  const [readOnlyMode, setReadOnlyMode] = useState(false);
  const [mp4VideoURL, setMp4VideoURL] = useState('');
  const [experienceLink, setExperienceLink] = useState('');
  const [cookies, setCookies] = useState([]);
  const [step, setStep] = useState(stepArray.DETAILS);
  const [videoProcessing, setVideoProcessing] = useState(false);
  const [selectedXpData, setSelectedXpData] = useState([]);
  const [thumbnailCard, setThumbnailCard] = useState('');
  // const [totalSteps, setTotalSteps] = useState(stepArray.DETAILS);

  // we need to keep a reference of the toastId to be able to update it
  const toastId = React.useRef(null);

  let rules;
  let parser;

  useEffect(async () => { // Check to see if user is already logged in
    const user = getUser();
    console.log(user);

    if (!user) {
      navigate('/login?continue=experiencesubmit', { replace: true });
    }
    const token = getToken();
    setToken(token);

    // Auth.currentSession()
    //   .then(data => {
    //     // console.log(data)
    //     setCognitoID(data.getAccessToken().decodePayload().username)
    //     const token = getToken();
    //     // console.log("Token: ", token);
    //     setToken(token);
    //   })
    //   .catch(err => {
    //     console.log(err)
    //     navigate('/login?continue=experiencesubmit', { replace: true });
    //   });
  }, []);

  // On state changes
  useEffect(() => {
    getExperiences();
  }, [cognitoID]);

  useEffect(() => {
    // console.log("Experience type", experienceType);
    getExperiences();

  }, [experienceType]);

  useEffect(() => {
    if (!isModalOpen) { // On the modal closing, get all the experiences 
      getExperiences();
    }
  }, [isModalOpen]);

  useEffect(() => {
    // console.log("picUrl changed");
  }, [picUrl]);

  // Brief: Only open the modal after the Experience Data has been updated
  useEffect(() => {
    if (selectedXpData.id) { // If data was retrieved from GraphQL querey
      setIsModalOpen(true);
    }
  }, [selectedXpData]);

  // Brief: Upload video to S3 bucket
  useEffect(() => {
    // console.log("Pre-signed URL: ", presignedUploadUrl);

    if (presignedUploadUrl === '') return;

    const req = new XMLHttpRequest(); // Initialize request
    req.open('PUT', presignedUploadUrl); // Specify request type and endpoint
    req.setRequestHeader("Content-Type", "video/*");
    // Add event listener to upload listening for progress. Function fires
    // regularly, with progress contained in "e" object
    req.upload.addEventListener('progress', (e) => {
      // Every time progress occurs
      const percentComplete = (e.loaded / e.total); // Calculate percentage complete via "e" object
      // console.log("Percent Complete: ", percentComplete);
      if (toastId.current === null) {
        toastId.current = toast.success(`Upload in Progress. ${Math.round(percentComplete * 100)}% complete`, { progress: percentComplete });
      } else {
        toast.update(toastId.current, { render: `Upload in Progress. ${Math.round(percentComplete * 100)}%`, progress: percentComplete });
      }
    })

    // Fires when upload is complete
    req.addEventListener('load', () => {
      // console.log(req.status); // Response status code
      // console.log(req.response); // Request response
      toast.success("Created Experience", {
        onClose: () => {
          // console.log("Done with toast")
          formik.resetForm(formik.initialValues);
          // setIsModalOpen(false);
          setIsUploading(false);
          toastId.current = null;
          setStep(stepArray.LINK);
          setIsUploading(true);
        }
      });
    })

    req.send(videoFile); // Sends request
    setPreSignedUploadUrl(''); // Reset presignedUploadUrl
  }, [presignedUploadUrl]);

  // Brief: Dummy handler
  const handleExperienceDuration = (e) => {
    console.log(e.target.value);
    setExperienceDuration(e.target.value);
  };

  // Brief: Set state variables and formik for a new experience
  const handleCreateNewExperience = () => {
    setCreate(true);
    setStep(stepArray.DETAILS);
    resetFormik();
    setIsModalOpen(true);
  }

  // Brief: Handle table selection of experience. Open experience modal with appropriate text and Experience Template filled
  const handleSelectedExperienceToEdit = async (id, index) => {
    let experienceData = [];
    let videoData = [];

    // Get experience info from GraphQL model
    try {
      const experience = await API.graphql(graphqlOperation(queries.getExperience, { id }));
      experienceData = experience.data.getExperience;
    } catch (err) {
      // console.log("Error", err)
      experienceData = err.data.getExperience;
    }

    // Get video info from GraphQL model
    try {
      const video = await API.graphql(graphqlOperation(queries.listVodInfos, { filter: { srcVideo: { contains: id } } }));
      videoData = video.data.listVodInfos.items;
      // console.log("VideoData = ", videoData);
      // Run signed cookie function
      getSignedCookie(token, videoData[0].mp4Urls.slice(1, -1).replace(REACT_APP_VOD_URL, '')); // Sending mp4 URL. Remove first and last characters which are '[' and ']', respectively
      setMp4VideoURL(videoData[0].mp4Urls.slice(1, -1).replace(REACT_APP_VOD_URL, ''));
      // setVideoProcessing(false);
      setIsUploading(false);
      // setLocalVideoURL(videoData[0].hlsUrl); // Remove first and last characters which are '[' and ']', respectively
      // setLocalVideoURL(videoData[0].mp4Urls.slice(1,-1)); // Remove first and last characters which are '[' and ']', respectively


    } catch (err) {
      // console.log("Error", err) // Video is not ready
      setIsUploading(true); // Set video processing indicator to true
      alert("Please log in to continue!");
      return;
      // videoData = err.data.getVodInfo;
    }

    // console.log(experienceData);
    // Capture experience data
    // setSelectedXpData(experienceData);
    setSelectedXpData(downloadedExperiences[index]);
    formik.values.experienceName = experienceData.title;
    formik.values.experienceShortDescription = experienceData.shortDescription;
    formik.values.notes = experienceData.notes ?? '';
    setAccessLevel(experienceData.accessLevel);
    setExperienceToEditId(id);
    if (cognitoID === experienceData.createdBy.id) { // If the current user is the owner of this experience, don't create a new recipe
      setCreate(false);
      setStep(stepArray.DETAILS); // Set to Experience Details page
    }
    else { // Read-only mode
      setReadOnlyMode(true);
      setStep(stepArrayReadOnly.VIDEO);
    }
    // console.log(create);
    // setIsModalOpen(true); // Moved to after selectXpData is updated
  }

  const handlePreviousStep = () => {
    if (step === stepArray.DETAILS) return;  // If it's already at the beginning
    if (readOnlyMode && step === stepArray.DETAILS) return;  // If it's already at the beginning of a Read-Only view (i.e. VIDEO)
    if (readOnlyMode) {
      setStep(stepArrayReadOnly.VIDEO) // Only one possibility for PREVIOUS (i.e. VIDEO)
      return
    }
    // Find equivalent index of stepArray
    const currentIndex = Object.keys(stepArray).map(i => stepArray[i]).indexOf(step);
    setStep(stepArray[Object.keys(stepArray)[currentIndex - 1]]);
  }

  const handleNextStep = () => {
    if (step === stepArray.LINK) return; // It it's at the Link step, do nothing
    if (readOnlyMode) {
      setStep(stepArrayReadOnly.LINK) // Only one possibility for NEXT (i.e. LINK)
      return
    }
    // Find equivalent index of stepArray
    const currentIndex = Object.keys(stepArray).map(i => stepArray[i]).indexOf(step);
    setStep(stepArray[Object.keys(stepArray)[currentIndex + 1]]);
  }

  const ExperienceSchema = Yup.object().shape({
    experienceName: Yup.string()
      .min(2, 'Too Short! Min 2 characters.')
      .max(30, 'Too Long! Max 30 characters.')
      .required('Experience Name required'),
    experienceShortDescription: Yup.string()
      .min(2, 'Too Short! Min 2 characters.')
      .max(50, 'Too long! Max 50 characters.')
      .required('Short Description Required'),
    experiencePrepTime: Yup.number().typeError('Must be a number'),
    experienceCookTime: Yup.number().typeError('Must be a number'),
    experienceReadyTime: Yup.number().typeError('Must be a number'),
  });

  const formik = useFormik({
    initialValues: {
      experienceName: '',
      experienceShortDescription: '',
      experiencePrepTime: '',
      experienceCookTime: '',
      experienceReadyTime: '',
      experienceLongDescription: '',
      experienceIngredients: '',
      directions: '',
      equipment: '',
      notes: ''
    },

    validationSchema: ExperienceSchema,
    onSubmit: async () => {

      // while(isUploading);
      submitExperience(create, experienceToEditId)
    }
  });

  const resetFormik = () => {
    formik.values.experienceName = '';
    formik.values.experienceShortDescription = '';
    formik.values.experienceLongDescription = '';
    formik.values.experiencePrepTime = '';
    formik.values.experienceCookTime = '';
    formik.values.experienceReadyTime = '';
    formik.values.experienceIngredients = '';
    formik.values.directions = '';
    formik.values.equipment = '';
    formik.values.notes = '';
    setLocalVideoURL('');
  }

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  const parseText = async (type, incomingText, ingredients) => {
    let testFieldSplit = '';
    let results = [];

    if (ingredients) {  // If it's an ingredients list
      await fetch(raw)
        .then(r => r.text())
        .then(text => {
          // console.log('text decoded:', text);
          rules = text;
          parser = new RecipesParser(rules, units, globalUnit);
          console.log("Parser: ", parser);
        });

      console.log("Original recipe: ", incomingText);

      let recipeString = incomingText;

      // Remove special characters other than letters, numbers, spaces, forward slash, parentheses, periods, and fraction unicodes
      recipeString = recipeString.replace(/[^a-zA-Z0-9' ''/'\u00BC-\u00BE\u2150-\u215E\u2189'('')''\n''.']/g, '');


      // const recipeStringArray = formik.values.recipeLongDescription.split("\n");
      const recipeStringArray = recipeString.split("\n");
      console.log("Recipe Ingredients: ", recipeStringArray);
      // const results = [];

      recipeStringArray.forEach((element) => {
        // console.log("Element: ", element);
        console.log("Parser: ", parser);
        results.push(parser.getIngredientsFromText([element.trim()], true));  // Trim leading and trailing whitespaces
      });

      const testField = parseTextFromRecipeJSON(results);
      testFieldSplit = testField.split("\n"); // Separate at each newline

    }
    else {
      results = incomingText;
      testFieldSplit = incomingText.split("\n");
      // results = testFieldSplit;
    }

    const el = document.getElementById(type);
    el.innerHTML = ''; // Empty the field

    testFieldSplit.forEach((text) => {
      const listEl = document.createElement("li");
      listEl.innerHTML = text;
      el.appendChild(listEl);
    })
    return results;

  };

  const parseTextFromRecipeJSON = (results) => {
    let testField = [];

    results.forEach((element) => {
      // Creating the text display entry
      if (element[0].result) {
        testField += element[0].result.amount
        testField += ' ';
        if (!element[0].result.unit) {
          testField += '';
        }
        else {
          testField += element[0].result.unit;
        }
        testField += ' ';
        testField += element[0].result.ingredient;
        testField += '\n';
      }
      else {
        testField += element[0].unknown.instruction;
        testField += '\n';
      }
    })

    return testField.trim();
  }

  const submitExperience = async (create, id) => {
    let returnedId = '';

    try {
      if (create) {
        const experience = await API.graphql(graphqlOperation(mutations.createExperience, {
          input: {
            experienceCreatedById: cognitoID,
            experienceHostedById: cognitoID,
            title: formik.values.experienceName,
            shortDescription: formik.values.experienceShortDescription,
            pictureReference: '',
            videoFileName: '',
            experienceDuration,
            notes: formik.values.notes,
            accessLevel
          }

        }));

        // console.log("Mutation result ", experience);
        returnedId = experience.data.createExperience.id;

        const requestConfig = {
          headers: {
            'Authorization': token,
          }
        }

        const requestBody = {  // Video file to display
          s3Key: `E${returnedId}_MOV.mp4`,
        }

        const response = await axios.post(REACT_APP_SIGNED_URL_VIDEO, requestBody, requestConfig).then(res => {
          // console.log('Received Signed Cookies');
          // console.log('Response: ', res);
          setPreSignedUploadUrl(res.data.signedURL);
        }).catch(error => {
          // console.log("Axios error: ", error);
          toast.error(error.message, {
            // onClose: () => window.location.reload(true) // Reload the page to clear the video
          })
        });

        // Try to upload the experience thumbnail pic
        try {
          // console.log("ExperienceData", experiencePicData);
          const base64Response = await fetch(experiencePicData.base64);
          const blob = await base64Response.blob();
          const file = new File([blob], `E${returnedId}_Pic.jpg`);
          const experiencePic = await putFile(file, accessLevel ? "private" : "public");
          // console.log("Experience Pic result", experiencePic);
          // sessionStorage.removeItem(`R${returnedId}_Pic.jpg`);
          // const newPicURL = await getFileURL(`E${returnedId}_Pic.jpg`, accessLevel ? "private" : "public")
          // setFileURL(`R${returnedId}_Pic.jpg`, newPicURL); // Reset the cached version of the picture URL

        } catch (err) {
          // console.log("error", err);
        }

      } else {  // Update
        const experience = await API.graphql(graphqlOperation(mutations.updateExperience, {
          input: {
            id,
            experienceCreatedById: cognitoID,
            title: formik.values.experienceName,
            shortDescription: formik.values.experienceShortDescription,
            experienceDuration,
            notes: formik.values.notes,
            accessLevel
          }
        }));

        console.log("Mutation result ", experience);
        returnedId = experience.data.updateExperience.id;
        toast.success("Updated Experience", {
          onClose: () => {
            formik.resetForm(formik.initialValues);
            setIsModalOpen(false);
          }
        });
      }
      setCreate(true);
      setExperienceToEditId('');
    } catch (err) {
      // console.log("Error: ", err);
      toast.error(err.message);
    }
    setIsUploading(false);
  }

  // Brief: Get listing and info for all Experiences to which the current user has access
  const getExperiences = async () => {
    const arrayToDisplay = [];
    let result = [];

    try {
      const experiences = experienceType ? await API.graphql(graphqlOperation(queries.listExperiences, { filter: { experienceCreatedById: { eq: cognitoID } } }))
        : await API.graphql(graphqlOperation(queries.listExperiences, { filter: { accessLevel: { eq: false } } }));
      result = experiences.data.listExperiences.items;
    } catch (err) {
      // console.log("Error: ", err);
      result = err.data.listExperiences.items;
    }

    try {
      await Promise.all(result.map(async (item) => {
        arrayToDisplay.push({
          id: item.id,
          title: item.title,
          shortDescription: item.shortDescription,
          postedBy: `${item.createdBy.firstName} ${item.createdBy.lastName}`,
          createdAt: `${new Date(item.createdAt).toLocaleDateString("en-US", { year: 'numeric', month: 'short', day: 'numeric' })} ${new Date(item.createdAt).toLocaleTimeString("en-US", { hour: 'numeric', minute: 'numeric' })}`,
          updatedAt: `${new Date(item.updatedAt).toLocaleDateString("en-US", { year: 'numeric', month: 'short', day: 'numeric' })} ${new Date(item.updatedAt).toLocaleTimeString("en-US", { hour: 'numeric', minute: 'numeric' })}`,
          s3FileURL: await getFileURL(`E${item.id}_Pic.jpg`, item.accessLevel ? "private" : "public"),
          duration: item.experienceDuration,
        })
      }));
    } catch (err) {
      console.log("Error: ", err);
    }
    setDownloadedExperiences(arrayToDisplay);
  }

  const customStyles = {
    content: {
      top: '100px',
    },
  };

  const handleExperienceView = (type) => {
    setExperienceType(type);
    console.log("Updated experience type");
  }

  const handleVideoUrl = (file) => {
    // console.log("In the handleVideoUrl. Local fileUrl = ", file);
    if (!file) return;
    const fileUrl = URL.createObjectURL(file);
    setLocalVideoURL(fileUrl);
    setVideoFile(file);
  }

  const handleThumbnail = (thumbnail) => {
    // console.log("Thumbnail: ", thumbnail);
    // setCroppedURL(experiencePicURL);
    setExperiencePicData(thumbnail);
    setThumbnailCard(thumbnail.base64);
  }

  // Brief: Dynamically create the Experience room link based on the Experience ID
  const handleExperienceLink = () => {
    const requestConfig = {
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
      }
    }

    const hostName = window.location.hostname;
    const port = window.location.port ? `:${window.location.port}` : '';

    const requestBodyVideo = {  // Video file to display
      experience: `${experienceToEditId}`,
      vod: `${mp4VideoURL}`,
      user_domain: `${hostName}${port}`,
      protocol: window.location.protocol
    }

    // Get Signed Cookies for Video
    axios.post(REACT_APP_VIDEO_ROOM_URL, requestBodyVideo, requestConfig).then(response => {
      console.log('Received Experience Link');
      setExperienceLink(response.data.link)
    }).catch(error => {
      console.log("Signed Cookie Error: ", error);
    });

  }

  const getSignedCookie = (token, guid) => {
    const requestConfig = {
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
      }
    }

    const requestBodyVideo = {  // Video file to display
      s3Key: `${guid}`,
    }
    // Get Signed Cookies for Video
    axios.post(REACT_APP_SIGNED_URL, requestBodyVideo, requestConfig).then(response => {
      // console.log('Received Signed Cookies');
      // console.log('Response: ', response);
      setCookies(response.data.signedURL);
      setLocalVideoURL(response.data.signedURL);
      // setCookies([response.data["Set-Cookie1"], response.data["Set-Cookie2"], response.data["Set-Cookie3"]]);
    }).catch(error => {
      console.log("Signed Cookie Error: ", error);
    });

  }

  return (
    <FormikProvider value={formik}>
      {/* <Form autoComplete="off" noValidate onSubmit={handleSubmit}> */}

      <Modal
        className="modal-container"
        isOpen={isModalOpen}
        shouldCloseOnOverlayClick={false}
        onRequestClose={() => {
          // Reset key states
          setIsModalOpen(false);
          setLocalVideoURL('');
          setReadOnlyMode(false);
          setExperienceLink('');
          setStep(stepArray.DETAILS);
          setIsUploading(false);
          setThumbnail('');
          setThumbnailCard('');
        }}
        ariaHideApp={false} style={customStyles}	>
        <AppBar sx={{ position: 'relative', borderRadius: '10px' }}>
          <Toolbar className="modal-toolbar" >
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setIsModalOpen(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {!readOnlyMode && (create ? 'Create Experience' : 'Your Experience')}
            </Typography>
            {/* {
              !readOnlyMode &&
              <Button id="modify-experience-button" autoFocus color="inherit" onClick={() => setIsModalOpen(false)}>
                {create ? 'Submit' : 'Update'}
              </Button>
            } */}
          </Toolbar>
        </AppBar>

        <>
          <br /><br />
          {/* <Button
            startIcon={<CloseIcon />}
            onClick={() => setIsModalOpen(false)} >
            Close
          </Button> */}
          <Stack className="modal-content" direction={{ xs: 'column', sm: 'row' }} spacing="5%" >

            <div className="experience-summary"  >
              <div className='experience-summary params'>
                <Typography variant='h4' fontWeight={400} >{formik.values.experienceName ?? ''}</Typography>
                <Typography variant='h7' fontWeight={400} >{formik.values.experienceShortDescription ?? ''}</Typography>
              </div>

              {(!create || readOnlyMode) &&
                <div className='experience-summary params'>
                  <Typography variant='h7' fontWeight={400} >Duration: {selectedXpData.duration ?? ''} min</Typography>
                  <Typography variant='h7' fontWeight={400} >Posted By: {selectedXpData.postedBy ?? ''}</Typography>
                  <Typography variant='h7' fontWeight={400} >Last Update: {selectedXpData.updatedAt ?? ''} </Typography>
                </div>
              }

              <div className='experience-summary categories'>
                <Typography variant={step === stepArray.DETAILS ? 'h4' : 'h7'} fontWeight={400} onClick={() => setStep(stepArray.DETAILS)}>Details</Typography>
                {/* <br /> */}
                <Typography variant={step === stepArray.VIDEO ? 'h4' : 'h7'} fontWeight={400} onClick={() => setStep(stepArray.VIDEO)}>Video</Typography>
                <Typography variant={step === stepArray.RECIPE ? 'h4' : 'h7'} fontWeight={400} onClick={() => setStep(stepArray.RECIPE)}>Connected Recipe</Typography>
                {
                  !readOnlyMode && <Typography variant={step === stepArray.ACCESS ? 'h4' : 'h7'} fontWeight={400} onClick={() => setStep(stepArray.ACCESS)}>Access Level</Typography>
                }
              </div>
            </div>


            <Form autoComplete="off" noValidate onSubmit={handleSubmit} >

              <Paper
                className='paper-container'
                elevation={10}
              >
                {/* <Stack direction={{ xs: 'row', sm: 'column' }} spacing={2} width='100%'> */}

                {step === stepArray.DETAILS &&
                  <Stack direction={{ xs: 'column', sm: 'column' }} spacing={2} sx={{ padding: '0%', alignItems: "center" }}>
                    <div className='paper-container title'>
                      <Typography variant='h4' fontWeight={400} >Enter Experience Details</Typography>
                    </div>
                    <TextField
                      // fullWidth
                      className='details-text'
                      // style={{
                      //   maxWidth: '100%'
                      // }}
                      label="Enter Experience Name"
                      disabled={readOnlyMode}
                      {...getFieldProps('experienceName')}
                      error={Boolean(touched.experienceName && errors.experienceName)}
                      helperText={touched.experienceName && errors.experienceName}
                    />

                    <TextField
                      // fullWidth
                      className='details-text'
                      // style={{
                      //   maxWidth: '100%'
                      // }}
                      label="Enter Experience Short Description"
                      disabled={readOnlyMode}
                      multiline
                      rows='2'
                      {...getFieldProps('experienceShortDescription')}
                      error={Boolean(touched.experienceShortDescription && errors.experienceShortDescription)}
                      helperText={touched.experienceShortDescription && errors.experienceShortDescription}
                    />

                    <FormControl className='details-text'>
                      <InputLabel id="experience-time-select">Experience Duration</InputLabel>
                      <Select
                        labelId="experience-duration-select-label"
                        id="experience-duration-select"
                        value={experienceDuration}
                        label="Duration"
                        onChange={handleExperienceDuration}
                        disabled={readOnlyMode}
                      >
                        <MenuItem value={15}>15 min</MenuItem>
                        <MenuItem value={30}>30 min</MenuItem>
                        <MenuItem value={60}>60 min</MenuItem>
                      </Select>
                    </FormControl>

                    <TextField
                      // fullWidth
                      className='details-text'
                      // style={{
                      //   maxWidth: '100%'
                      // }}
                      disabled={readOnlyMode}
                      multiline
                      rows='5'
                      // fullHeight
                      autoComplete="off"
                      label="Enter Notes"
                      {...getFieldProps('notes')}
                      error={Boolean(touched.notes && errors.notes)}
                      helperText={touched.notes && errors.notes}
                      onSelect={(e) => { parseText('notesList', e.target.value, false) }}
                    />
                  </Stack>
                }
                {(step === stepArray.VIDEO || step === stepArrayReadOnly.VIDEO || step === stepArray.THUMBNAIL) &&
                  // <Stack direction={{ xs: 'column', sm: 'column' }} spacing={2} sx={{ padding: '5%' }} >
                  <>
                    <div className='paper-container title'>
                      <Typography variant='h4' fontWeight={400} >Video</Typography>
                    </div>
                    <div className='paper-container content' style={{ flexDirection: "row" }}>
                      {isUploading &&
                        <div>We are currently processing your video. Please check back in a few minutes.</div>
                      }
                      {!isUploading && ((localVideoURL === '') ?
                        <VideoSelect id='videoUpload' parentCallback={handleVideoUrl} />
                        : <ExperienceTemplate
                          videoURL={localVideoURL}
                          experienceName={formik.values.experienceName}
                          experienceShortDescription={formik.values.experienceShortDescription}
                          experienceNotes={formik.values.notes}
                          create={create}
                          selectThumbnail={step === stepArray.THUMBNAIL}
                          parentCallback={handleThumbnail} />)}
                      {!isUploading && thumbnailCard && create && (step === stepArray.THUMBNAIL) &&
                        <ExperienceCard
                          experience={{
                            title: formik.values.experienceName,
                            shortDescription: formik.values.experienceShortDescription,
                            updatedAt: '',
                            s3FileURL: thumbnailCard,
                          }}
                        />
                      }
                    </div>
                  </>

                }
                {step === stepArray.ACCESS &&
                  <Stack direction={{ xs: 'column', sm: 'column' }} spacing={1} sx={{ paddingTop: '10px', alignItems: "center" }} >
                    <div className='paper-container text' style={{ justifyContent: "center" }}>
                      <Typography variant='h4' fontWeight={400} >{accessLevel ? 'Current Status: Private' : 'Current Status: Public'}</Typography>
                    </div>
                    {accessLevel ? <LockIcon sx={{ fontSize: "400px" }} /> : <LockOpenIcon sx={{ fontSize: "400px" }} />}
                    < Button
                      sx={{ fontSize: '20px' }}
                      // startIcon={accessLevel ? <LockIcon /> : <LockOpenIcon />}
                      onClick={(e) => {
                        setAccessLevel(!accessLevel)
                        // console.log("accessLevel changed")
                      }}> {accessLevel ? "Click to make public" : "Click to make private"}
                    </Button>
                  </Stack>
                }
                {step === stepArray.RECIPE &&
                  // <div>Work in Progress</div>
                  /* <Stack  direction={{ xs: 'column', sm: 'column' }} spacing={2} >
                     <RecipeSubmitForm />
                   </Stack> */
                  <div id='recipe-panel' style={{ minWidth: '1000px', height: '500px' }}>
                    <RecipeSubmitForm sx={{ padding: '2.5%' }} />
                  </div>
                }
                {step === stepArray.SUBMIT &&
                  // <div>Work in Progress</div>
                  /* <Stack  direction={{ xs: 'column', sm: 'column' }} spacing={2} >
                     <RecipeSubmitForm />
                   </Stack> */
                  <div className='paper-container content' >
                    {/* <ExperienceCard experience={selectedXpData} /> */}
                    <ExperienceCard
                      experience={{
                        title: formik.values.experienceName,
                        shortDescription: formik.values.experienceShortDescription,
                        updatedAt: '',
                        s3FileURL: create ? thumbnailCard : selectedXpData.s3FileURL,
                      }}
                    />
                  </div>
                }

                {step === stepArray.LINK &&
                  <Stack minWidth='50%' direction={{ xs: 'column', sm: 'column' }} spacing={2} >
                    {isUploading ?
                      <div>We are currently processing your video. Please check back in a few minutes.</div> :
                      <>
                        <Button
                          startIcon={<AddLinkIcon />}
                          onClick={handleExperienceLink} >
                          Click to get Experience Link
                        </Button>
                        {experienceLink && <a style={{ textAlign: 'center' }} href={experienceLink}>Experience link. Invite up to 10 friends!</a>}
                      </>
                    }
                  </Stack>
                }
                <Stack direction={{ xs: 'column', sm: 'row', maxWidth: '100%' }} sx={{ justifyContent: "center" }} spacing="50%">
                  <Button
                    startIcon={<ArrowUpwardIcon />}
                    onClick={handlePreviousStep}
                    className='step-btn'
                    // sx={{ marginLeft: '10px', backgroundColor: "#3F51B5", color: "#FFFFFF" }}
                    size="large"
                    variant="outlined"
                    disabled={step === stepArray.DETAILS}
                  >
                    Previous
                  </Button>
                  <Button
                    startIcon={<ArrowDownwardIcon />}
                    onClick={handleNextStep}
                    className='step-btn'
                    // sx={{ marginRight: '10px', backgroundColor: "#3F51B5", color: "#FFFFFF" }}
                    size="large"
                    variant="outlined"
                    disabled={(step === stepArray.SUBMIT && create) || step === stepArray.LINK}
                  >
                    Next
                  </Button>
                </Stack>
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  disabled={!(step === stepArray.SUBMIT)}
                // onClick={() => submitExperience(create, experienceToEditId)}
                >
                  Save and Upload Experience
                </LoadingButton>
                {/* </Stack> */}
              </Paper>
              {/* <br /> */}
              {/* 
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                disabled={!(step === stepArray.SUBMIT)}
              // onClick={() => submitExperience(create, experienceToEditId)}
              >
                Save and Upload Experience
              </LoadingButton> */}
            </Form>
          </Stack>
        </>

        <ToastContainer
          position="bottom-center"
          autoClose={1000}
        />

        {/* </Box> */}
      </Modal >

      {
        !isModalOpen ?
          <>
            <Button
              variant="contained"
              // component={RouterLink}
              // to={createNewExperience}
              onClick={handleCreateNewExperience}
              startIcon={<Icon icon={plusFill} />}
              sx={{ minWidth: '250px', maxWidth: '25%' }}
            >
              Add Experience
            </Button>
            <br />
            <FormControl style={{ minWidth: '250px', maxWidth: '25%' }}>
              <InputLabel id="demo-simple-select-label"
              // style={{ marginLeft: '3%'}}
              >
                Public or Private
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={experienceType}
                label="Experiences To Display"
                onChange={(e) => handleExperienceView(e.target.value)}
              >
                <MenuItem value={false}>Public</MenuItem>
                <MenuItem value>My Experiences</MenuItem>
              </Select>
            </FormControl>
            <br />
            <Grid container spacing={3}>
              {downloadedExperiences.map((experience, index) => (
                <Grid key={experience.id} item xs={12} sm={6} md={3}  >
                  <ExperienceCard experience={experience} parentCallback={(e) => {
                    if (e) {
                      handleSelectedExperienceToEdit(experience.id, index);
                    }
                  }} />
                </Grid>
              ))}
            </Grid>
          </> : null
      }
      {/* {
        !isModalOpen ? <ExperienceTable
          USERLIST={downloadedExperiences}
          parentCallback={handleSelectedExperienceToEdit}
          createNewExperience={handleCreateNewExperience}
          publicOrOwnExperiences={handleExperienceView}
        /> : null
      } */}
    </FormikProvider >
  );
}

export default ExperienceSubmitForm;
