import PropTypes from 'prop-types';
import { Link as RouterLink, useNavigate } from 'react-router-dom';


// material
import { Box, Card, Link, Typography, Stack, Button, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import { format, startOfMinute } from 'date-fns';

import { setProductDetails } from '../../authentication/AuthService';
// utils
import { fCurrency } from '../../../utils/formatNumber';

//
import Label from '../../Label';
import ColorPreview from '../../ColorPreview';




// ----------------------------------------------------------------------

const ProductImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});



/* 

<ColorPreview colors={colors} />
          <Typography variant="subtitle1">
            <Typography
              component="span"
              variant="body1"
              sx={{
                color: 'text.disabled',
                textDecoration: 'line-through'
              }}
            >
              {priceSale && fCurrency(priceSale)}
            </Typography>
            <Button 
              to="#"
              size="small"
              color="inherit"
              >
                Sign up
             </Button> 

            &nbsp;
            {fCurrency(price)}
            
          </Typography>

          */


// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
  product: PropTypes.object,
  
};

// This is a test of our source code 
export default function ShopProductCard({ product }) {
  const { name, cover, avgRating, runLength, experienceDate, status, author, priceSale, description } = product;
  const navigate = useNavigate();
 

  const dateOfExperience = format(experienceDate, 'iii PPP');
  const timeOfExperience = format(experienceDate, 'p');
  

  
  function onSignup(product) {

    // console.log("We pushed the Signup button");
    // console.log(product.name);
    // console.log(product.id);
    setProductDetails(product);
    navigate("/dashboard/productdetails", { replace: false}); // Navigate to the Products Details Page
    // const myWindow = window.open("/productdetails"); 
    

    // const string = "This window name is:";
    // myWindow.document.write(string);
    // navigate("/dashboard/blog", { replace: true}); // On clicking go to product details

  }



  return (
    <Card>
      <Box sx={{ pt: '100%', position: 'relative' }}>
        {status && (
          <Label
            variant="filled"
            color={(status === 'sale' && 'error') || 'info'}
            sx={{
              zIndex: 9,
              top: 16,
              right: 16,
              position: 'absolute',
              textTransform: 'uppercase'
            }}
          >
            {status}
          </Label>
        )}
        <ProductImgStyle alt={name} src={cover} />
      </Box>

      <Stack spacing={2} sx={{ p: 3 }}>
        <Link to="#" color="inherit" underline="hover" component={RouterLink}>
          <Typography variant="subtitle1" noWrap>
            {name}
          </Typography>
        </Link>

        <Stack alignitems="left" justifyContent="flex-end">
          <Typography variant="subtitle2">
            <Typography
              component="span"
              variant="subtitle2"
              sx={{
                color: 'inherit',
               
              }}
            >
            Avg. Rating: {avgRating} &nbsp;
            </Typography>
            <Typography
              fontWeight="600"
              component="span"
              variant="subtitle2"
              sx={{
                color: 'inherit',
                fontWeight: 'bold',
               
              }}
            >
              &nbsp; Time: &nbsp;
              
            </Typography>

            <Typography
              component="span"
              variant="subtitle2"
              sx={{
                color: 'inherit',
               
              }}
            >
            {runLength} min.
            </Typography>


            </Typography>
            <Typography
              component="span"
              variant="subtitle2"
              sx={{
                color: 'inherit',
               
              }}
            >
              Date: {dateOfExperience} 
            </Typography>
            <Typography
              component="span"
              variant="subtitle2"
              sx={{
                color: 'inherit',
               
              }}
            >
              Time: {timeOfExperience} 
            </Typography>
            <Typography
              component="span"
              variant="subtitle2"
              sx={{
                color: 'inherit',
               
              }}
            >
              Host: {author} 
            </Typography>
            <Button 
            
              to="#"
              size="small"
              color="inherit"
              onClick={() => onSignup(product)}
              >
                More info
 
             </Button> 
           
          
        </Stack>
      </Stack>
    </Card>
  );
}
