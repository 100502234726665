import * as Yup from 'yup';
import { useState, useRef, useEffect, useCallback, createRef } from 'react';
// import { Link as useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import './style.css';
// import { Icon } from '@iconify/react';
// import eyeFill from '@iconify/icons-eva/eye-fill';
// import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Stack,
  TextField,
  Container,
  Grid,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
// imports for Snackbar UI
import * as React from 'react';
import Button from '@mui/material/Button';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import NoiseAwareIcon from '@mui/icons-material/NoiseAware';
import NoiseControlOffIcon from '@mui/icons-material/NoiseControlOff';
import InfoIcon from '@mui/icons-material/Info';
import FastForwardIcon from '@mui/icons-material/FastForward';
// import { SnackbarProvider, useSnackbar } from 'notistack';
import "./static/styles.css";
// import { styled } from '@mui/material/styles';
// import DefaultVideo from './DefaultVideo';
import ReactPlayer from 'react-player';
import { green, red } from '@mui/material/colors';
// import { Block, VideoFile } from '@mui/icons-material';
import Timer from './Timer';

const { connect, Logger, createLocalAudioTrack, createLocalVideoTrack, createLocalTracks } = require('twilio-video');
const { isMobile } = require('./browser');

const playerRef = createRef();  // Reference to the ReactPlayer JSX component


// Boolean states, defined outside React Component
let noiseStatusGlobal; // Noise Cancellation enabled? (default = true)
let micStatusGlobal; // Microphone unmuted? (default = true)
let cameraStatusGlobal; // Video camera on? (default = true)
let tempUserName; // Copy variable for username to extend its scope
// let updateRoomSID;  // Global reference for setRoomSID
// let updateMicStatus; // Global reference for micStatus
// let updateCameraStatus; // Global reference for cameraStatus



function StartVideoSession(props) {
  const { REACT_APP_VIDEO_TOKEN_URL, REACT_APP_WSS_URL, REACT_APP_SIGNED_URL } = process.env;

  const StartVideoSessionSchema = Yup.object().shape({
    // lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Username required')
    // email: Yup.string().email('Email must be a valid email address').required('Email is required')
    // identity: Yup.string().required('Identity is required'),
    // room: Yup.string().required('Room is required')
  });

  // State variables
  const [playVOD, setPlayVOD] = useState(false);  // Video player state (i.e. playing (true) or paused (false))
  const [signedURL, setSignedURL] = useState(); // URL to video
  const [receivedWSS, setReceivedWSS] = useState(false);  // State flag to determine when a play or pause message has been received
  const [userName, setUserName] = useState(); // Username from Formik form to populate the participant's name in the video chat
  const [uniqueUserName, setUniqueUserName] = useState(); // Uniquename for user. Used in the WSS Connection
  const [roomSID, setRoomSID] = useState(null); // Username from Formik form to populate the WSS Connection
  const [pingWSS, setPingWSS] = useState(false); // Timer-based ping to stay connected to the WSS
  const [timerEnabled, setTimerEnabled] = useState(false); // Timer state variable to start or stop the timer
  const [micStatus, setMicStatus] = useState(1);  // Microphone state. "1" = unmuted, "0" = muted.
  const [cameraStatus, setCameraStatus] = useState(1);  // Camera state. "1" = on, "0" = off.
  const [noiseStatus, setNoiseStatus] = useState(1); // Noise cancellation enabled. "1" = enabled, "0" = disabled

  const { displayName, uniqueName, token, videoChatRoom, videoFile } = props; // Grab the accessToken from the caller
  // const { name, uniqueName, token, videoChatRoom, videoFile } = props; // Grab the accessToken from the caller



  // WEB SOCKET TEST
  const socket = useRef(null);
  const [isConnected, setIsConnected] = useState(false);
  // const userName = "NewUser";

  const onSocketOpen = useCallback(() => {
    setIsConnected(true);
    console.log('WSS_AWS: Socket is Open!');
  }, []);

  const onSocketClose = useCallback(() => {
    // setMembers([]);
    console.log('WSS_AWS: Socket is Closed!');
    setIsConnected(false);
    // setChatRows([]);
  });

  const onSocketMessage = useCallback((dataStr) => {
    const data = JSON.parse(dataStr);
    console.log("WSS_AWS: dataStr", dataStr);

    if (data.publicMessage) {
      console.log('WSS_AWS: data.publicMessage', data.publicMessage);
      alert(data.publicMessage);  // Show the public message to everyone
    }
    else if (data.sendPlay) {
      if (data.sendPlay.search(`${tempUserName}:`) === -1) {  // The username is not present
        setReceivedWSS(true); // Set receivedWSS state to true
        playerRef.current.seekTo(data.currentTime); // Seek to the current time sent
        setPlayVOD(true); // Set the video player to play
      } // else, do nothing
    }
    else if (data.sendPause) {
      if (data.sendPause.search(`${tempUserName}:`) === -1) {  // The username is not present
        setReceivedWSS(true); // Set receivedWSS state to true
        setPlayVOD(false); // Set the video player to pause
      } // else, do nothing
    }
    else if (data.members) {
      console.log("WSS_AWS: Members: ", data.members);
    }
    else if (data.systemMessage) {
      console.log("WSS_AWS: System Message: ", data.systemMessage);
    }
  });

  // @brief: Connect to the websocket
  const onWSSConnect = useCallback(() => {
    console.log("WSS_AWS: Attempting to connect");
    if (socket.current?.readyState !== WebSocket.OPEN) {
      socket.current = new WebSocket(REACT_APP_WSS_URL);
      socket.current.addEventListener('open', onSocketOpen);
      socket.current.addEventListener('close', onSocketClose);
      socket.current.addEventListener('message', (event) => {
        onSocketMessage(event.data);
      });
      setTimerEnabled(true);  // Start the timer!
    }
  });

  const onSendPlayMessage = (() => {
    console.log("WSS_AWS: Sending/Receiving Play");
    if (!receivedWSS) { // If the player change did not come from WSS
      setPlayVOD(true); // Set playVOD to reflect current state of the player
      socket.current?.send(JSON.stringify({
        action: 'sendPlay',
        room: roomSID,
        currentTime: playerRef.current.getCurrentTime(),
      }));
    }
    else {
      setReceivedWSS(false);  // Reset the receivedWSS flag
    }
  });

  const onSendPauseMessage = (() => {
    console.log("WSS_AWS: Sending/Receiving Pause");
    if (!receivedWSS) { // If the player change did not come from WSS
      setPlayVOD(false); // Set playVOD to reflect current state of the player
      socket.current?.send(JSON.stringify({
        action: 'sendPause',
        room: roomSID
      }));
    }
    else {
      setReceivedWSS(false);  // Reset the receivedWSS flag
    }
  });

  const onSendPublicMessage = (() => {
    const message = prompt('Enter public message');
    const room = roomSID;
    // alert(playVOD ? "Video paused" : "Video playing");
    console.log("WSS_AWS: message:", message);
    socket.current?.send(JSON.stringify({
      action: 'sendPublic',
      message,
      room
    }));
  });

  const onWSSdisconnect = (() => {
    if (isConnected) {
      socket.current?.close();
    }
    window.location.reload(true); // Reload the page to clear the video

  });

  // Set or reset the pingWSS state
  const handleTimerCallback = (childData) => {
    setPingWSS(childData)
  };

  // MARK: useEffect calls

  // Execute after a change in username
  // useEffect(() => {
  //   if (isConnected) {
  //     console.log('WSS_AWS: Setting name!');
  //     const name = userName;
  //     const room = roomSID;
  //     socket.current?.send(JSON.stringify({ action: 'setName', name, room }));
  //   }
  //   console.log("WSS_AWS: Username is ", userName);
  //   tempUserName = userName; // To filter out any incoming messages from local user
  // }, [userName]);

  // Execute after a change in uniqueUserName
  useEffect(() => {
    if (isConnected) {
      console.log('WSS_AWS: Setting name!');
      const name = uniqueUserName;
      const room = roomSID;
      const displayName = userName;
      socket.current?.send(JSON.stringify({ action: 'setName', name, room, displayName }));
    }
    console.log("WSS_AWS: Username is ", uniqueUserName);
    tempUserName = uniqueUserName; // To filter out any incoming messages from local user
  }, [uniqueUserName]);

  // Connect reference to noiseStatus state
  useEffect(() => {
    console.log("UI: noiseStatus now: ", noiseStatus);
    // console.log("UI: Camera status change");
    /* Assign update to outside variable */
    noiseStatusGlobal = noiseStatus;
    /* Unassign when component unmounts */
    return () => noiseStatusGlobal = null
  }, [noiseStatus]);

  // Connect reference to micStatus state
  useEffect(() => {
    console.log("UI: micStatus now: ", micStatus);
    // console.log("UI: Camera status change");
    /* Assign update to outside variable */
    micStatusGlobal = micStatus;
    /* Unassign when component unmounts */
    return () => micStatusGlobal = null
  }, [micStatus]);

  // Connect reference to cameraStatus state
  useEffect(() => {
    console.log("UI: cameraStatus now: ", cameraStatus);
    // console.log("UI: Camera status change");
    /* Assign update to outside variable */
    cameraStatusGlobal = cameraStatus;
    /* Unassign when component unmounts */
    return () => cameraStatusGlobal = null
  }, [cameraStatus]);

  // Execute after connecting to the websocket
  useEffect(() => {
    console.log("WSS_AWS: Appending Room SID");
    if (roomSID) {
      // // Set the Username for websocket
      // let newUserName = roomSID;
      // newUserName += '-';
      // newUserName += formik.values.identity;
      // setUserName(name);  // Set this after roomSID is defined
      setUniqueUserName(uniqueName); // Set this after roomSID is defined
      // setUserName(formik.values.identity);  // Set this after roomSID is defined
    }
  }, [roomSID]);

  // Execute after reaching timer value
  useEffect(() => {
    console.log("WSS_AWS: In the ping setName");
    if (isConnected && pingWSS) { // If currently connected and pingWSS is true
      console.log('WSS_AWS: Setting name!');
      // const name = userName;
      const name = uniqueUserName;
      const room = roomSID;
      socket.current?.send(JSON.stringify({ action: 'sendPing', name, room }));
    }
    // else, do nothing
  }, [pingWSS]);

  const formik = useFormik({
    initialValues: {
      // lastName: ''
      identity: uniqueName,
      // identity: name,
      room: videoChatRoom
      // remember: true
    },
    validationSchema: StartVideoSessionSchema,
    onSubmit: async (values, { resetForm }) => {

      // console.log('We have submitted token request');

      // console.log(formik.values.password);

      // console.log("StartVideo token: ", token);
      const requestConfig = {
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json',
        }
      }

      const requestBodyVideo = {  // Video file to display
        s3Key: videoFile,
        // s3Key: "HannahPuppetShowDashTest.mp4"
      }

      const requestBody = {
        identity: uniqueName,
        // identity: name,
        room: videoChatRoom
        // identity: formik.values.identity,
        // room: formik.values.room
      }



      // document.getElementById("requestBody.username").innerHTML = myArray[0];

      console.log("Identity: ", requestBody.identity);
      console.log("room: ", requestBody.room);
      // console.log("Token: ", requestBody.token);

      // // Get Signed Cookies for Video
      // axios.post(REACT_APP_SIGNED_URL, requestBodyVideo, requestConfig).then(async response => {
      //   console.log('Received Signed Cookies');
      //   console.log('Response: ', response);
      //   setSignedURL(`${REACT_APP_VOD_URL}de642268-d87b-45cf-b3ef-8d166a74f6f9/hls/RollingClouds.m3u8`);
      //   toast.success("Loaded video file");
      // }).catch(error => {
      //   toast.error(error.message, {
      //     onClose: () => window.location.reload(true) // Reload the page to clear the video
      //   })
      // });

      // Get Signed URL for Video
      axios.post(REACT_APP_SIGNED_URL, requestBodyVideo, requestConfig).then(async response => {
        console.log('Received SignedURL');
        console.log('Response: ', response);
        setSignedURL(response.data.signedURL);
        toast.success("Loaded video file");
      }).catch(error => {
        toast.error(error.message, {
          onClose: () => window.location.reload(true) // Reload the page to clear the video
        })
      });


      // Get Twilio Token
      axios.post(REACT_APP_VIDEO_TOKEN_URL, requestBody, requestConfig).then(async response => {
        console.log('Received Token Succesfully!');
        console.log('Response: ', response);
        // token = response.data;
        // setOpenSuccess(true);  // Show the Snackbar
        toast.success("Received video token");
        // await StartVideoChat(requestBody.room, response.data);
        await startVideoChat(requestBody.room, response.data);
        resetForm();
        // document.getElementById("submitButton")
      }).catch(error => {
        toast.error(error.message, {
          onClose: () => window.location.reload(true) // Reload the page to clear the video
        })
      });
      // resetForm();
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handlePlayerInfo = () => {
    console.log('Current play time: ', playerRef.current.getCurrentTime());
  }

  const handlePlayerAdvance = () => {
    playerRef.current.seekTo(0.35, 'fraction');
    console.log('Current play time: ', playerRef.current.getCurrentTime());
  }

  const startVideoChat = async (roomName, token) => {
    // Comment the next two lines to disable verbose logging.
    const logger = Logger.getLogger('twilio-video');
    // logger.setLevel('debug');
    // console.log('Is Mobile? ', isMobile);

    // Create a LocalAudioTrack with Krisp noise cancellation enabled.
    const localAudioTrack = await createLocalAudioTrack({
      noiseCancellationOptions: {
        sdkAssetsPath: '/noise_cancellation_plugin/1.0.0/dist',
        vendor: 'krisp'
      }
    });

    const localVideoTrack = await createLocalVideoTrack({});

    let room;
    if (!localAudioTrack.noiseCancellation) {
      // If the Krisp audio plugin fails to load, then a warning message will be logged
      // in the browser console, and the "noiseCancellation" property will be set to null.
      // You can still use the LocalAudioTrack to join a Room. However, it will use the
      // browser's noise suppression instead of the Krisp noise cancellation. Make sure
      // the "sdkAssetsPath" provided in "noiseCancellationOptions" points to the correct
      // hosted path of the plugin assets. 
      alert("Noise cancellation could not be started");
      // Join to the Room with the given AccessToken and ConnectOptions.
      room = await connect(token,
        {
          name: roomName,
          audio: true,
          video: { height: 240, frameRate: 24, width: 320 },
          // tracks: [localVideoTrack, localAudioTrack],
          bandwidthProfile: {
            video: {
              mode: 'grid',
              maxSubscriptionBitrate: 2500000
            }
          },
          maxAudioBitrate: 16000, // For music remove this line
          // For multiparty rooms (participants>=3) uncomment the line below
          preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],
          networkQuality: { local: 1, remote: 1 }
        });
    } else {
      room = await connect(token,
        {
          name: roomName,
          audio: true,
          video: { height: 240, frameRate: 24, width: 320 },
          tracks: [localVideoTrack, localAudioTrack],
          bandwidthProfile: {
            video: {
              mode: 'grid',
              maxSubscriptionBitrate: 2500000
            }
          },
          maxAudioBitrate: 16000, // For music remove this line
          // For multiparty rooms (participants>=3) uncomment the line below
          preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],
          networkQuality: { local: 1, remote: 1 }
        });

      // Toggle Krisp noise cancellation on/off
      const setNoiseCancellation = document.getElementById('toggleNoiseCancellation');
      setNoiseCancellation.addEventListener('click', () => {
        const { noiseCancellation } = localAudioTrack;
        if (noiseCancellation) {
          console.log("UI: Noise State", noiseStatusGlobal);
          if (!noiseStatusGlobal) {
            // If enabled, then the LocalAudioTrack will use the Krisp noise
            // cancellation instead of the browser's noise suppression.
            console.log("Noise cancellation ON");
            noiseCancellation.enable();
            setNoiseStatus(1);
          } else {
            // If disabled, then the LocalAudioTrack will use the browser's
            // noise suppression instead of the Krisp noise cancellation.
            console.log("Noise cancellation OFF");
            noiseCancellation.disable();
            setNoiseStatus(0);
          }
        }
      });
    }

    // // Join to the Room with the given AccessToken and ConnectOptions.
    // const room = await connect(token,
    //   {
    //     name: roomName,
    //     audio: true,
    //     video: { height: 240, frameRate: 24, width: 320 },
    //     tracks: [localVideoTrack, localAudioTrack],
    //     bandwidthProfile: {
    //       video: {
    //         mode: 'grid',
    //         maxSubscriptionBitrate: 2500000
    //       }
    //     },
    //     maxAudioBitrate: 16000, // For music remove this line
    //     // For multiparty rooms (participants>=3) uncomment the line below
    //     // preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],
    //     networkQuality: { local: 1, remote: 1 }
    //   });

    // if (!localAudioTrack.noiseCancellation.isEnabled) {
    //   // Krisp noise cancellation is permanently disabled in Peer-to-Peer and Go Rooms.
    //   alert("Noise cancellation not compatible in this mode");
    // }

    setRoomSID(room.sid);  // Store the unique room SID name
    // updateRoomSID(room.sid); // Store the unique room SID name

    // Save the LocalVideoTrack.
    // const localVideoTrack = Array.from(room.localParticipant.videoTracks.values())[0].track;

    // // Toggle Krisp noise cancellation on/off
    // const setNoiseCancellation = document.getElementById('toggleNoiseCancellation');
    // setNoiseCancellation.addEventListener('click', () => {
    //   const { noiseCancellation } = localAudioTrack;
    //   if (noiseCancellation) {
    //     console.log("UI: Noise State", noiseStatusGlobal);
    //     if (!noiseStatusGlobal) {
    //       // If enabled, then the LocalAudioTrack will use the Krisp noise
    //       // cancellation instead of the browser's noise suppression.
    //       console.log("Noise cancellation ON");
    //       noiseCancellation.enable();
    //       setNoiseStatus(1);
    //     } else {
    //       // If disabled, then the LocalAudioTrack will use the browser's
    //       // noise suppression instead of the Krisp noise cancellation.
    //       console.log("Noise cancellation OFF");
    //       noiseCancellation.disable();
    //       setNoiseStatus(0);
    //     }
    //   }
    // });

    // Mute the microphone
    const muteAudio = document.getElementById('muteAudio');
    muteAudio.addEventListener('click', () => {
      // micMuted = !micMuted; // Toggle micorphone state

      console.log("UI: Mic State", micStatusGlobal);

      if (micStatusGlobal) { // Microphone unmuted, so mute it
        room.localParticipant.audioTracks.forEach(element => {
          element.track.disable();
          element.track.stop();
        });
        setMicStatus(0);
      } else {  // Microphone muted, so unmute it
        room.localParticipant.audioTracks.forEach(element => {
          element.track.enable();
          element.track.restart();
        });
        setMicStatus(1);
      }
      // setMicStatus(!micStatus) // Toggle microphone state
    });

    // Stop the video feed
    const stopVideo = document.getElementById('stopVideo');
    stopVideo.addEventListener('click', () => {
      console.log("UI: Camera State", cameraStatusGlobal);

      if (cameraStatusGlobal) {  // Video camera should be on, turn it off
        room.localParticipant.videoTracks.forEach(element => {
          element.track.disable();
          element.track.stop();
        });
        setCameraStatus(0);
      }
      else {  // Video camera should be on
        room.localParticipant.videoTracks.forEach(element => {
          element.track.enable();
          element.track.restart();
        });
        setCameraStatus(1);
      }
    });

    // Leave the chat room
    const leaveChat = document.getElementById('leaveChat');
    leaveChat.addEventListener('click', () => {
      const mediaTracks = [room.localParticipant.videoTracks, room.localParticipant.audioTracks];
      const tracks = [...mediaTracks, room.localParticipant.dataTracks];
      // Stop all the tracks
      mediaTracks.forEach(mediaTrack => {
        mediaTrack.forEach(element => {
          element.track.stop();
        })
      });
      // Unpublish all the tracks
      room.localParticipant.tracks.forEach(generalTrack => {
        generalTrack.unpublish();
      });
      // Detach all media tracks
      mediaTracks.forEach(mediaTrack => {
        mediaTrack.forEach(element => {
          element.track.detach();
        })
      });

      tidyUp(room); // Close out 
    });


    // Handle the LocalParticipant's media.
    participantConnected(room.localParticipant, room);

    // Subscribe to the media published by RemoteParticipants already in the Room.
    room.participants.forEach(participant => {
      participantConnected(participant, room);
    });

    // Subscribe to the media published by RemoteParticipants joining the Room later.
    room.on('participantConnected', participant => {
      participantConnected(participant, room);
    });

    // Handle a disconnected RemoteParticipant.
    room.on('participantDisconnected', participant => {
      participantDisconnected(participant, room);
    });

    // // Handle a stopped video feed RemoteParticipant.
    // room.on('stopped', participant => {
    //   participantDisconnected(participant, room);
    // });

    // Set the current active Participant.
    setCurrentActiveParticipant(room);

    // Update the active Participant when changed, only if the user has not
    // pinned any particular Participant as the active Participant.
    room.on('dominantSpeakerChanged', () => {
      if (!isActiveParticipantPinned) {
        setCurrentActiveParticipant(room);
      }
    });
    return new Promise((resolve, reject) => {
      // Leave the Room when the "beforeunload" event is fired.
      window.onbeforeunload = () => {
        room.disconnect();
      };

      room.once('disconnected', (room, error) => {
        // Clear the event handlers on document and window..
        window.onbeforeunload = null;
        if (isMobile) {
          window.onpagehide = null;
          document.onvisibilitychange = null;
        }

        // Stop the LocalVideoTrack.
        localVideoTrack.stop();

        // Handle the disconnected LocalParticipant.
        participantDisconnected(room.localParticipant, room);

        // Handle the disconnected RemoteParticipants.
        room.participants.forEach(participant => {
          participantDisconnected(participant, room);
        });

        // Clear the active Participant's video.
        //  $activeVideo.get(0).srcObject = null;

        // Clear the Room reference used for debugging from the JavaScript console.
        window.room = null;

        if (error) {
          // Reject the Promise with the TwilioError so that the Room selection
          // modal (plus the TwilioError message) can be displayed.
          reject(error);
        } else {
          // Resolve the Promise so that the Room selection modal can be
          // displayed.
          resolve();
        }
      });
    });
  }


  // const Item = styled(Paper)(({ theme }) => ({
  //   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  //   ...theme.typography.body2,
  //   padding: theme.spacing(1),
  //   textAlign: 'center',
  //   color: theme.palette.text.secondary,
  // }));

  const participants = document.getElementById("participants");

  // Whether the user has selected the active Participant by clicking on
  // one of the video thumbnails.
  let isActiveParticipantPinned = false;

  /**
   * Set the current active Participant in the Room.
   * @param room - the Room which contains the current active Participant
   */
  function setCurrentActiveParticipant(room) {
    const { dominantSpeaker, localParticipant } = room;
    setActiveParticipant(dominantSpeaker || localParticipant);
  }

  /**
   * Set the active Participant's video.
   * @param participant - the active Participant
   */
  function setActiveParticipant(participant) {
    // if (activeParticipant) {
    //   const $activeParticipant = $(`div#${activeParticipant.sid}`, $participants);
    //   $activeParticipant.removeClass('active');
    //   $activeParticipant.removeClass('pinned');

    //   // Detach any existing VideoTrack of the active Participant.
    //   const { track: activeTrack } = Array.from(activeParticipant.videoTracks.values())[0] || {};
    //   if (activeTrack) {
    //     activeTrack.detach($activeVideo.get(0));
    //     $activeVideo.css('opacity', '0');
    //   }
    // }

    // // Set the new active Participant.
    // activeParticipant = participant;
    // const { identity, sid } = participant;
    // const $participant = $(`div#${sid}`, $participants);

    // $participant.addClass('active');
    // if (isActiveParticipantPinned) {
    //   $participant.addClass('pinned');
    // }

    // // Attach the new active Participant's video.
    // const { track } = Array.from(participant.videoTracks.values())[0] || {};
    // if (track) {
    //   track.attach($activeVideo.get(0));
    //   $activeVideo.css('opacity', '');
    // }

    // // Set the new active Participant's identity
    // $activeParticipant.attr('data-identity', identity);
  }

  function participantConnected(participant) {
    // Create new <div> for participant and add it to the page
    const el = document.createElement("div");
    // Create a div for a name tag
    const elText = document.createElement("div");
    elText.setAttribute("class", "participant-identity")
    // elText.innerHTML = formik.values.identity;  // Show user name
    elText.innerHTML = participant.identity;  // Show user name
    // elText.style = "width=100%;height=1.5em";
    elText.style.fontWeight = 'bold';
    el.appendChild(elText);   // Add name to el div
    el.setAttribute("id", participant.identity);
    el.setAttribute("video", "autoplay");
    el.setAttribute("class", "participant-video")

    document.getElementById("participants").appendChild(el);
    // Find all the participant's existing tracks and publish them to our page
    participant.tracks.forEach((trackPublication) => {
      trackPublished(trackPublication, participant);
    });
    // Listen for the participant publishing new tracks
    participant.on("trackPublished", trackPublished);
  }


  function trackPublished(trackPublication, participant) {
    // Get the participant's <div> we created earlier
    const el = document.getElementById(participant.identity);

    // First create a function that adds the track to the page
    console.log("Participant: ", participant.identity);
    const trackSubscribed = (track) => {
      // track.attach() creates the media elements <video> and <audio> to
      // to display the track on the page.
      const video = track.attach();
      // video.style.borderStyle = 'solid';
      if (track.constructor.name === "RemoteVideoTrack" && !track.isEnabled) { // If remote user video track and not enabled
        video.style.backgroundColor = 'black';
        video.style.height = '240px';
        video.style.width = '320px';
        video.style.borderStyle = 'solid';
      }
      else if (track.constructor.name === "RemoteAudioTrack") { // If this is the remote user audio 
        const videoIndex = Array.from(el.childNodes).findIndex((element) => element.localName === 'video');  // Find the audio track index
        if (videoIndex) { // If it exists

          if (track.isEnabled) {
            el.childNodes[videoIndex].style.color = 'black';  // Make solid outline to match microphone status by default
          }
          else {
            el.childNodes[videoIndex].style.color = 'red';  // Make solid outline to match microphone status by default
          }
        }
      }
      else if (track.constructor.name === 'LocalAudioTrack') {  // These are the tracks for the local user
        video.style.color = micStatusGlobal ? 'black' : 'red';  // Make solid outline to match microphone status by default
      }
      // video.muted = micStatusGlobal; // Make audio track match the microphone initial status 
      el.appendChild(video);

      track.on('disabled', (track) => {
        if (track.kind === 'video') {
          console.log("UI: Disabled video");
          const videoIndex = Array.from(el.childNodes).findIndex((element) => element.localName === 'video');  // Find the video track index
          const blackEl = document.createElement("video");
          blackEl.style.backgroundColor = 'black';
          blackEl.style.height = '240px';
          blackEl.style.width = '320px';
          blackEl.style.borderStyle = 'solid';
          blackEl.style.color = el.childNodes[videoIndex].style.color;  // Retain the outline color
          el.replaceChild(blackEl, el.childNodes[videoIndex]); // Replace video track 
        }
        else if (track.kind === 'audio') {
          console.log("UI: Disabled audio");
          const videoIndex = Array.from(el.childNodes).findIndex((element) => element.localName === 'video');  // Find the video track index
          el.childNodes[videoIndex].style.borderStyle = 'solid';
          el.childNodes[videoIndex].style.color = 'red';
        }
      });

      track.on('enabled', (track) => {
        if (track.kind === 'video') {
          console.log("UI: Enabled video");
          const videoIndex = Array.from(el.childNodes).findIndex((element) => element.localName === 'video');  // Find the video track index
          const resumedVideo = track.attach();
          resumedVideo.style.borderStyle = 'solid';
          resumedVideo.style.color = el.childNodes[videoIndex].style.color;  // Retain the outline color    
          el.replaceChild(resumedVideo, el.childNodes[videoIndex])
        }
        else if (track.kind === 'audio') {
          console.log("UI: Enabled audio");
          const videoIndex = Array.from(el.childNodes).findIndex((element) => element.localName === 'video');  // Find the video track index
          el.childNodes[videoIndex].style.borderStyle = 'solid';
          el.childNodes[videoIndex].style.color = 'black';
        }
      });

    };

    // If the track is already subscribed, add it immediately to the page
    if (trackPublication.track) {
      trackSubscribed(trackPublication.track);

      trackPublication.track.on('disabled', (track) => {
        if (track.kind === 'video') {
          console.log("UI: Disabled video");
          const videoIndex = Array.from(el.childNodes).findIndex((element) => element.localName === 'video');  // Find the video track index
          const blackEl = document.createElement("video");
          blackEl.style.backgroundColor = 'black';
          blackEl.style.height = '240px';
          blackEl.style.width = '320px';
          blackEl.style.borderStyle = 'solid';
          blackEl.style.color = el.childNodes[videoIndex].style.color;  // Retain the outline color
          el.replaceChild(blackEl, el.childNodes[videoIndex]); // Replace video track 
        }
        else if (track.kind === 'audio') {
          console.log("UI: Disabled audio");
          const videoIndex = Array.from(el.childNodes).findIndex((element) => element.localName === 'video');  // Find the video track index
          el.childNodes[videoIndex].style.borderStyle = 'solid';
          el.childNodes[videoIndex].style.color = 'red';
        }
      });

      trackPublication.track.on('enabled', (track) => {
        if (track.kind === 'video') {
          console.log("UI: Enabled video");
          const videoIndex = Array.from(el.childNodes).findIndex((element) => element.localName === 'video');  // Find the video track index
          const resumedVideo = track.attach();
          resumedVideo.style.borderStyle = 'solid';
          resumedVideo.style.color = el.childNodes[videoIndex].style.color;  // Retain the outline color
        }
        else if (track.kind === 'audio') {
          console.log("UI: Enabled audio");
          const videoIndex = Array.from(el.childNodes).findIndex((element) => element.localName === 'video');  // Find the video track index
          el.childNodes[videoIndex].style.borderStyle = 'solid';
          el.childNodes[videoIndex].style.color = 'black';
        }
      });
    }

    // Otherwise listen for the track to be subscribed to, then add it to the
    // page

    trackPublication.on("subscribed", trackSubscribed);
    document.getElementById("videoChatContainer").style.display = "block";  // Show video controls
    document.getElementById("chatLogin").style.visibility = "hidden"; // Hide login
    document.getElementById("chatLogin").style.disabled = true; // Disable login
    document.getElementById("submitButton").style.loading = false; // Disable login
  }

  function participantDisconnected(participant) {
    participant.removeAllListeners();
    const el = document.getElementById(participant.identity);
    // document.getElementById("participants").removeChild(el);
    el.remove();
  }

  function trackUnpublished(trackPublication) {
    trackPublication.track.detach().forEach((mediaElement) => {
      mediaElement.remove();
    });
  }

  function tidyUp(room) {
    // Leave the room
    room.disconnect();
    room = null;

    document.getElementById("videoChatContainer").style.display = "none"; // Hide Chat Controls
    document.getElementById("chatLogin").style.visibility = "visible"; // Show login
    document.getElementById("chatLogin").style.disabled = false; // Enable login
  }

  return (
    <FormikProvider value={formik}>
      <Container id="videoChatContainer" sx={{ display: 'none' }}>
        <Grid container direction='column' /* alignContent='center' */ justifyContent='center' >
          <Grid item>
            <Button id="leaveChat" variant="outlined" onClick={onWSSdisconnect}>Leave Chat</Button>
            <Button
              id='toggleNoiseCancellation'
              variant='outlined'
              startIcon={noiseStatus ? <NoiseAwareIcon sx={{ color: green[500] }} /> : <NoiseControlOffIcon sx={{ color: red[500] }} />}
            />
            <Button
              id="muteAudio"
              variant="outlined"
              startIcon={micStatus ? <MicIcon sx={{ color: green[500] }} /> : <MicOffIcon sx={{ color: red[500] }} />}
            />
            <Button
              id="stopVideo"
              variant="outlined"
              startIcon={cameraStatus ? <VideocamIcon sx={{ color: green[500] }} /> : <VideocamOffIcon sx={{ color: red[500] }} />}
            />
            <Button
              variant='outlined'
              startIcon={<InfoIcon sx={{ color: green[500] }} />}
              onClick={handlePlayerInfo}
            />
            <Button
              variant='outlined'
              startIcon={<FastForwardIcon sx={{ color: green[500] }} />}
              onClick={handlePlayerAdvance}
            />
            <Timer id="chatTimer" timerEnabled={timerEnabled} startCount={0} parentCallback={handleTimerCallback} timerInterval={60 * 2} />
          </Grid>
          <Grid container item direction='row' spacing={2} >
            <Grid item style={{ justifyContent: 'left' }}>
              <div style={{ display: '-webkit-inline-box' }}  >
                <ReactPlayer className="videoPlayerCss" url={signedURL}
                  ref={playerRef}
                  playing={playVOD}
                  controls
                  onPlay={onSendPlayMessage}
                  onPause={onSendPauseMessage}
                />
                <Grid container item sx={{ gridTemplateColumns: 'repeat(3, 1fr)' }}
                  id="participants"
                />
              </div>
            </Grid>
          </Grid>
          {/* <Grid item>
            <Button id="leaveChat" variant="outlined" onClick={onWSSdisconnect}>Leave Chat</Button>
            <Button
              id="muteAudio"
              variant="outlined"
              startIcon={micStatus ? <MicIcon sx={{ color: green[500] }} /> : <MicOffIcon sx={{ color: red[500] }} />}
            />
            <Button
              id="stopVideo"
              variant="outlined"
              startIcon={cameraStatus ? <VideocamIcon sx={{ color: green[500] }} /> : <VideocamOffIcon sx={{ color: red[500] }} />}
            />
            <Timer id="chatTimer" timerEnabled={timerEnabled} startCount={0} parentCallback={handleTimerCallback} timerInterval={60 * 4} />
          </Grid> */}
        </Grid>
      </Container>
      <Form id="chatLogin" sx={{ disabled: false, visibility: 'visible' }} autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            // fullWidth
            autoComplete="identity"
            type="identity"
            label="Your Name"
            {...getFieldProps('identity')}
            error={Boolean(touched.identity && errors.identity)}
            helperText={touched.identity && errors.identity}
          />
          <TextField
            // fullWidth
            autoComplete="room"
            type="room"
            label="Enter Video Chat Room"
            {...getFieldProps('room')}
            error={Boolean(touched.room && errors.room)}
            helperText={touched.room && errors.room}
            disabled
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} />

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          id="submitButton"
          onClick={onWSSConnect}
        >
          Start Your Experience
        </LoadingButton>
      </Form>
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
      />
    </FormikProvider >
  );
}

export default StartVideoSession;



