import faker from 'faker';
// utils
import { mockImgCover } from '../utils/mockImages';

// ----------------------------------------------------------------------

const POST_TITLES = [
  'The Best Food Tips You Have Ever Heard',
  'The Secret to Southern Fried Chicken',
  'An Apple A Day Keeps More Than the Doctor Away',
  '✨Kale: Why it Can✨t be stopped',
  'Fresh Chef of Bel Air',
  'Six Secrets to Cutting Onions',
  'I cannot believe they pay me for this',
  'Simple, Great Looking Side Dishes for Your Main Meal | Video Tutorial',
  'Free Food -- Now That I Have Your Aattention, What Are You Doing With That Knife?',
  'Examining the Evolution of the Typical Brussel Sprout Dish',
  'Sasha in the Kitchen -- A Tutorial',
  'The American Dream retold through mid-century mac and cheese',
  'Illustration of a Perfect Pasta',
  'Cardio for Your Stomach -- a Guide to Great Chocolate',
  'How to create a foodie for life -- feeding your kids spaghetti squash ',
  'Organic without Effort ',
  'No Dairy, No Meat?? We Have Your Vegan Dishes ',
  'How I Made My Spouse Love Me -- a January Dish to Remember',
  'Inside the Mind of a Fake Chef ',
  'Cooking Review: Is This Dish Too Creative?',
  'A German Meal -- Why not?',
  'Grade A Milk -- 12 Uses You Never Thought of',
  'Here’s a reason to celebrate the New Year!',
  'How to Animate a Blind Date with Pasta and Red Wine '
];

const posts = [...Array(23)].map((_, index) => ({
  id: faker.datatype.uuid(),
  cover: mockImgCover(index + 1),
  title: POST_TITLES[index + 1],
  createdAt: faker.date.past(),
  view: faker.datatype.number(),
  comment: faker.datatype.number(),
  share: faker.datatype.number(),
  favorite: faker.datatype.number(),
  author: {
    name: faker.name.findName(),
    avatarUrl: `/static/mock-images/avatars/avatar_${index + 1}.jpg`
  }
}));

export default posts;
