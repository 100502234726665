import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Box, Grid, Container, Typography, AppBar, TextField, Toolbar } from '@mui/material';
import { Link } from "react-router-dom";



const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;



const footerStyle = {
  height: "auto",
  display: 'flex',
  backgroundColor: '#fff',

};


const RootStyle = styled('div')(({ theme }) => ({
  padding: 60,
  boxShadow: 'none',
  display: 'flex',
  width: "100vw",
  height: "auto",
  backgroundColor: "#212121",
  // backgroundColor: theme.palette.background.appbar,
  // marginBottom: 10,

}));



export default function DashboardFooter() {

  const theme = useTheme();

  const textColor = {
    color: theme.palette.primary.contrastText
  }
  const iconStyle = {
    width: 40,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: "#FFFFFF",
    // backgroundColor: theme.palette.warning.main,
    boxShadow: theme.customShadows.z12,
    marginTop: 2,
    marginRight: 1,
    marginLeft: 1,
    '&:hover': {
      bgcolor: '#000',
    },
  };

  return (

    <RootStyle>

      <Grid container spacing={5} sx={{ justifyContent: "space-around", alignItems: "center" }}  >

        <Grid item xs={6} sm={4} md={3}>
          <Grid
            container
            direction="column"
            spacing={2}
            sx={{ mr: 1, }}
          >
            <Grid item sx={{ display: 'flex' }}>
              <Grid item style={iconStyle}>
                <a href="https://www.instagram.com/foodrazzle/" >
                  <img src="/static/appFooterInstagram.png" alt="Instagram" />
                </a>
              </Grid>
              <Grid item style={iconStyle}>
                <a href="https://www.twitter.com/foodrazzle" >
                  <img src="/static/appFooterTwitter.png" alt="Twitter" />
                </a>
              </Grid>
              
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} sm={4} md={2} >
          <Typography color={textColor} component={Link} to="/">
            Food Razzle @ 2022
          </Typography>

        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Typography color={textColor} component={Link} to="/dashboard/tos" sx={{ mr: 3 }}>
            Terms
          </Typography>
          <Typography color={textColor} component={Link} to="/" sx={{ mr: 3 }}>
            Privacy
          </Typography>
          <Typography noWrap color={textColor} component={Link} to="/dashboard/membterms">
            Membership Terms
          </Typography>
        </Grid>
      </Grid>
    </RootStyle>
  );
}