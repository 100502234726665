import faker from 'faker';
import { sample } from 'lodash';
// utils
import { mockImgAvatar } from '../utils/mockImages';

// ----------------------------------------------------------------------

const recipes = [...Array(24)].map((_, index) => ({
  id: faker.datatype.uuid(),
  avatarUrl: mockImgAvatar(index + 1),
  name: faker.name.findName(),
  email: faker.internet.email(),
  company: faker.company.companyName(),
  isVerified: faker.datatype.boolean(),
  status: sample(['online', 'taking class', 'offline']),
  lastExperience: faker.commerce.productName()
}));

// const users = [...Array(24)].map((_, index) => ({
//   id: faker.datatype.uuid(),
//   avatarUrl: mockImgAvatar(index + 1),
//   name: faker.name.findName(),
//   email: faker.internet.email(),
//   company: faker.company.companyName(),
//   isVerified: faker.datatype.boolean(),
//   status: sample(['online', 'taking class', 'offline']),
//   lastExperience: faker.commerce.productName()
// }));

export default recipes;
