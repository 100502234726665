import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
// imports for Snackbar UI
import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import CloseIcon from '@mui/icons-material/Close';  // Installed @mui/icons-material package and pointed to it correctly
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import { SnackbarProvider, useSnackbar } from 'notistack';

// ----------------------------------------------------------------------

function NewPasswordForm() {
  const navigate = useNavigate();
  const newPWUrl = 'https://3cqvp6zerj.execute-api.us-east-1.amazonaws.com/prod/new-password';
  const { token } = useParams();
  console.log(token);
  const [showPassword, setShowPassword] = useState(false);
  const { REACT_APP_X_API_KEY } = process.env;

  const NewPasswordSchema = Yup.object().shape({
    // lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Username required')
    // email: Yup.string().email('Email must be a valid email address').required('Email is required')
    password: Yup.string().required('Password is required'),
    passwordMatch: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Required')
  });

  const formik = useFormik({
    initialValues: {
      // lastName: ''
      password: '',
      passwordMatch: ''
      // remember: true
    },
    validationSchema: NewPasswordSchema,
    onSubmit: () => {
      console.log('We have submitted the password reset and are now navigating to the dashboard');
      // const name = formik.values.firstName;
      // const username = formik.values.lastName;
      // const email = formik.values.email;
      // const password = formik.values.password;
      console.log(formik.values.password);

      const requestConfig = {
        headers: {
          'x-api-key': REACT_APP_X_API_KEY,
        }
      }

      // console.log("Token = ", token);

      // Parse the token to get the username and token ID
      // Format is "example@test.com&token"
      const myArray = String(token).split("&");

      const requestBody = {
        username: myArray[0],
        token: myArray[1],
        password: formik.values.password
      }

      // document.getElementById("requestBody.username").innerHTML = myArray[0];

      console.log("Username: ", requestBody.username);
      console.log("Password: ", requestBody.password);
      console.log("Token: ", requestBody.token);

      axios.post(newPWUrl, requestBody, requestConfig).then(response => {
        console.log('Password Reset Succesful');
        // setOpenSuccess(true);  // Show the Snackbar
        handleClickVariant("Password Reset Succesful!", "success", actionAPISuccess, handleCloseAPISuccess)();  // Show Snackbar, go to Login page on close
      }).catch(error => {
        if (error.response.status === 401 || error.response.status === 403) {
          console.log(error.response.data.message); // This is the error message from the Server
          if (error.response.data.message.includes("Username")) {  // Password error
            handleClickVariant("Invalid username, please try again.", "error", actionAPIError, handleCloseAPIError)();  // Show Snackbar, reload page on close
          }
          else {  // Username error
            handleClickVariant("Invalid token, please try again.", "error", actionAPIError, handleCloseAPIError)();  // Show Snackbar, reload page on close
          }
        } else {
          console.log('something else went wrong - maybe the backend server is down');
          handleClickVariant("Something went wrong, please try again.", "error", actionAPIError, handleCloseAPIError)();  // Show Snackbar, reload page on close
        }
        // setOpenError(true);  // Show the Snackbar
      });
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };


  // Code to enable SnackBars 
  const { enqueueSnackbar } = useSnackbar();

  // Define the Snackbar parameters
  const handleClickVariant = (message, variant, action, onClose) => () => {
    enqueueSnackbar(message, { variant, action, onClose });
  };

  const handleCloseAPISuccess = (event, reason) => {
    // Navigate to the Dashboard page
    navigate('/login', { replace: true });
  };

  const handleCloseAPIError = (event, reason) => {
    navigate('/resetpw', { replace: true });  // Load the reset password page
  };

  // Additional HTML actions to perform on SnackBar
  const actionAPISuccess = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseAPISuccess}
      >
        <RedoIcon fontSize="medium" />
      </IconButton>
    </>
  );

  const actionAPIError = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseAPIError}
      >
        <UndoIcon fontSize="medium" />
      </IconButton>
    </>
  );

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Re-enter Password"
            {...getFieldProps('passwordMatch')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.passwordMatch && errors.passwordMatch)}
            helperText={touched.passwordMatch && errors.passwordMatch}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          {/* <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          /> */}

          {/* <Link component={RouterLink} variant="subtitle2" to="#">
            Forgot password?
          </Link> */}
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Submit
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}

// Outward facing function. Main form is wrapped in this function
export default function IntegrationNotistack() {
  return (
    <SnackbarProvider
      maxSnack={1}
      autoHideDuration={3000}
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
      <NewPasswordForm />
    </SnackbarProvider>
  );
}
