import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
// material
import { Stack, TextField, Divider } from '@mui/material';
// import { Stack, TextField, IconButton, InputAdornment, Paper, Divider, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// imports for Snackbar UI
import * as React from 'react';
import { API, graphqlOperation, Auth } from 'aws-amplify';
// import { API, graphqlOperation, Auth, Storage } from 'aws-amplify';
import Button from '@mui/material/Button';
import RecipesParser from "recipes-parser";
import units from "recipes-parser/lib/nlp/en/units.json";
import globalUnit from "recipes-parser/lib/nlp/en/global_unit.json";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
// import 'rsuite/dist/rsuite.css';
// // import 'rsuite/dist/styles/rsuite-default.css';
// import { Animation } from 'rsuite';
// import { styled, useTheme } from '@mui/material/styles';


import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import { putFile, getFileURL, getUser } from '../AuthService';

// import { putFile, getFileURL, setFileURL } from '../AuthService';
import RecipeTable from './RecipeTable';

import raw from "./rules.pegjs";
// import * as path from "path";

import * as queries from '../../../graphql/queries'
// import * as customQueries from '../../../graphql/custom_queries';
import * as mutations from '../../../graphql/mutations';
// import * as subscriptions from '../../../graphql/subscriptions';
// import ImageUpload from './ImageUpload';
import ImageUpload from '../../../utils/ImageUpload';
import RecipeTemplate from './RecipeTemplate';
import RecipeCard from './RecipeCard';
// import { UserListToolbar } from '../../_dashboard/user';

// const { Fade } = Animation;

function RecipeSubmitForm() {

  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cognitoID, setCognitoID] = useState();
  const [picUrl, setPicUrl] = useState();
  const [recipeToEditId, setRecipeToEditId] = useState();
  const [create, setCreate] = useState(true);
  const [isEdit, setIsEdit] = useState(true);
  const [croppedURL, setCroppedURL] = useState();
  const [accessLevel, setAccessLevel] = useState(false);
  const [recipePicData, setRecipePicData] = useState();
  const [recipeType, setRecipeType] = useState(false);
  const [downloadedRecipes, setDownloadedRecipes] = useState([]);
  const [filterName, setFilterName] = useState('');

  let rules;
  let parser;

  useEffect(() => { // Check to see if user is already logged in
    Auth.currentSession()
      .then(data => {
        // console.log(data)
        setCognitoID(data.getAccessToken().decodePayload().username)
      })
      .catch(err => {
        console.log(err)
        navigate('/login?continue=recipesubmit', { replace: true });
        // navigate('/login', { replace: true });
      });
  }, []);

  useEffect(async () => { // Check to see if user is already logged in
    const user = getUser();
    console.log('user: ', user);

    if (!user) {
      navigate('/login?continue=recipesubmit', { replace: true });
    }

    setCognitoID(user.email);
    // const token = getToken();
    // setToken(token);

    // Auth.currentSession()
    //   .then(data => {
    //     // console.log(data)
    //     setCognitoID(data.getAccessToken().decodePayload().username)
    //     const token = getToken();
    //     // console.log("Token: ", token);
    //     setToken(token);
    //   })
    //   .catch(err => {
    //     console.log(err)
    //     navigate('/login?continue=experiencesubmit', { replace: true });
    //   });
  }, []);

  // On state changes
  useEffect(() => {
    getRecipes();
  }, [cognitoID]);

  useEffect(() => {
    console.log("Recipe type", recipeType);
    getRecipes();

  }, [recipeType]);

  useEffect(() => {
    if (!isModalOpen) { // On the modal closing, get all the recipes 
      getRecipes();
    }
  }, [isModalOpen]);

  useEffect(() => {
    console.log("picUrl changed");
  }, [picUrl]);

  // Brief: Set state variables and formik for a new recipe
  const handleCreateNewRecipe = () => {
    setCreate(true);
    resetFormik();
    setIsModalOpen(true);
  }

  // Brief: Handle table selection of recipe. Open recipe modal with appropriate text and Recipe Template filled
  const handleSelectedRecipeToEdit = async (id) => {
    let recipeData = [];

    // Get recipe info from GraphQL model
    try {
      const recipe = await API.graphql(graphqlOperation(queries.getRecipe, { id }));
      recipeData = recipe.data.getRecipe;
    } catch (err) {
      console.log("Error", err)
      recipeData = err.data.getRecipe;
    }

    // console.log(recipeData);
    formik.values.recipeName = recipeData.title;
    formik.values.recipeShortDescription = recipeData.shortDescription;
    formik.values.recipePrepTime = recipeData.prepTime ?? '0';
    formik.values.recipeCookTime = recipeData.cookTime ?? '0';
    formik.values.recipeReadyTime = recipeData.readyTime ?? '0';
    formik.values.recipeIngredients = parseRecipeJSON(JSON.parse(recipeData.ingredientsList));
    formik.values.directions = recipeData.directions;
    formik.values.equipment = recipeData.equipment;
    formik.values.notes = recipeData.notes ?? '';
    setAccessLevel(recipeData.accessLevel);
    setRecipeToEditId(id);
    if (cognitoID === recipeData.postedBy.id) { // If the current user is the owner of this recipe, don't create a new recipe
      setCreate(false);
    } else {
      setCreate(true);
    }

    console.log(create);
    if (sessionStorage.getItem(`R${id}_Pic.jpg`)) {
      console.log("Getting file from cache");
      const picURL = sessionStorage.getItem(`R${id}_Pic.jpg`);
      setCroppedURL(picURL);
      fetch(picURL)
        .then(res => res.blob())
        .then(imageBlob => setRecipePicData(imageBlob))
      // setRecipePicData(sessionStorage.getItem(`R${id}_Pic.jpg`))
    } else {
      console.log("Getting file from S3");
      const s3PicURL = await getFileURL(`R${id}_Pic.jpg`, recipeData.accessLevel ? "private" : "public");
      setCroppedURL(s3PicURL);
      fetch(s3PicURL)
        .then(res => res.blob())
        .then(imageBlob => setRecipePicData(imageBlob))
    }
    // console.log("Getting file from S3");
    // const s3PicURL = await getFileURL(`R${id}_Pic.jpg`, recipeData.accessLevel ? "private" : "public");
    // setCroppedURL(s3PicURL);
    // fetch(s3PicURL)
    //   .then(res => res.blob())
    //   .then(imageBlob => setRecipePicData(imageBlob))
    setIsModalOpen(true);
    // Pass to the recipe form

    parseText('ingredientsList', formik.values.recipeIngredients, true);
    parseText('directionsList', formik.values.directions, false);
    parseText('equipmentList', formik.values.equipment, false);
    parseText('notesList', formik.values.notes, false)
  }


  const RegisterSchema = Yup.object().shape({
    recipeName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Recipe name required'),
    recipeShortDescription: Yup.string().min(2, 'Too Short!').required('Short Description Required'),
    recipePrepTime: Yup.number().typeError('Must be a number'),
    recipeCookTime: Yup.number().typeError('Must be a number'),
    recipeReadyTime: Yup.number().typeError('Must be a number'),
  });

  const formik = useFormik({
    initialValues: {
      recipeName: '',
      recipeShortDescription: '',
      recipePrepTime: '',
      recipeCookTime: '',
      recipeReadyTime: '',
      recipeLongDescription: '',
      recipeIngredients: '',
      directions: '',
      equipment: '',
      notes: ''
    },

    validationSchema: RegisterSchema,
    onSubmit: async () => {
      const space = ' ';

    }
  });

  const resetFormik = () => {
    formik.values.recipeName = '';
    formik.values.recipeShortDescription = '';
    formik.values.recipeLongDescription = '';
    formik.values.recipePrepTime = '';
    formik.values.recipeCookTime = '';
    formik.values.recipeReadyTime = '';
    formik.values.recipeIngredients = '';
    formik.values.directions = '';
    formik.values.equipment = '';
    formik.values.notes = '';
    setCroppedURL('');
  }

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  const parseText = async (type, incomingText, ingredients) => {
    let testFieldSplit = '';
    let results = [];

    if (ingredients) {  // If it's an ingredients list
      await fetch(raw)
        .then(r => r.text())
        .then(text => {
          // console.log('text decoded:', text);
          rules = text;
          parser = new RecipesParser(rules, units, globalUnit);
          console.log("Parser: ", parser);
        });

      console.log("Original recipe: ", incomingText);

      let recipeString = incomingText;

      // Remove special characters other than letters, numbers, spaces, forward slash, parentheses, periods, and fraction unicodes
      recipeString = recipeString.replace(/[^a-zA-Z0-9' ''/'\u00BC-\u00BE\u2150-\u215E\u2189'('')''\n''.']/g, '');


      // const recipeStringArray = formik.values.recipeLongDescription.split("\n");
      const recipeStringArray = recipeString.split("\n");
      console.log("Recipe Ingredients: ", recipeStringArray);
      // const results = [];

      recipeStringArray.forEach((element) => {
        // console.log("Element: ", element);
        console.log("Parser: ", parser);
        results.push(parser.getIngredientsFromText([element.trim()], true));  // Trim leading and trailing whitespaces
      });

      const testField = parseTextFromRecipeJSON(results);
      testFieldSplit = testField.split("\n"); // Separate at each newline

    }
    else {
      results = incomingText;
      testFieldSplit = incomingText.split("\n");
      // results = testFieldSplit;
    }

    const el = document.getElementById(type);
    el.innerHTML = ''; // Empty the field

    testFieldSplit.forEach((text) => {
      const listEl = document.createElement("li");
      listEl.innerHTML = text;
      el.appendChild(listEl);
    })
    // formik.setFieldValue(type, testField);
    return results;

  };

  const parseTextFromRecipeJSON = (results) => {
    let testField = [];

    results.forEach((element) => {
      // Creating the text display entry
      if (element[0].result) {
        testField += element[0].result.amount
        // testField += "Ingredient: "
        // testField += '\n';
        // testField += "Quantity: "
        testField += ' ';
        if (!element[0].result.unit) {
          testField += '';
        }
        else {
          testField += element[0].result.unit;
        }
        testField += ' ';
        testField += element[0].result.ingredient;
        testField += '\n';
      }
      else {
        testField += element[0].unknown.instruction;
        testField += '\n';
      }
    })

    return testField.trim();
  }

  const parseRecipeJSON = (results) => {
    let testField = [];

    results.forEach((element) => {

      // Creating the text display entry
      if (element[0].result) {
        testField += element[0].result.instruction
        testField += '\n';
      }
      else {
        testField += element[0].unknown.instruction;
        testField += '\n';
      }
    })

    return testField.trim();
  }

  const submitRecipe = async (create, id) => {
    const ingredients = await parseText('ingredientsList', formik.values.recipeIngredients, true);
    const directions = await parseText('directionsList', formik.values.directions, false);
    const equipment = await parseText('equipmentList', formik.values.equipment, false);
    const notes = await parseText('notesList', formik.values.notes, false);
    console.log("Notes:", notes);
    console.log("Create? ", create);
    let returnedId = '';

    try {
      if (create) {
        const recipe = await API.graphql(graphqlOperation(mutations.createRecipe, {
          input: {
            recipePostedById: cognitoID,
            title: formik.values.recipeName,
            shortDescription: formik.values.recipeShortDescription,
            prepTime: formik.values.recipePrepTime,
            cookTime: formik.values.recipeCookTime,
            readyTime: formik.values.recipeReadyTime,
            directions,
            ingredientsList: JSON.stringify(ingredients),
            equipment,
            notes,
            accessLevel
          }
        }));

        console.log("Mutation result ", recipe);
        returnedId = recipe.data.createRecipe.id;
        toast.success("Created Recipe", {
          onClose: () => {
            formik.resetForm(formik.initialValues);
            setIsModalOpen(false);
          }
        });
      } else {  // Update
        const recipe = await API.graphql(graphqlOperation(mutations.updateRecipe, {
          input: {
            id,
            recipePostedById: cognitoID,
            title: formik.values.recipeName,
            shortDescription: formik.values.recipeShortDescription,
            prepTime: formik.values.recipePrepTime,
            cookTime: formik.values.recipeCookTime,
            readyTime: formik.values.recipeReadyTime,
            directions,
            ingredientsList: JSON.stringify(ingredients),
            equipment,
            notes,
            accessLevel
          }
        }));

        console.log("Mutation result ", recipe);
        returnedId = recipe.data.updateRecipe.id;
        toast.success("Updated Recipe", {
          onClose: () => {
            formik.resetForm(formik.initialValues);
            setIsModalOpen(false);
          }
        });
      }
      // Try to upload the recipe pic
      try {
        console.log("RecipeData", recipePicData);
        recipePicData.name = `R${returnedId}_Pic.jpg`
        const recipePic = await putFile(recipePicData, accessLevel ? "private" : "public");
        console.log("Recipe Pic result", recipePic);
        // sessionStorage.removeItem(`R${returnedId}_Pic.jpg`);
        const newPicURL = await getFileURL(`R${returnedId}_Pic.jpg`, accessLevel ? "private" : "public")
        // setFileURL(`R${returnedId}_Pic.jpg`, newPicURL); // Reset the cached version of the picture URL

      } catch (err) {
        console.log("error", err);
      }
      setCreate(true);
      setRecipeToEditId('');
    } catch (err) {
      console.log("Error: ", err);
      toast.error(err.message);
    }
  }

  const getRecipes = async () => {
    const arrayToDisplay = [];
    let result = [];
    // const s3FileURL = '';
    try {
      const recipes = recipeType ? await API.graphql(graphqlOperation(queries.listRecipes, { filter: { recipePostedById: { eq: cognitoID } } }))
        : await API.graphql(graphqlOperation(queries.listRecipes, { filter: { accessLevel: { eq: false } } }));
      result = recipes.data.listRecipes.items;
    } catch (err) {
      console.log("Error: ", err);
      result = err.data.listRecipes.items;
    }

    try {
      await Promise.all(result.map(async (item) => {
        arrayToDisplay.push({
          id: item.id,
          title: item.title,
          shortDescription: item.shortDescription,
          postedBy: `${item.postedBy.firstName} ${item.postedBy.lastName}`,
          createdAt: `${new Date(item.createdAt).toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' })} ${new Date(item.createdAt).toLocaleTimeString("en-US", { hour: 'numeric', minute: 'numeric' })}`,
          updatedAt: `${new Date(item.updatedAt).toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' })} ${new Date(item.updatedAt).toLocaleTimeString("en-US", { hour: 'numeric', minute: 'numeric' })}`,
          s3FileURL: sessionStorage.getItem(`R${item.id}_Pic.jpg`) ?? await getFileURL(`R${item.id}_Pic.jpg`, item.accessLevel ? "private" : "public")
        })
      }));

    } catch (err) {
      console.log("Error: ", err);
    }

    setDownloadedRecipes(arrayToDisplay);
  }

  const customStyles = {
    content: {
      top: '100px',
      // maxHeight: '50%'
      // left: 'auto',
      // right: 'auto',
      // bottom: 'auto',
      // marginRight: '-50%',
      // transform: 'translate(-50%, -50%)',
    },
  };

  const handleCroppedPic = (pic) => {
    // TODO: check for a pic stored in sessionStorage. If it's there, remove it.

    console.log(pic);
    const recipePicURL = URL.createObjectURL(pic);
    setCroppedURL(recipePicURL);
    setRecipePicData(pic);
    setIsEdit(true);
  }

  const handleRecipeView = (type) => {
    setRecipeType(type);
    console.log("Updated recipe type");
  }

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>

        <Modal isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)} ariaHideApp={false} style={customStyles}	>
          {!isEdit &&
            <>
              <ImageUpload inputImg={picUrl} getCroppedImg={handleCroppedPic} />
            </>
          }
          {isEdit &&
            <>
              <Button
                variant="contained"
                component="label"
                style={{ marginLeft: '92%' }}
              >
                Upload Recipe Pic
                <input
                  type="file"
                  accept="image/*"
                  hidden
                  onChange={(e) => {
                    setPicUrl("file", e.currentTarget.files[0]);
                    const reader = new FileReader();
                    reader.onload = function (e) {
                      setPicUrl(e.target.result);
                      setIsEdit(false);
                    };
                    reader.readAsDataURL(e.target.files[0]);
                    console.log(reader);
                  }}
                />
              </Button>
              <br /><br />
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} width='100%'>
                <Stack direction={{ xs: 'column', sm: 'column' }} spacing={2} minWidth='50%'>
                  <TextField
                    // fullWidth
                    style={{
                      maxWidth: '100%'
                    }}
                    label="Enter Recipe Name"
                    {...getFieldProps('recipeName')}
                    error={Boolean(touched.recipeName && errors.recipeName)}
                    helperText={touched.recipeName && errors.recipeName}
                  />

                  <TextField
                    // fullWidth
                    style={{
                      maxWidth: '100%'
                    }}
                    label="Enter Recipe Short Description"
                    multiline
                    rows='2'
                    {...getFieldProps('recipeShortDescription')}
                    error={Boolean(touched.recipeShortDescription && errors.recipeShortDescription)}
                    helperText={touched.recipeShortDescription && errors.recipeShortDescription}
                  />
                  <Stack direction='row' spacing={5} minWidth='100%' divider={<Divider orientation="vertical" flexItem />} justifyContent="center">
                    <TextField
                      // fullWidth
                      style={{
                        maxWidth: '33%'
                      }}
                      // type='number'
                      label="Enter Prep Time (min)"
                      // multiline
                      // rows='2'
                      {...getFieldProps('recipePrepTime')}
                      error={Boolean(touched.recipePrepTime && errors.recipePrepTime)}
                      helperText={touched.recipePrepTime && errors.recipePrepTime}
                    />
                    <TextField
                      // fullWidth
                      style={{
                        maxWidth: '33%'
                      }}
                      // type='number'
                      label="Enter Cook Time (min)"
                      // multiline
                      // rows='2'
                      {...getFieldProps('recipeCookTime')}
                      error={Boolean(touched.recipeCookTime && errors.recipeCookTime)}
                      helperText={touched.recipeCookTime && errors.recipeCookTime}
                    />
                    <TextField
                      // fullWidth
                      style={{
                        maxWidth: '33%'
                      }}
                      // type='number'
                      label="Enter Ready In Time (min)"
                      // multiline
                      // rows='2'
                      {...getFieldProps('recipeReadyTime')}
                      error={Boolean(touched.recipeReadyTime && errors.recipeReadyTime)}
                      helperText={touched.recipeReadyTime && errors.recipeReadyTime}
                    />
                  </Stack>

                  <TextField
                    // fullWidth
                    style={{
                      maxWidth: '100%'
                    }}
                    multiline
                    rows='5'
                    // fullHeight
                    autoComplete="off"
                    label="Enter Cooking Ingredients"
                    {...getFieldProps('recipeIngredients')}
                    error={Boolean(touched.recipeIngredients && errors.recipeIngredients)}
                    helperText={touched.recipeIngredients && errors.recipeIngredients}
                    onSelect={(e) => { parseText('ingredientsList', e.target.value, true) }}
                  />

                  <TextField
                    // fullWidth
                    style={{
                      maxWidth: '100%'
                    }}
                    multiline
                    rows='5'
                    // fullHeight
                    autoComplete="off"
                    label="Enter Cooking Steps"
                    {...getFieldProps('directions')}
                    error={Boolean(touched.directions && errors.directions)}
                    helperText={touched.directions && errors.directions}
                    onSelect={(e) => { parseText('directionsList', e.target.value, false) }}
                  // onSelect={(e) => { parseText('jsonDirections', e.target.value) }}
                  />

                  <TextField
                    // fullWidth
                    style={{
                      maxWidth: '100%'
                    }}
                    multiline
                    rows='5'
                    // fullHeight
                    autoComplete="off"
                    label="Enter Cooking Equipment"
                    {...getFieldProps('equipment')}
                    error={Boolean(touched.equipment && errors.equipment)}
                    helperText={touched.equipment && errors.equipment}
                    onSelect={(e) => { parseText('equipmentList', e.target.value, false) }}
                  />

                  <TextField
                    // fullWidth
                    style={{
                      maxWidth: '100%'
                    }}
                    multiline
                    rows='5'
                    // fullHeight
                    autoComplete="off"
                    label="Enter Notes"
                    {...getFieldProps('notes')}
                    error={Boolean(touched.notes && errors.notes)}
                    helperText={touched.notes && errors.notes}
                    onSelect={(e) => { parseText('notesList', e.target.value, false) }}
                  />
                  <Button
                    startIcon={accessLevel ? <LockIcon /> : <LockOpenIcon />}
                    onClick={(e) => {
                      setAccessLevel(!accessLevel)
                      console.log("accessLevel changed")
                    }}> {accessLevel ? "Click to make public" : "Click to make private"}
                  </Button>

                </Stack>
                <Stack minWidth='50%' >
                  <RecipeTemplate
                    croppedURL={croppedURL}
                    recipeName={formik.values.recipeName}
                    recipeShortDescription={formik.values.recipeShortDescription}
                    recipePrepTime={formik.values.recipePrepTime}
                    recipeCookTime={formik.values.recipeCookTime}
                    recipeReadyTime={formik.values.recipeReadyTime}
                  />
                </Stack>
              </Stack>
              <br />
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                onClick={() => submitRecipe(create, recipeToEditId)}
              >
                Save and Upload Recipe
              </LoadingButton>

            </>
          }

          <ToastContainer
            position="bottom-center"
            autoClose={1000}
          />

        </Modal>
        {!isModalOpen ?
          <>
            {/* Search bar for the Cards */}
            {/* <UserListToolbar
              numSelected={0}
              filterName={filterName}
              onFilterName={handleFilterByName}
            /> */}
            {/* <Grid container spacing={3} >
              {downloadedRecipes.filter(recipe => recipe.title.toLowerCase().includes(filterName.toLowerCase()))
                .map((recipe) => (
                  <Grid key={recipe.id} item xs={12} sm={6} md={3} onClick={() => handleSelectedRecipeToEdit(recipe.id)} flexDirection="row" flexWrap="wrap">
                    <RecipeCard recipe={recipe}  />
                  </Grid>
                ))}
            </Grid> */}
          </> : null}
        {!isModalOpen ? <RecipeTable
          USERLIST={downloadedRecipes}
          parentCallback={handleSelectedRecipeToEdit}
          createNewRecipe={handleCreateNewRecipe}
          publicOrOwnRecipes={handleRecipeView}
        /> : null}
      </Form>
    </FormikProvider >


  );
}

export default RecipeSubmitForm;
