/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      username
      firstName
      lastName
      emailVerified
      profilePic
      Friends {
        items {
          id
          createdAt
          updatedAt
          userFriendsId
          friendshipFriendId
        }
        nextToken
      }
      accessLevel
      subscriptionStartDate
      createdExperiences {
        items {
          id
          title
          pictureReference
          shortDescription
          videoFileName
          experienceDuration
          membershipLevelRequired
          price
          avgRating
          accessLevel
          notes
          createdAt
          updatedAt
          userCreatedExperiencesId
          userExperiencesWatchedId
          experienceCreatedById
          experienceHostedById
          experienceDefaultRecipeId
        }
        nextToken
      }
      upcomingVideoRooms {
        items {
          id
          roomTitle
          scheduledTime
          createdAt
          updatedAt
          userUpcomingVideoRoomsId
          videoRoomOrganizerId
          videoRoomVideoId
        }
        nextToken
      }
      experiencesWatched {
        items {
          id
          title
          pictureReference
          shortDescription
          videoFileName
          experienceDuration
          membershipLevelRequired
          price
          avgRating
          accessLevel
          notes
          createdAt
          updatedAt
          userCreatedExperiencesId
          userExperiencesWatchedId
          experienceCreatedById
          experienceHostedById
          experienceDefaultRecipeId
        }
        nextToken
      }
      dateTimeExperienceWatched
      createdAt
      updatedAt
      experienceUsersWhoHaveSignedUpId
      videoRoomAttendeesId
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      username
      firstName
      lastName
      emailVerified
      profilePic
      Friends {
        items {
          id
          createdAt
          updatedAt
          userFriendsId
          friendshipFriendId
        }
        nextToken
      }
      accessLevel
      subscriptionStartDate
      createdExperiences {
        items {
          id
          title
          pictureReference
          shortDescription
          videoFileName
          experienceDuration
          membershipLevelRequired
          price
          avgRating
          accessLevel
          notes
          createdAt
          updatedAt
          userCreatedExperiencesId
          userExperiencesWatchedId
          experienceCreatedById
          experienceHostedById
          experienceDefaultRecipeId
        }
        nextToken
      }
      upcomingVideoRooms {
        items {
          id
          roomTitle
          scheduledTime
          createdAt
          updatedAt
          userUpcomingVideoRoomsId
          videoRoomOrganizerId
          videoRoomVideoId
        }
        nextToken
      }
      experiencesWatched {
        items {
          id
          title
          pictureReference
          shortDescription
          videoFileName
          experienceDuration
          membershipLevelRequired
          price
          avgRating
          accessLevel
          notes
          createdAt
          updatedAt
          userCreatedExperiencesId
          userExperiencesWatchedId
          experienceCreatedById
          experienceHostedById
          experienceDefaultRecipeId
        }
        nextToken
      }
      dateTimeExperienceWatched
      createdAt
      updatedAt
      experienceUsersWhoHaveSignedUpId
      videoRoomAttendeesId
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      username
      firstName
      lastName
      emailVerified
      profilePic
      Friends {
        items {
          id
          createdAt
          updatedAt
          userFriendsId
          friendshipFriendId
        }
        nextToken
      }
      accessLevel
      subscriptionStartDate
      createdExperiences {
        items {
          id
          title
          pictureReference
          shortDescription
          videoFileName
          experienceDuration
          membershipLevelRequired
          price
          avgRating
          accessLevel
          notes
          createdAt
          updatedAt
          userCreatedExperiencesId
          userExperiencesWatchedId
          experienceCreatedById
          experienceHostedById
          experienceDefaultRecipeId
        }
        nextToken
      }
      upcomingVideoRooms {
        items {
          id
          roomTitle
          scheduledTime
          createdAt
          updatedAt
          userUpcomingVideoRoomsId
          videoRoomOrganizerId
          videoRoomVideoId
        }
        nextToken
      }
      experiencesWatched {
        items {
          id
          title
          pictureReference
          shortDescription
          videoFileName
          experienceDuration
          membershipLevelRequired
          price
          avgRating
          accessLevel
          notes
          createdAt
          updatedAt
          userCreatedExperiencesId
          userExperiencesWatchedId
          experienceCreatedById
          experienceHostedById
          experienceDefaultRecipeId
        }
        nextToken
      }
      dateTimeExperienceWatched
      createdAt
      updatedAt
      experienceUsersWhoHaveSignedUpId
      videoRoomAttendeesId
    }
  }
`;
export const createFriendship = /* GraphQL */ `
  mutation CreateFriendship(
    $input: CreateFriendshipInput!
    $condition: ModelFriendshipConditionInput
  ) {
    createFriendship(input: $input, condition: $condition) {
      id
      user {
        id
        username
        firstName
        lastName
        emailVerified
        profilePic
        Friends {
          nextToken
        }
        accessLevel
        subscriptionStartDate
        createdExperiences {
          nextToken
        }
        upcomingVideoRooms {
          nextToken
        }
        experiencesWatched {
          nextToken
        }
        dateTimeExperienceWatched
        createdAt
        updatedAt
        experienceUsersWhoHaveSignedUpId
        videoRoomAttendeesId
      }
      friend {
        id
        username
        firstName
        lastName
        emailVerified
        profilePic
        Friends {
          nextToken
        }
        accessLevel
        subscriptionStartDate
        createdExperiences {
          nextToken
        }
        upcomingVideoRooms {
          nextToken
        }
        experiencesWatched {
          nextToken
        }
        dateTimeExperienceWatched
        createdAt
        updatedAt
        experienceUsersWhoHaveSignedUpId
        videoRoomAttendeesId
      }
      createdAt
      updatedAt
      userFriendsId
      friendshipFriendId
    }
  }
`;
export const updateFriendship = /* GraphQL */ `
  mutation UpdateFriendship(
    $input: UpdateFriendshipInput!
    $condition: ModelFriendshipConditionInput
  ) {
    updateFriendship(input: $input, condition: $condition) {
      id
      user {
        id
        username
        firstName
        lastName
        emailVerified
        profilePic
        Friends {
          nextToken
        }
        accessLevel
        subscriptionStartDate
        createdExperiences {
          nextToken
        }
        upcomingVideoRooms {
          nextToken
        }
        experiencesWatched {
          nextToken
        }
        dateTimeExperienceWatched
        createdAt
        updatedAt
        experienceUsersWhoHaveSignedUpId
        videoRoomAttendeesId
      }
      friend {
        id
        username
        firstName
        lastName
        emailVerified
        profilePic
        Friends {
          nextToken
        }
        accessLevel
        subscriptionStartDate
        createdExperiences {
          nextToken
        }
        upcomingVideoRooms {
          nextToken
        }
        experiencesWatched {
          nextToken
        }
        dateTimeExperienceWatched
        createdAt
        updatedAt
        experienceUsersWhoHaveSignedUpId
        videoRoomAttendeesId
      }
      createdAt
      updatedAt
      userFriendsId
      friendshipFriendId
    }
  }
`;
export const deleteFriendship = /* GraphQL */ `
  mutation DeleteFriendship(
    $input: DeleteFriendshipInput!
    $condition: ModelFriendshipConditionInput
  ) {
    deleteFriendship(input: $input, condition: $condition) {
      id
      user {
        id
        username
        firstName
        lastName
        emailVerified
        profilePic
        Friends {
          nextToken
        }
        accessLevel
        subscriptionStartDate
        createdExperiences {
          nextToken
        }
        upcomingVideoRooms {
          nextToken
        }
        experiencesWatched {
          nextToken
        }
        dateTimeExperienceWatched
        createdAt
        updatedAt
        experienceUsersWhoHaveSignedUpId
        videoRoomAttendeesId
      }
      friend {
        id
        username
        firstName
        lastName
        emailVerified
        profilePic
        Friends {
          nextToken
        }
        accessLevel
        subscriptionStartDate
        createdExperiences {
          nextToken
        }
        upcomingVideoRooms {
          nextToken
        }
        experiencesWatched {
          nextToken
        }
        dateTimeExperienceWatched
        createdAt
        updatedAt
        experienceUsersWhoHaveSignedUpId
        videoRoomAttendeesId
      }
      createdAt
      updatedAt
      userFriendsId
      friendshipFriendId
    }
  }
`;
export const createExperience = /* GraphQL */ `
  mutation CreateExperience(
    $input: CreateExperienceInput!
    $condition: ModelExperienceConditionInput
  ) {
    createExperience(input: $input, condition: $condition) {
      id
      title
      createdBy {
        id
        username
        firstName
        lastName
        emailVerified
        profilePic
        Friends {
          nextToken
        }
        accessLevel
        subscriptionStartDate
        createdExperiences {
          nextToken
        }
        upcomingVideoRooms {
          nextToken
        }
        experiencesWatched {
          nextToken
        }
        dateTimeExperienceWatched
        createdAt
        updatedAt
        experienceUsersWhoHaveSignedUpId
        videoRoomAttendeesId
      }
      hostedBy {
        id
        username
        firstName
        lastName
        emailVerified
        profilePic
        Friends {
          nextToken
        }
        accessLevel
        subscriptionStartDate
        createdExperiences {
          nextToken
        }
        upcomingVideoRooms {
          nextToken
        }
        experiencesWatched {
          nextToken
        }
        dateTimeExperienceWatched
        createdAt
        updatedAt
        experienceUsersWhoHaveSignedUpId
        videoRoomAttendeesId
      }
      pictureReference
      shortDescription
      videoFileName
      experienceDuration
      usersWhoHaveSignedUp {
        items {
          id
          username
          firstName
          lastName
          emailVerified
          profilePic
          accessLevel
          subscriptionStartDate
          dateTimeExperienceWatched
          createdAt
          updatedAt
          experienceUsersWhoHaveSignedUpId
          videoRoomAttendeesId
        }
        nextToken
      }
      defaultRecipe {
        id
        title
        shortDescription
        prepTime
        cookTime
        readyTime
        ingredientsList
        directions
        equipment
        notes
        postedBy {
          id
          username
          firstName
          lastName
          emailVerified
          profilePic
          accessLevel
          subscriptionStartDate
          dateTimeExperienceWatched
          createdAt
          updatedAt
          experienceUsersWhoHaveSignedUpId
          videoRoomAttendeesId
        }
        recipeInspiredBy {
          id
          title
          pictureReference
          shortDescription
          videoFileName
          experienceDuration
          membershipLevelRequired
          price
          avgRating
          accessLevel
          notes
          createdAt
          updatedAt
          userCreatedExperiencesId
          userExperiencesWatchedId
          experienceCreatedById
          experienceHostedById
          experienceDefaultRecipeId
        }
        accessLevel
        createdAt
        updatedAt
        recipePostedById
        recipeRecipeInspiredById
      }
      membershipLevelRequired
      price
      avgRating
      accessLevel
      notes
      createdAt
      updatedAt
      userCreatedExperiencesId
      userExperiencesWatchedId
      experienceCreatedById
      experienceHostedById
      experienceDefaultRecipeId
    }
  }
`;
export const updateExperience = /* GraphQL */ `
  mutation UpdateExperience(
    $input: UpdateExperienceInput!
    $condition: ModelExperienceConditionInput
  ) {
    updateExperience(input: $input, condition: $condition) {
      id
      title
      createdBy {
        id
        username
        firstName
        lastName
        emailVerified
        profilePic
        Friends {
          nextToken
        }
        accessLevel
        subscriptionStartDate
        createdExperiences {
          nextToken
        }
        upcomingVideoRooms {
          nextToken
        }
        experiencesWatched {
          nextToken
        }
        dateTimeExperienceWatched
        createdAt
        updatedAt
        experienceUsersWhoHaveSignedUpId
        videoRoomAttendeesId
      }
      hostedBy {
        id
        username
        firstName
        lastName
        emailVerified
        profilePic
        Friends {
          nextToken
        }
        accessLevel
        subscriptionStartDate
        createdExperiences {
          nextToken
        }
        upcomingVideoRooms {
          nextToken
        }
        experiencesWatched {
          nextToken
        }
        dateTimeExperienceWatched
        createdAt
        updatedAt
        experienceUsersWhoHaveSignedUpId
        videoRoomAttendeesId
      }
      pictureReference
      shortDescription
      videoFileName
      experienceDuration
      usersWhoHaveSignedUp {
        items {
          id
          username
          firstName
          lastName
          emailVerified
          profilePic
          accessLevel
          subscriptionStartDate
          dateTimeExperienceWatched
          createdAt
          updatedAt
          experienceUsersWhoHaveSignedUpId
          videoRoomAttendeesId
        }
        nextToken
      }
      defaultRecipe {
        id
        title
        shortDescription
        prepTime
        cookTime
        readyTime
        ingredientsList
        directions
        equipment
        notes
        postedBy {
          id
          username
          firstName
          lastName
          emailVerified
          profilePic
          accessLevel
          subscriptionStartDate
          dateTimeExperienceWatched
          createdAt
          updatedAt
          experienceUsersWhoHaveSignedUpId
          videoRoomAttendeesId
        }
        recipeInspiredBy {
          id
          title
          pictureReference
          shortDescription
          videoFileName
          experienceDuration
          membershipLevelRequired
          price
          avgRating
          accessLevel
          notes
          createdAt
          updatedAt
          userCreatedExperiencesId
          userExperiencesWatchedId
          experienceCreatedById
          experienceHostedById
          experienceDefaultRecipeId
        }
        accessLevel
        createdAt
        updatedAt
        recipePostedById
        recipeRecipeInspiredById
      }
      membershipLevelRequired
      price
      avgRating
      accessLevel
      notes
      createdAt
      updatedAt
      userCreatedExperiencesId
      userExperiencesWatchedId
      experienceCreatedById
      experienceHostedById
      experienceDefaultRecipeId
    }
  }
`;
export const deleteExperience = /* GraphQL */ `
  mutation DeleteExperience(
    $input: DeleteExperienceInput!
    $condition: ModelExperienceConditionInput
  ) {
    deleteExperience(input: $input, condition: $condition) {
      id
      title
      createdBy {
        id
        username
        firstName
        lastName
        emailVerified
        profilePic
        Friends {
          nextToken
        }
        accessLevel
        subscriptionStartDate
        createdExperiences {
          nextToken
        }
        upcomingVideoRooms {
          nextToken
        }
        experiencesWatched {
          nextToken
        }
        dateTimeExperienceWatched
        createdAt
        updatedAt
        experienceUsersWhoHaveSignedUpId
        videoRoomAttendeesId
      }
      hostedBy {
        id
        username
        firstName
        lastName
        emailVerified
        profilePic
        Friends {
          nextToken
        }
        accessLevel
        subscriptionStartDate
        createdExperiences {
          nextToken
        }
        upcomingVideoRooms {
          nextToken
        }
        experiencesWatched {
          nextToken
        }
        dateTimeExperienceWatched
        createdAt
        updatedAt
        experienceUsersWhoHaveSignedUpId
        videoRoomAttendeesId
      }
      pictureReference
      shortDescription
      videoFileName
      experienceDuration
      usersWhoHaveSignedUp {
        items {
          id
          username
          firstName
          lastName
          emailVerified
          profilePic
          accessLevel
          subscriptionStartDate
          dateTimeExperienceWatched
          createdAt
          updatedAt
          experienceUsersWhoHaveSignedUpId
          videoRoomAttendeesId
        }
        nextToken
      }
      defaultRecipe {
        id
        title
        shortDescription
        prepTime
        cookTime
        readyTime
        ingredientsList
        directions
        equipment
        notes
        postedBy {
          id
          username
          firstName
          lastName
          emailVerified
          profilePic
          accessLevel
          subscriptionStartDate
          dateTimeExperienceWatched
          createdAt
          updatedAt
          experienceUsersWhoHaveSignedUpId
          videoRoomAttendeesId
        }
        recipeInspiredBy {
          id
          title
          pictureReference
          shortDescription
          videoFileName
          experienceDuration
          membershipLevelRequired
          price
          avgRating
          accessLevel
          notes
          createdAt
          updatedAt
          userCreatedExperiencesId
          userExperiencesWatchedId
          experienceCreatedById
          experienceHostedById
          experienceDefaultRecipeId
        }
        accessLevel
        createdAt
        updatedAt
        recipePostedById
        recipeRecipeInspiredById
      }
      membershipLevelRequired
      price
      avgRating
      accessLevel
      notes
      createdAt
      updatedAt
      userCreatedExperiencesId
      userExperiencesWatchedId
      experienceCreatedById
      experienceHostedById
      experienceDefaultRecipeId
    }
  }
`;
export const createRecipe = /* GraphQL */ `
  mutation CreateRecipe(
    $input: CreateRecipeInput!
    $condition: ModelRecipeConditionInput
  ) {
    createRecipe(input: $input, condition: $condition) {
      id
      title
      shortDescription
      prepTime
      cookTime
      readyTime
      ingredientsList
      directions
      equipment
      notes
      postedBy {
        id
        username
        firstName
        lastName
        emailVerified
        profilePic
        Friends {
          nextToken
        }
        accessLevel
        subscriptionStartDate
        createdExperiences {
          nextToken
        }
        upcomingVideoRooms {
          nextToken
        }
        experiencesWatched {
          nextToken
        }
        dateTimeExperienceWatched
        createdAt
        updatedAt
        experienceUsersWhoHaveSignedUpId
        videoRoomAttendeesId
      }
      recipeInspiredBy {
        id
        title
        createdBy {
          id
          username
          firstName
          lastName
          emailVerified
          profilePic
          accessLevel
          subscriptionStartDate
          dateTimeExperienceWatched
          createdAt
          updatedAt
          experienceUsersWhoHaveSignedUpId
          videoRoomAttendeesId
        }
        hostedBy {
          id
          username
          firstName
          lastName
          emailVerified
          profilePic
          accessLevel
          subscriptionStartDate
          dateTimeExperienceWatched
          createdAt
          updatedAt
          experienceUsersWhoHaveSignedUpId
          videoRoomAttendeesId
        }
        pictureReference
        shortDescription
        videoFileName
        experienceDuration
        usersWhoHaveSignedUp {
          nextToken
        }
        defaultRecipe {
          id
          title
          shortDescription
          prepTime
          cookTime
          readyTime
          ingredientsList
          directions
          equipment
          notes
          accessLevel
          createdAt
          updatedAt
          recipePostedById
          recipeRecipeInspiredById
        }
        membershipLevelRequired
        price
        avgRating
        accessLevel
        notes
        createdAt
        updatedAt
        userCreatedExperiencesId
        userExperiencesWatchedId
        experienceCreatedById
        experienceHostedById
        experienceDefaultRecipeId
      }
      accessLevel
      createdAt
      updatedAt
      recipePostedById
      recipeRecipeInspiredById
    }
  }
`;
export const updateRecipe = /* GraphQL */ `
  mutation UpdateRecipe(
    $input: UpdateRecipeInput!
    $condition: ModelRecipeConditionInput
  ) {
    updateRecipe(input: $input, condition: $condition) {
      id
      title
      shortDescription
      prepTime
      cookTime
      readyTime
      ingredientsList
      directions
      equipment
      notes
      postedBy {
        id
        username
        firstName
        lastName
        emailVerified
        profilePic
        Friends {
          nextToken
        }
        accessLevel
        subscriptionStartDate
        createdExperiences {
          nextToken
        }
        upcomingVideoRooms {
          nextToken
        }
        experiencesWatched {
          nextToken
        }
        dateTimeExperienceWatched
        createdAt
        updatedAt
        experienceUsersWhoHaveSignedUpId
        videoRoomAttendeesId
      }
      recipeInspiredBy {
        id
        title
        createdBy {
          id
          username
          firstName
          lastName
          emailVerified
          profilePic
          accessLevel
          subscriptionStartDate
          dateTimeExperienceWatched
          createdAt
          updatedAt
          experienceUsersWhoHaveSignedUpId
          videoRoomAttendeesId
        }
        hostedBy {
          id
          username
          firstName
          lastName
          emailVerified
          profilePic
          accessLevel
          subscriptionStartDate
          dateTimeExperienceWatched
          createdAt
          updatedAt
          experienceUsersWhoHaveSignedUpId
          videoRoomAttendeesId
        }
        pictureReference
        shortDescription
        videoFileName
        experienceDuration
        usersWhoHaveSignedUp {
          nextToken
        }
        defaultRecipe {
          id
          title
          shortDescription
          prepTime
          cookTime
          readyTime
          ingredientsList
          directions
          equipment
          notes
          accessLevel
          createdAt
          updatedAt
          recipePostedById
          recipeRecipeInspiredById
        }
        membershipLevelRequired
        price
        avgRating
        accessLevel
        notes
        createdAt
        updatedAt
        userCreatedExperiencesId
        userExperiencesWatchedId
        experienceCreatedById
        experienceHostedById
        experienceDefaultRecipeId
      }
      accessLevel
      createdAt
      updatedAt
      recipePostedById
      recipeRecipeInspiredById
    }
  }
`;
export const deleteRecipe = /* GraphQL */ `
  mutation DeleteRecipe(
    $input: DeleteRecipeInput!
    $condition: ModelRecipeConditionInput
  ) {
    deleteRecipe(input: $input, condition: $condition) {
      id
      title
      shortDescription
      prepTime
      cookTime
      readyTime
      ingredientsList
      directions
      equipment
      notes
      postedBy {
        id
        username
        firstName
        lastName
        emailVerified
        profilePic
        Friends {
          nextToken
        }
        accessLevel
        subscriptionStartDate
        createdExperiences {
          nextToken
        }
        upcomingVideoRooms {
          nextToken
        }
        experiencesWatched {
          nextToken
        }
        dateTimeExperienceWatched
        createdAt
        updatedAt
        experienceUsersWhoHaveSignedUpId
        videoRoomAttendeesId
      }
      recipeInspiredBy {
        id
        title
        createdBy {
          id
          username
          firstName
          lastName
          emailVerified
          profilePic
          accessLevel
          subscriptionStartDate
          dateTimeExperienceWatched
          createdAt
          updatedAt
          experienceUsersWhoHaveSignedUpId
          videoRoomAttendeesId
        }
        hostedBy {
          id
          username
          firstName
          lastName
          emailVerified
          profilePic
          accessLevel
          subscriptionStartDate
          dateTimeExperienceWatched
          createdAt
          updatedAt
          experienceUsersWhoHaveSignedUpId
          videoRoomAttendeesId
        }
        pictureReference
        shortDescription
        videoFileName
        experienceDuration
        usersWhoHaveSignedUp {
          nextToken
        }
        defaultRecipe {
          id
          title
          shortDescription
          prepTime
          cookTime
          readyTime
          ingredientsList
          directions
          equipment
          notes
          accessLevel
          createdAt
          updatedAt
          recipePostedById
          recipeRecipeInspiredById
        }
        membershipLevelRequired
        price
        avgRating
        accessLevel
        notes
        createdAt
        updatedAt
        userCreatedExperiencesId
        userExperiencesWatchedId
        experienceCreatedById
        experienceHostedById
        experienceDefaultRecipeId
      }
      accessLevel
      createdAt
      updatedAt
      recipePostedById
      recipeRecipeInspiredById
    }
  }
`;
export const createVodInfo = /* GraphQL */ `
  mutation CreateVodInfo(
    $input: CreateVodInfoInput!
    $condition: ModelVodInfoConditionInput
  ) {
    createVodInfo(input: $input, condition: $condition) {
      id
      guid
      srcBucket
      startTime
      srcVideo
      hlsUrl
      mp4Urls
      createdAt
      updatedAt
      postedBy
    }
  }
`;
export const updateVodInfo = /* GraphQL */ `
  mutation UpdateVodInfo(
    $input: UpdateVodInfoInput!
    $condition: ModelVodInfoConditionInput
  ) {
    updateVodInfo(input: $input, condition: $condition) {
      id
      guid
      srcBucket
      startTime
      srcVideo
      hlsUrl
      mp4Urls
      createdAt
      updatedAt
      postedBy
    }
  }
`;
export const deleteVodInfo = /* GraphQL */ `
  mutation DeleteVodInfo(
    $input: DeleteVodInfoInput!
    $condition: ModelVodInfoConditionInput
  ) {
    deleteVodInfo(input: $input, condition: $condition) {
      id
      guid
      srcBucket
      startTime
      srcVideo
      hlsUrl
      mp4Urls
      createdAt
      updatedAt
      postedBy
    }
  }
`;
export const createVideoRoom = /* GraphQL */ `
  mutation CreateVideoRoom(
    $input: CreateVideoRoomInput!
    $condition: ModelVideoRoomConditionInput
  ) {
    createVideoRoom(input: $input, condition: $condition) {
      id
      roomTitle
      organizer {
        id
        username
        firstName
        lastName
        emailVerified
        profilePic
        Friends {
          nextToken
        }
        accessLevel
        subscriptionStartDate
        createdExperiences {
          nextToken
        }
        upcomingVideoRooms {
          nextToken
        }
        experiencesWatched {
          nextToken
        }
        dateTimeExperienceWatched
        createdAt
        updatedAt
        experienceUsersWhoHaveSignedUpId
        videoRoomAttendeesId
      }
      attendees {
        items {
          id
          username
          firstName
          lastName
          emailVerified
          profilePic
          accessLevel
          subscriptionStartDate
          dateTimeExperienceWatched
          createdAt
          updatedAt
          experienceUsersWhoHaveSignedUpId
          videoRoomAttendeesId
        }
        nextToken
      }
      video {
        id
        title
        createdBy {
          id
          username
          firstName
          lastName
          emailVerified
          profilePic
          accessLevel
          subscriptionStartDate
          dateTimeExperienceWatched
          createdAt
          updatedAt
          experienceUsersWhoHaveSignedUpId
          videoRoomAttendeesId
        }
        hostedBy {
          id
          username
          firstName
          lastName
          emailVerified
          profilePic
          accessLevel
          subscriptionStartDate
          dateTimeExperienceWatched
          createdAt
          updatedAt
          experienceUsersWhoHaveSignedUpId
          videoRoomAttendeesId
        }
        pictureReference
        shortDescription
        videoFileName
        experienceDuration
        usersWhoHaveSignedUp {
          nextToken
        }
        defaultRecipe {
          id
          title
          shortDescription
          prepTime
          cookTime
          readyTime
          ingredientsList
          directions
          equipment
          notes
          accessLevel
          createdAt
          updatedAt
          recipePostedById
          recipeRecipeInspiredById
        }
        membershipLevelRequired
        price
        avgRating
        accessLevel
        notes
        createdAt
        updatedAt
        userCreatedExperiencesId
        userExperiencesWatchedId
        experienceCreatedById
        experienceHostedById
        experienceDefaultRecipeId
      }
      scheduledTime
      createdAt
      updatedAt
      userUpcomingVideoRoomsId
      videoRoomOrganizerId
      videoRoomVideoId
    }
  }
`;
export const updateVideoRoom = /* GraphQL */ `
  mutation UpdateVideoRoom(
    $input: UpdateVideoRoomInput!
    $condition: ModelVideoRoomConditionInput
  ) {
    updateVideoRoom(input: $input, condition: $condition) {
      id
      roomTitle
      organizer {
        id
        username
        firstName
        lastName
        emailVerified
        profilePic
        Friends {
          nextToken
        }
        accessLevel
        subscriptionStartDate
        createdExperiences {
          nextToken
        }
        upcomingVideoRooms {
          nextToken
        }
        experiencesWatched {
          nextToken
        }
        dateTimeExperienceWatched
        createdAt
        updatedAt
        experienceUsersWhoHaveSignedUpId
        videoRoomAttendeesId
      }
      attendees {
        items {
          id
          username
          firstName
          lastName
          emailVerified
          profilePic
          accessLevel
          subscriptionStartDate
          dateTimeExperienceWatched
          createdAt
          updatedAt
          experienceUsersWhoHaveSignedUpId
          videoRoomAttendeesId
        }
        nextToken
      }
      video {
        id
        title
        createdBy {
          id
          username
          firstName
          lastName
          emailVerified
          profilePic
          accessLevel
          subscriptionStartDate
          dateTimeExperienceWatched
          createdAt
          updatedAt
          experienceUsersWhoHaveSignedUpId
          videoRoomAttendeesId
        }
        hostedBy {
          id
          username
          firstName
          lastName
          emailVerified
          profilePic
          accessLevel
          subscriptionStartDate
          dateTimeExperienceWatched
          createdAt
          updatedAt
          experienceUsersWhoHaveSignedUpId
          videoRoomAttendeesId
        }
        pictureReference
        shortDescription
        videoFileName
        experienceDuration
        usersWhoHaveSignedUp {
          nextToken
        }
        defaultRecipe {
          id
          title
          shortDescription
          prepTime
          cookTime
          readyTime
          ingredientsList
          directions
          equipment
          notes
          accessLevel
          createdAt
          updatedAt
          recipePostedById
          recipeRecipeInspiredById
        }
        membershipLevelRequired
        price
        avgRating
        accessLevel
        notes
        createdAt
        updatedAt
        userCreatedExperiencesId
        userExperiencesWatchedId
        experienceCreatedById
        experienceHostedById
        experienceDefaultRecipeId
      }
      scheduledTime
      createdAt
      updatedAt
      userUpcomingVideoRoomsId
      videoRoomOrganizerId
      videoRoomVideoId
    }
  }
`;
export const deleteVideoRoom = /* GraphQL */ `
  mutation DeleteVideoRoom(
    $input: DeleteVideoRoomInput!
    $condition: ModelVideoRoomConditionInput
  ) {
    deleteVideoRoom(input: $input, condition: $condition) {
      id
      roomTitle
      organizer {
        id
        username
        firstName
        lastName
        emailVerified
        profilePic
        Friends {
          nextToken
        }
        accessLevel
        subscriptionStartDate
        createdExperiences {
          nextToken
        }
        upcomingVideoRooms {
          nextToken
        }
        experiencesWatched {
          nextToken
        }
        dateTimeExperienceWatched
        createdAt
        updatedAt
        experienceUsersWhoHaveSignedUpId
        videoRoomAttendeesId
      }
      attendees {
        items {
          id
          username
          firstName
          lastName
          emailVerified
          profilePic
          accessLevel
          subscriptionStartDate
          dateTimeExperienceWatched
          createdAt
          updatedAt
          experienceUsersWhoHaveSignedUpId
          videoRoomAttendeesId
        }
        nextToken
      }
      video {
        id
        title
        createdBy {
          id
          username
          firstName
          lastName
          emailVerified
          profilePic
          accessLevel
          subscriptionStartDate
          dateTimeExperienceWatched
          createdAt
          updatedAt
          experienceUsersWhoHaveSignedUpId
          videoRoomAttendeesId
        }
        hostedBy {
          id
          username
          firstName
          lastName
          emailVerified
          profilePic
          accessLevel
          subscriptionStartDate
          dateTimeExperienceWatched
          createdAt
          updatedAt
          experienceUsersWhoHaveSignedUpId
          videoRoomAttendeesId
        }
        pictureReference
        shortDescription
        videoFileName
        experienceDuration
        usersWhoHaveSignedUp {
          nextToken
        }
        defaultRecipe {
          id
          title
          shortDescription
          prepTime
          cookTime
          readyTime
          ingredientsList
          directions
          equipment
          notes
          accessLevel
          createdAt
          updatedAt
          recipePostedById
          recipeRecipeInspiredById
        }
        membershipLevelRequired
        price
        avgRating
        accessLevel
        notes
        createdAt
        updatedAt
        userCreatedExperiencesId
        userExperiencesWatchedId
        experienceCreatedById
        experienceHostedById
        experienceDefaultRecipeId
      }
      scheduledTime
      createdAt
      updatedAt
      userUpcomingVideoRoomsId
      videoRoomOrganizerId
      videoRoomVideoId
    }
  }
`;
