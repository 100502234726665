// material
import { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
import { Grid, Typography, Button } from '@mui/material';
import { Auth } from 'aws-amplify';


// utils
// import ReactPlayer from 'react-player';
import { styled } from '@mui/material/styles';
import { getToken } from '../../authentication/AuthService';
// import { getAccount } from '../../authentication/AuthService';

// ----------------------------------------------------------------------

export default function Hero() {

  // const setplaying = true; // auto plays the video from the page load
  // const navigate = useNavigate();

  const [cognitoID, setCognitoID] = useState();
  const [token, setToken] = useState('');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const backgroundImage = window.innerWidth >= 720 ? `url(/static/homepage_assets/ChefHands.png)` : `url(/static/homepage_assets/ChefHands_reduced.png)`;

  useEffect(() => {
    // Check for authenticated session
    Auth.currentSession()
    .then(data => {
      // console.log(data)
      setCognitoID(data.getAccessToken().decodePayload().username)
      const token = getToken();
      // console.log("Token: ", token);
      setToken(token);
    })
    .catch(err => {
      console.log(err)
      // navigate('/login?continue=experiencesubmit', { replace: true });
    });

    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    }
  }, []);

  // Change text and URL depending on whether someone is logged in

  // const account = getAccount();
  // const buttonText = ((account.displayName) === 'Guest') ? 'Join the Waitlist!' : 'See Experiences!';  // update text
  // const buttonURL = ((account.displayName) === 'Guest') ? '/register' : '/dashboard/products';  // update URL



  const TextStyle = styled('div')(({ theme }) => ({
    width: '100%',
    height: '60em',
    position: 'relative',
    top: '15em',
    left: '5%',
    zindex: 2,
    color: theme.palette.primary.contrastText,
    backgroundColor: 'transparent'
  }));

  const myStyle = {
    backgroundImage,
    // backgroundImage: {md: `url(/static/homepage_assets/ChefHands_reduced.png)`, lg: `url(/static/homepage_assets/ChefHands.png)`},
    height: 'auto',
    width: '105vw',
    // minWidth: '2000px',
    marginTop: '-70px',
    // top: {xs: '-5px', sm: '0px'},
    // fontSize: '50px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth'
      /* you can also use 'auto' behaviour 
         in place of 'smooth' */
    });
  };

  return (
    <div style={myStyle} >
      <Grid item xs={12} md={12} lg={12}>
        <TextStyle sx={{ paddingLeft: { xs: '0px', sm: '20px' }, paddingRight: '20px', top: { xs: '60px', sm: '200px' } }}>
          <Typography variant="h1" sx={{ pt: 0, paddingLeft: 5, paddingRight: 5, textAlign: 'left', width: { xs: '15ch', sm: '18ch' } }}  >
            Cook Together, From Anywhere
          </Typography>
          <Typography variant="h3" sx={{ pt: 0, paddingLeft: 5, paddingRight: 5, marginBottom: '1ch', fontVariant: 'normal', textAlign: 'left', maxWidth: { xs: '22ch', sm: '30ch' }, fontWeight: '400', lineHeight: '1-' }}  >
            Ease, Convenience, and Fun - All At Your Fingertips
          </Typography>
          <br />
          <Typography variant="h4" sx={{ pt: 0, mb: 5, paddingLeft: 5, paddingRight: 5, textAlign: 'left', maxWidth: '40ch', fontVariant: 'normal', fontWeight: '400' }}  >
            Host your friends and family to unique virtual experiences catered by the world's best culinary artists.
          </Typography>
          {!token  && <Button id="submit-contact" variant="contained" style={{ backgroundColor: "#1976D2", fontSize: '18pt', marginLeft: 35 }} onClick={scrollToBottom}>Sign Up</Button>}
          {/* <Button variant='contained' size='large' sx={{ left: '45%', alignSelf: "center" }} onClick={() => onSignup()}> {buttonText} </Button> */}
        </TextStyle>

      </Grid>
    </div>
  );
}
